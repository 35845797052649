import React, { useState } from 'react';
import { WidgetProps } from './WidgetProps';

const ImageWidget = ({
	id,
	isShowWidget,
	itemRef,
	onClickItem,
	widgetTemplateId,
	settings,
	isSafari,
	onContextMenu,
}: WidgetProps.BaseProps) => {
	const [isHover, setIsHover] = useState(false);

	const { imgUrl, bgImgUrl } = settings;

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	return (
		<g id={widgetTemplateId} className={`${!isSafari ? 'widget' : ''} widget_${widgetTemplateId}`}>
			<g
				ref={itemRef}
				data-widget-id={id}
				data-template-id={widgetTemplateId}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onContextMenu={(e) => {
					e.preventDefault();
					onContextMenu?.(itemRef, !!isShowWidget);
					return false;
				}}
			>
				<foreignObject>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							height: '100%',
						}}
					>
						<img
							className="image-widget"
							src={isHover ? bgImgUrl || imgUrl : imgUrl}
							style={{
								display: isShowWidget ? 'block' : 'none',
								width: '100%',
							}}
							onClick={(e) => {
								e.stopPropagation();
								onClickItem?.(itemRef);
							}}
						/>
					</div>
				</foreignObject>
			</g>
		</g>
	);
};

export default ImageWidget;
