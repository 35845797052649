import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';

import { useTranslation } from 'react-i18next';

import ColorPicker from '../../../../../../../../../reusable/ColorPicker/ColorPicker';
import CardData from '../../../../../../../utils/CardData';

type HotspotContentTypeProps = {
	widgetTemplate: {
		style: {
			backgroundSettings?: {
				backgroundColor?: string;
				hoverBackgroundColor?: string;
			};
		};
	};
	bgHoverSetting: boolean;
	setBGHoverSetting: (_value: boolean) => {};
	handleBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleHoverBGColorChange: (_event: {} | undefined, _color: {}) => {};
};

const HotspotContent = ({
	widgetTemplate,
	bgHoverSetting,
	setBGHoverSetting,
	handleBGColorChange,
	handleHoverBGColorChange,
}: HotspotContentTypeProps) => {
	const {
		style: {
			backgroundSettings: { backgroundColor = 'transparent', hoverBackgroundColor = 'transparent' } = {
				backgroundColor: 'transparent',
				hoverBackgroundColor: 'transparent',
			},
		},
	} = widgetTemplate;

	const { t: translator } = useTranslation();

	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT')}>
			<div className="scene-widget__control">
				<ButtonGroup className="scene-widget__buttonGroup">
					<Button
						variant="outlined"
						color={bgHoverSetting ? undefined : 'primary'}
						onClick={() => setBGHoverSetting(false)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_NORMAL')}
					</Button>
					<Button
						variant="outlined"
						color={bgHoverSetting ? 'primary' : undefined}
						onClick={() => setBGHoverSetting(true)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER')}
					</Button>
				</ButtonGroup>
			</div>
			{!bgHoverSetting && (
				<>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
						onChange={handleBGColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={backgroundColor}
									onChangeComplete={(color: any) => handleBGColorChange(undefined, color)}
								/>
								<Input value={backgroundColor} />
							</div>
						}
					/>
				</>
			)}
			{bgHoverSetting && (
				<>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
						onChange={handleHoverBGColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={hoverBackgroundColor}
									onChangeComplete={(color: any) => handleHoverBGColorChange(undefined, color)}
								/>
								<Input value={hoverBackgroundColor} />
							</div>
						}
					/>
				</>
			)}
			<Divider className="divider-extra-space" />
		</CardData>
	);
};

export default HotspotContent;
