import { publicURL } from '../../../utils/config';

export const EDITED_MEDIA_ID_PREFIX = 'aeedit';
export namespace AdobeExpressUtils {
	export interface Size {
		width: number;
		height: number;
		unit: 'px' | 'in' | 'mm';
	}
	export interface AEProjectProps {
		ccEverywhere: any;
		actionCallback: any;
		projectId?: string; // required for edit design
		mediaName?: string;
		dataUrl?: string;
		replaceMediaContent?: object;
		mediaType?: string;
		canvasSize?: Size | string;
	}

	const containerConfig = {
		zIndex: 1,
	};
	const docConfig = { canvasSize: 'Video' };
	const baseAppConfig = {
		selectedCategory: 'media',
		allowedFileTypes: ['image/png', 'image/jpeg', 'video/mp4'],
	};

	const exportConfig = [
		{
			id: 'export-btn',
			label: 'Save & Export to Qbrick',
			action: {
				target: 'publish',
			},
			style: {
				uiType: 'button',
			},
		},
	];

	export async function readJSON() {
		const resp = fetch(`${publicURL}/QbrickVideoEditor-196182-API-Key.json`);
		return await (await resp).json();
	}

	export async function initializeCcEverywhere() {
		try {
			const credentialJSON = await readJSON();
			const ccEverywhere = await (window as any).CCEverywhere.initialize({
				clientId: credentialJSON?.API_KEY,
				appName: credentialJSON?.name,
			});

			return ccEverywhere;
		} catch (error) {
			console.error('Error initializing ccEverywhere:', error);
			throw error;
		}
	}

	export function terminateCcEverywhere() {
		console.info('terminateCcEverywhere');
		// (window as any).CCEverywhere.terminate();
	}

	export async function createNewProject({
		ccEverywhere,
		dataUrl,
		actionCallback,
		mediaName,
		mediaType = 'video',
	}: AEProjectProps) {
		try {
			const appConfig = {
				...baseAppConfig,
				allowedFileTypes: mediaType === 'video' ? baseAppConfig.allowedFileTypes : ['image/png', 'image/jpeg'],
				callbacks: {
					...actionCallback,
					onPublish: (intent: any, publishParams: any) => {
						actionCallback.onPublish(intent, publishParams, mediaName);
					},
				},
			};

			if (dataUrl) {
				ccEverywhere.editor.createWithAsset(
					{
						...docConfig,
						asset: {
							type: mediaType,
							dataType: 'url',
							data: dataUrl,
						},
					},
					appConfig,
					exportConfig,
					containerConfig
				);
			} else {
				ccEverywhere.editor.create(docConfig, appConfig, exportConfig, containerConfig);
			}
		} catch (error) {
			console.error('Error creating design:', error);
		}
	}

	export async function editDesign({
		ccEverywhere,
		projectId,
		actionCallback,
		mediaName,
		replaceMediaContent,
	}: AEProjectProps) {
		const editDocConfig = { documentId: projectId };
		const appConfig = {
			...baseAppConfig,
			callbacks: {
				...actionCallback,
				onPublish: (intent: any, publishParams: any) => {
					actionCallback.onPublish(intent, publishParams, mediaName, replaceMediaContent);
				},
			},
		};
		ccEverywhere && ccEverywhere.editor.edit(editDocConfig, appConfig, exportConfig, containerConfig);
	}

	export const getMediaIdFromProjectId = (projectId: string) => {
		return `${EDITED_MEDIA_ID_PREFIX}-${new Date().getTime()}-${projectId.replaceAll(':', '-')}`;
	};

	export const getAdobeExpressProjectIdFromMediaId = (mediaId: string) => {
		const [, , a, b, c, d] = (mediaId ?? '').split('-');
		const projectUUID = mediaId.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i)?.[0];

		if (!a || !b || !c || !d || !projectUUID) {
			return null;
		}

		return `${a}:${b}:${c}:${d}:${projectUUID}`;
	};
}
