import React, { useEffect, useRef, useState } from 'react';

import { defaultIconPosition, defaultIconSize, defaultIconSpacing } from '../../../utils/helper';
import { getIconByName } from '../../../../../reusable/IconLibrary/IconLibrary';

import { WidgetProps } from './WidgetProps';

const generateButtonStyle = ({
	isShowWidget,
	style,
	isHover,
}: {
	isShowWidget?: boolean;
	style: WidgetProps.StyleProps;
	isHover?: boolean;
}) => {
	const {
		textLabel: {
			backgroundColor: textBackgroundColor,
			color,
			font,
			fontSize,
			fontWeight,
			hoverBackgroundColor: textHoverBackgroundColor,
			hoverColor,
			hoverFontWeight,
		},
		transformSettings: {
			paddingBottom,
			paddingLeft,
			paddingRight,
			paddingTop,
			borderRadiusBottom,
			borderRadiusLeft,
			borderRadiusRight,
			borderRadiusTop,
		},
		backgroundSettings: { backgroundColor, hoverBackgroundColor },
		iconConfig: { position } = {},
	} = style;

	// !! Should change to ONLY use backgroundSettings's props after KK-1411 released a few months
	const BGColor =
		backgroundColor && backgroundColor !== 'transparent' && backgroundColor !== '#00000000'
			? backgroundColor
			: textBackgroundColor;
	const hoverBGColor =
		hoverBackgroundColor && hoverBackgroundColor !== 'transparent' && hoverBackgroundColor !== '#00000000'
			? hoverBackgroundColor
			: textHoverBackgroundColor;

	return {
		display: isShowWidget ? 'flex' : 'none',
		flexDirection: position === 'top' || position === 'bottom' ? 'column' : ('row' as any),
		backgroundColor: !isHover ? BGColor : hoverBGColor,
		color: !isHover ? color : hoverColor,
		fontFamily: `${font}, Arial, sans-serif`,
		fontSize: `${fontSize}px`,
		fontWeight: !isHover ? fontWeight : hoverFontWeight,
		paddingBottom,
		paddingLeft,
		paddingRight,
		paddingTop,
		borderTopRightRadius: `${borderRadiusTop}px`,
		borderBottomRightRadius: `${borderRadiusRight}px`,
		borderBottomLeftRadius: `${borderRadiusBottom}px`,
		borderTopLeftRadius: `${borderRadiusLeft}px`,
		width: '100%',
		height: '100%',
	};
};

const generateIconStyle = ({
	isHover,
	size,
	color,
	hoverColor,
}: {
	isHover: boolean;
	size: string;
	color: string;
	hoverColor: string;
}) => {
	return {
		fontSize: `${size}px`,
		color: !isHover ? color : hoverColor,
	};
};

const generateIconContainerStyle = ({ position, spacing }: { position: string; spacing: string }) => {
	return {
		paddingBottom: position === 'top' ? `${spacing}px` : 0,
		paddingLeft: position === 'right' ? `${spacing}px` : 0,
		paddingRight: position === 'left' ? `${spacing}px` : 0,
		paddingTop: position === 'bottom' ? `${spacing}px` : 0,
		lineHeight: 0,
	};
};

const ButtonWidget = ({
	id,
	isShowWidget,
	itemRef,
	onClickItem,
	widgetTemplateId,
	style,
	settings,
	isSafari,
	onContextMenu,
	onUpdateLabel,
}: WidgetProps.BaseProps) => {
	// KK-1182 - Enable text input directly on widget - Solution note
	// Using contentEditable to make span element editable content - better than optimise a text area input
	// But contentEditable & reactjs gonna make the cursor of editing text jump to begin of line. https://github.com/facebook/react/issues/2047
	// Therefore using initialTextValue, dangerouslySetInnerHTML to work it out.

	const {
		textLabel: { text, color, hoverColor },
		iconConfig: { position = defaultIconPosition, size = defaultIconSize, spacing = defaultIconSpacing } = {},
	} = style;

	const [isHover, setIsHover] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [initialTextValue, setInitialTextValue] = useState(text);

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const textElRef = useRef<HTMLSpanElement | null>();

	const { iconKey } = settings;
	const icon =
		iconKey &&
		getIconByName(iconKey, {
			style: generateIconStyle({ isHover, size, color, hoverColor }),
		});

	useEffect(() => {
		if (editMode) {
			textElRef.current?.focus();
			return;
		}
	}, [textElRef, editMode]);

	useEffect(() => {
		if (!editMode) {
			setInitialTextValue(text);
		}
	}, [text, editMode]);

	//If safari then we not add style backface-visibility. This style using to reduce ghost layer appear when drag widget KK-1087
	//But on safari using backface-visibility + foreignObject (inside g tag) will break D&D on safari.
	// More info: https://github.com/bkrem/react-d3-tree/issues/284
	return (
		<g id={widgetTemplateId} className={`${!isSafari ? 'widget' : ''} widget_${widgetTemplateId}`}>
			<g
				ref={itemRef}
				data-template-id={widgetTemplateId}
				data-widget-id={id}
				onClick={(e) => {
					e.stopPropagation();
					isShowWidget && onClickItem?.(itemRef);
				}}
				onContextMenu={(e) => {
					e.preventDefault();
					onContextMenu?.(itemRef, !!isShowWidget);
					return false;
				}}
				onDoubleClick={() => {
					setEditMode(true);
				}}
			>
				<foreignObject>
					<div
						className="button-widget"
						style={generateButtonStyle({ isShowWidget, style, isHover })}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{iconKey && (position === 'top' || position === 'left') && (
							<div style={generateIconContainerStyle({ spacing, position })}>{icon}</div>
						)}

						<span
							ref={(r) => (textElRef.current = r)}
							contentEditable={editMode}
							onInput={(e) => {
								// send text to update
								onUpdateLabel?.(e.currentTarget.innerHTML);
							}}
							onBlur={() => {
								setEditMode(false);
							}}
							dangerouslySetInnerHTML={{ __html: initialTextValue }}
						/>

						{iconKey && (position === 'bottom' || position === 'right') && (
							<div style={generateIconContainerStyle({ spacing, position })}>{icon}</div>
						)}
					</div>
				</foreignObject>
			</g>
		</g>
	);
};

export default ButtonWidget;
