import React, { memo } from 'react';
import { Handle } from 'reactflow';
import { useTranslation } from 'react-i18next';

import subtract from '../../../assets/images/subtract.svg';
import filled_trash from '../../../assets/images/filled-trash.svg';
import house_fill from '../../../assets/images/house-fill.svg';
import support from '../../../assets/images/support.svg';
import video_settings from '../../../assets/svg/video_settings.svg';
import no_thumbnail from '../../../assets/svg/NoThumbnail.svg';
import { getThumbnailFromRenditionsBasedOnWidth } from '../../../services/mediaDisplayService';
import { joinClassNames } from '../../../services/elementHelperService';

import './SceneNode.scss';

export default memo(function SceneNode(props) {
	const { t } = useTranslation();
	const { data } = props;

	let thumbnail = data?.mediaDetails?.metadata?.thumbnail ?? no_thumbnail;
	if (thumbnail instanceof Array) {
		thumbnail = getThumbnailFromRenditionsBasedOnWidth(thumbnail, 220);
	}

	return (
		<>
			<Handle type="target" position="left" id={props.id + 'target'} />
			<div
				className={joinClassNames(
					'scene__body',
					data.home ? 'scene__home' : undefined,
					data.isMiniNode ? 'scene__mini' : undefined,
					data.isDragable ? undefined : 'nodrag'
				)}
			>
				{props.data?.isProcessing ? (
					<div className={joinClassNames('scene__content', 'scene__content--processing')}>
						{t('COMMON_PROCESSING_VIDEO')}
					</div>
				) : (
					<div
						className={joinClassNames(
							'scene__content',
							!thumbnail ||
								thumbnail === no_thumbnail ||
								thumbnail.toString().toLowerCase().includes('nothumbnail')
								? `no-thumbnail`
								: ''
						)}
						style={{
							backgroundImage: `url(${thumbnail})`,
							...data.style,
						}}
					/>
				)}
				<div className={'scene__actions'}>
					<button
						className={'scene-btn scene-btn__home'}
						title="Make this scene home"
						onClick={() => data.setNodeHome?.(data)}
					>
						<img src={house_fill} />
					</button>
					{!data.isMiniNode && (
						<div className={'scene__actions--right'}>
							<button className={'scene-btn scene-btn__edit'} title="Edit scene">
								<img src={support} />
							</button>
							<button className={'scene-btn scene-btn__videoSettings'} title="Video settings">
								<img width={'auto'} height={'auto'} style={{ marginTop: '3px' }} src={video_settings} />
							</button>
							<button
								className={'scene-btn scene-btn__duplicate'}
								title="Duplicate scene"
								onClick={() => data.onDuplicateNode?.(data)}
							>
								<img src={subtract} />
							</button>
							<button
								className={`scene-btn scene-btn__trash ${data.home ? 'disabled' : ''}`}
								title={data.home ? 'Cannot remove home scene' : 'Remove this scene'}
								onClick={() => data.onRemoveNode?.(data)}
								disabled={data.home}
							>
								<img src={filled_trash} />
							</button>
						</div>
					)}
				</div>
			</div>
			<Handle type="source" position="right" id={props.id + 'source'} />
		</>
	);
});
