import React, { useState } from 'react';
import { WidgetProps } from './WidgetProps';

const generateHotspotStyle = ({
	isShowWidget,
	style,
	isHover,
}: {
	isShowWidget?: boolean;
	style: WidgetProps.StyleProps;
	isHover?: boolean;
}) => {
	const {
		transformSettings: {
			borderRadiusBottom,
			borderRadiusLeft,
			borderRadiusRight,
			borderRadiusTop,
			paddingBottom,
			paddingLeft,
			paddingRight,
			paddingTop,
			hoverPaddingBottom,
			hoverPaddingLeft,
			hoverPaddingRight,
			hoverPaddingTop,
		},
		backgroundSettings: { backgroundColor, hoverBackgroundColor },
	} = style;

	return {
		display: isShowWidget ? 'flex' : 'none',
		width: '100%',
		height: '100%',
		backgroundColor: isHover ? hoverBackgroundColor : backgroundColor,
		borderTopRightRadius: `${borderRadiusTop}px`,
		borderBottomRightRadius: `${borderRadiusRight}px`,
		borderBottomLeftRadius: `${borderRadiusBottom}px`,
		borderTopLeftRadius: `${borderRadiusLeft}px`,
		paddingBottom: isHover ? hoverPaddingBottom : paddingBottom,
		paddingLeft: isHover ? hoverPaddingLeft : paddingLeft,
		paddingRight: isHover ? hoverPaddingRight : paddingRight,
		paddingTop: isHover ? hoverPaddingTop : paddingTop,
	};
};

const HotspotWidget = ({
	id,
	isShowWidget,
	itemRef,
	onClickItem,
	widgetTemplateId,
	style,
	onContextMenu,
}: WidgetProps.BaseProps) => {
	const [isHover, setIsHover] = useState(false);

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	return (
		<g id={widgetTemplateId} className={`widget widget_${widgetTemplateId}`}>
			<g
				ref={itemRef}
				data-template-id={widgetTemplateId}
				data-widget-id={id}
				onClick={(e) => {
					e.stopPropagation();
					isShowWidget && onClickItem?.(itemRef);
				}}
				onContextMenu={(e) => {
					e.preventDefault();
					onContextMenu?.(itemRef, !!isShowWidget);
					return false;
				}}
			>
				<foreignObject>
					<div
						className="widget button-widget"
						style={generateHotspotStyle({ isShowWidget, style, isHover })}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					/>
				</foreignObject>
			</g>
		</g>
	);
};

export default HotspotWidget;
