import React, { useRef, useEffect } from 'react';

import ButtonWidget from './ButtonWidget';
import ImageWidget from './ImageWidget';
import IconWidget from './IconWidget';
import TextWidget from './TextWidget';
import HotspotWidget from './HotspotWidget';
import { getTimelineTimeFromTimeInput, getSecondsFromTimelineTime } from '../../../../../services/timeStampService';
import {
	WIDGET_TYPE_BUTTON,
	WIDGET_TYPE_ICON,
	WIDGET_TYPE_IMAGE,
	WIDGET_TYPE_TEXT,
	WIDGET_TYPE_HOTSPOT,
} from '../../../../../constants/scenarioConstant';
import { WidgetProps } from './WidgetProps';

const createWidget = ({
	type,
	...widgetProperties
}: WidgetProps.BaseProps & {
	type?: string;
}) => {
	switch (type) {
		case WIDGET_TYPE_BUTTON:
			return <ButtonWidget {...widgetProperties} />;
		case WIDGET_TYPE_IMAGE:
			return <ImageWidget {...widgetProperties} />;
		case WIDGET_TYPE_ICON:
			return <IconWidget {...widgetProperties} />;
		case WIDGET_TYPE_TEXT:
			return <TextWidget {...widgetProperties} />;
		case WIDGET_TYPE_HOTSPOT:
			return <HotspotWidget {...widgetProperties} />;
		default:
			return <></>;
	}
};

type WidgetInteractionItemProps = WidgetProps.BaseProps & {
	data: any;
	bounds: any;
	playerTimePos: number;
	onUpdateLabel?: (_labelContent?: string) => void;
};

const WidgetInteractionItem = ({
	data,
	bounds,
	onClickItem,
	playerTimePos,
	onContextMenu,
	onUpdateLabel,
}: WidgetInteractionItemProps) => {
	const {
		id,
		name,
		widgetTemplateId,
		start,
		end,
		type,
		settings,
		style: { dimension, coordinate, rotation },
	} = data;

	const { x, y } = coordinate ?? { x: 0, y: 0 };
	const { w, h } = dimension ?? { w: 150, h: 100 };

	const itemRef = useRef<HTMLElement | null>(null);

	const isShowWidget =
		playerTimePos >= getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(start)) &&
		playerTimePos <= getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(end));

	const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

	useEffect(() => {
		// Init widget
		if (itemRef.current) {
			const wrapper = itemRef.current as any;
			const children = itemRef.current.children[0];
			// Update position
			children.setAttribute('x', x);
			children.setAttribute('y', y);

			// Update dimension
			children.setAttribute('width', w);
			children.setAttribute('height', h);

			// Init rotation
			wrapper.style.transform = `rotate(${rotation ?? 0}deg)`;
		}
	}, [bounds, data, isShowWidget]);

	if (data.style && Object.keys(data.style).length === 0) {
		return null;
	}

	return (
		isShowWidget &&
		createWidget({
			id,
			type,
			isShowWidget,
			itemRef,
			onClickItem,
			widgetTemplateId,
			style: data.style,
			name,
			coordinate,
			dimension,
			settings,
			isSafari,
			onContextMenu,
			onUpdateLabel,
		})
	);
};

export default WidgetInteractionItem;
