import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'underscore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from '../../../../../../constants/dialogSizesConstant';
import { showNotification } from '../../../../../../actions/notificationActions';

import './CaptureThumbnailDialog.scss';

const THUMBNAIL_IMAGE_WIDTH = 1280;

const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		//marginTop: theme.spacing.unit * 2,
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
});

class CaptureThumbnailDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fullWidth: true,
			maxWidth: dialogSizes.SMALL,
			thumbnailImageSet: [],
		};
	}

	captureImage = () => {
		let video = document.getElementById('main-video');
		let canvas = document.getElementById('canvas');
		let context = canvas.getContext('2d');

		const heightOnWidthRatio = video.videoHeight / video.videoWidth;
		canvas.width = THUMBNAIL_IMAGE_WIDTH;
		canvas.height = THUMBNAIL_IMAGE_WIDTH * heightOnWidthRatio;

		context.fillRect(0, 0, canvas.width, canvas.height);
		context.drawImage(video, 0, 0, canvas.width, canvas.height);
		let img = document.createElement('img');
		img.crossOrigin = 'Anonymous';
		img.src = canvas.toDataURL('image/jpeg', 1);

		this.setState({ thumbnailImageSet: [...this.state.thumbnailImageSet, img] });
	};

	dataURItoBlob = (dataURI) => {
		let byteString = atob(dataURI.split(',')[1]);
		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}

		let bb = new Blob([ab], {
			type: 'image/jpeg',
		});

		return bb;
	};

	blobToFile = (blobData, fileName) => {
		const fd = new FormData();
		fd.set('a', blobData, fileName);
		return fd.get('a');
	};

	fileName = () => {
		return 'cupturedThumbnail' + _.random(1, 999) + '.jpeg';
	};

	initUpload = () => {
		let filesToUpload = [];
		if (this.state.thumbnailImageSet) {
			_.each(this.state.thumbnailImageSet, (val) => {
				let newFile = this.dataURItoBlob(val.src);
				let file = this.blobToFile(newFile, this.fileName());
				filesToUpload.push(file);
			});

			this.props.loadTheSelectedMediaItems(filesToUpload);
			this.props.closeInformationDialog();
		}
	};

	deleteSelectedImage = (img) => {
		let tempArray = this.state.thumbnailImageSet.filter((eachThumb) => eachThumb.src !== img);
		this.setState({ thumbnailImageSet: tempArray });
	};

	getDialogStyle = (_) => ({ paddingLeft: 53, paddingTop: 0, paddingRight: 53, paddingBottom: 0 });

	render() {
		const { t, closeInformationDialog, open, videoSource } = this.props;

		const { thumbnailImageSet } = this.state;

		return (
			<>
				<Dialog
					id="CaptureThumbnailDialog"
					style={this.getDialogStyle()}
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={open}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					onClose={closeInformationDialog}
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CAPTURE_THUMBNAIL_FROM_VIDEO')}
						</Typography>
						<IconButton onClick={closeInformationDialog}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div>
							<label className="secondaryTitleCaptureThumbnailDialog">
								{t(
									'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_SKIM_THROUGH_YOUR_VIDEO_TO_CHOOSE_A_FRAME'
								)}
							</label>
							<div className="videoPlayerCaptureThumbnail">
								<video id="main-video" width="550px" height="auto" controls crossOrigin="Anonymous">
									<source src={videoSource} type="video/mp4" />
								</video>
								<canvas id="canvas" hidden></canvas>
							</div>
						</div>

						<Button
							className="defaultActionBtn"
							style={{ marginBottom: '20px' }}
							onClick={() => {
								this.captureImage();
							}}
						>
							<div className="cameraSvg">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.48 8.47">
									<g id="Layer_2" data-name="Layer 2">
										<g id="Layer_1-2" data-name="Layer 1">
											<path d="M11.05,1H8.88V.85A.85.85,0,0,0,8,0H3.45A.85.85,0,0,0,2.6.85V1H.43A.43.43,0,0,0,0,1.47V8a.43.43,0,0,0,.43.43H11.05A.43.43,0,0,0,11.48,8V1.47A.43.43,0,0,0,11.05,1ZM5.74,7.19A2.44,2.44,0,1,1,8.18,4.75,2.44,2.44,0,0,1,5.74,7.19ZM8.88,2.85a.54.54,0,1,1,.53-.53A.53.53,0,0,1,8.88,2.85Z" />
											<path d="M5.74,6A1.25,1.25,0,0,1,4.49,4.75a.25.25,0,0,0-.5,0A1.75,1.75,0,0,0,5.74,6.5.25.25,0,0,0,6,6.25.25.25,0,0,0,5.74,6Z" />
										</g>
									</g>
								</svg>
							</div>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CAPTURE_THIS_FRAME')}
						</Button>

						<div className="thumbnailsContentWrapper">
							<div className="actualThubGallery captureThumbnailGallery">
								{thumbnailImageSet.map((eachThumb, i) => (
									<div key={i} className="thumbnailImgWrapper">
										<div className="thumbDeleteSvgBtnWrapper">
											<svg
												viewBox="0 0 19.5 19.5"
												onClick={() => {
													this.deleteSelectedImage(eachThumb.src);
												}}
											>
												{' '}
												<defs />{' '}
												<g id="Layer_2" data-name="Layer 2">
													{' '}
													<g id="Layer_1-2" data-name="Layer 1">
														<path
															className="cls-1"
															d="M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z"
														/>
														<path
															className="cls-1"
															d="M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z"
														/>
													</g>
												</g>{' '}
											</svg>
										</div>
										<img src={eachThumb.src} />
									</div>
								))}
							</div>
						</div>
					</DialogContent>

					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
								<Button
									className="defaultActionBtn"
									style={{ marginTop: '-20px', marginBottom: '10px' }}
									onClick={() => {
										this.initUpload();
									}}
								>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_ADD_TO_THUMBNAILS')}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles)(CaptureThumbnailDialog));
