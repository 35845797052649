import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Clear, NotInterested } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { fontWeights, fonts } from '../../../../../../../../../constants/playerConfigurationsConstant';
import ColorPicker from '../../../../../../../../../reusable/ColorPicker/ColorPicker';
import { IconNames, getIconByName } from '../../../../../../../../../reusable/IconLibrary/IconLibrary';
import CustomSlider from '../../../../../../../../../reusable/Slider/CustomSlider';
import { defaultIconPosition, defaultIconSize, defaultIconSpacing } from '../../../../../../../utils/helper';
import CardData from '../../../../../../../utils/CardData';

const ICON_PLACEMENT_OPTIONS = [
	{ key: 'left', value: 'left', label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_PLACEMENT_LEFT' },
	{ key: 'top', value: 'top', label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_PLACEMENT_TOP' },
	{
		key: 'right',
		value: 'right',
		label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_PLACEMENT_RIGHT',
	},
	{
		key: 'bottom',
		value: 'bottom',
		label: 'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_PLACEMENT_BOTTOM',
	},
];

const SLIDER_OPTION_MARKS = [
	{ value: 0, label: '0px' },
	{ value: 20 },
	{ value: 40 },
	{ value: 60 },
	{ value: 80 },
	{ value: 100 },
	{ value: 120 },
	{ value: 140 },
	{ value: 160 },
	{ value: 180 },
	{ value: 200, label: '200px' },
];

type ButtonContentTypeProps = {
	widgetTemplate: {
		style: {
			textLabel?: {
				text?: string;
				font?: string;
				fontSize?: string;
				fontWeight?: string;
				color?: string;
				backgroundColor?: string;
				hoverFontWeight?: string;
				hoverColor?: string;
				hoverBackgroundColor?: string;
			};
			backgroundSettings?: {
				backgroundColor?: string;
				hoverBackgroundColor?: string;
			};
			iconConfig?: { position?: string; size?: string; spacing?: string };
		};
		settings?: { iconKey?: string };
	};
	textHoverSetting: boolean;
	setTextHoverSetting: (_value: boolean) => {};
	handleTextLabelChange: () => {};
	handleTextLabelFontChange: () => {};
	handleTextLabelFontSizeChange: () => {};
	handleTextLabelFontWeightChange: () => {};
	handleTextLabelColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelHoverFontWeightChange: () => {};
	handleTextLabelHoverColorChange: (_event: {} | undefined, _color: {}) => {};
	handleTextLabelHoverBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleUpdateIconToButton: (_iconKey: IconNames | '', _size?: string, _position?: string, _spacing?: string) => {};
	onShowIconLibraryPickerModal: () => {};

	handleBGColorChange: (_event: {} | undefined, _color: {}) => {};
	handleHoverBGColorChange: (_event: {} | undefined, _color: {}) => {};
};

const ButtonContent = ({
	widgetTemplate,
	textHoverSetting,
	setTextHoverSetting,
	handleTextLabelChange,
	handleTextLabelFontChange,
	handleTextLabelFontSizeChange,
	handleTextLabelFontWeightChange,
	handleTextLabelColorChange,
	handleTextLabelBGColorChange,
	handleBGColorChange: handleBGColorChangeProps,
	handleTextLabelHoverFontWeightChange,
	handleTextLabelHoverColorChange,
	handleTextLabelHoverBGColorChange,
	handleHoverBGColorChange: handleHoverBGColorChangeProps,
	handleUpdateIconToButton,
	onShowIconLibraryPickerModal,
}: ButtonContentTypeProps) => {
	const {
		style: {
			textLabel: {
				text = '',
				font = 'Poppins',
				fontSize = '12',
				fontWeight = 'normal',
				color = '#126cfc',
				backgroundColor: textBGColor,
				hoverFontWeight = 'normal',
				hoverColor = '#126cfc',
				hoverBackgroundColor: textHoverBGColor,
			} = {
				text: '',
				font: 'Poppins',
				fontSize: '12',
				fontWeight: 'normal',
				color: '#126cfc',
				hoverFontWeight: 'normal',
				hoverColor: '#126cfc',
				backgroundColor: 'transparent',
				hoverBackgroundColor: 'transparent',
			},
			iconConfig: { position = defaultIconPosition, size = defaultIconSize, spacing = defaultIconSpacing } = {
				position: defaultIconPosition,
				size: defaultIconSize,
				spacing: defaultIconSpacing,
			},
			backgroundSettings: { backgroundColor, hoverBackgroundColor } = {
				backgroundColor: 'transparent',
				hoverBackgroundColor: 'transparent',
			},
		},
		settings: { iconKey } = {},
	} = widgetTemplate;

	// !! Should change to ONLY use backgroundSettings's props after KK-1411 released a few months
	const BGColor =
		backgroundColor && backgroundColor !== 'transparent' && backgroundColor !== '#00000000'
			? backgroundColor
			: textBGColor;
	const hoverBGColor =
		hoverBackgroundColor && hoverBackgroundColor !== 'transparent' && hoverBackgroundColor !== '#00000000'
			? hoverBackgroundColor
			: textHoverBGColor;

	const { t: translator } = useTranslation();

	const isShowIconOpt = Boolean(iconKey);

	const IconToShowOnToggle = getIconByName((iconKey || 'ArrowRightAlt') as IconNames, {
		color: isShowIconOpt ? 'primary' : 'none',
	});

	const IconToShowOnPicker = getIconByName((iconKey || 'ArrowRightAlt') as IconNames, {});

	const handleBGColorChange = (event: any | undefined, color: any) => {
		handleBGColorChangeProps(event, color);
		handleTextLabelBGColorChange(event, { hex: '' });
	};
	const handleHoverBGColorChange = (event: any | undefined, color: any) => {
		handleHoverBGColorChangeProps(event, color);
		handleTextLabelHoverBGColorChange(event, { hex: '' });
	};

	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT')}>
			<div className="scene-widget__control">
				<ButtonGroup className="scene-widget__buttonGroup">
					<Button
						variant="outlined"
						color={textHoverSetting ? undefined : 'primary'}
						onClick={() => setTextHoverSetting(false)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_NORMAL')}
					</Button>
					<Button
						variant="outlined"
						color={textHoverSetting ? 'primary' : undefined}
						onClick={() => setTextHoverSetting(true)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER')}
					</Button>
				</ButtonGroup>
			</div>
			{!textHoverSetting && (
				<>
					<FormControlLabel
						className={'scenario-chart__control'}
						control={<TextField variant="standard" value={text} />}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_TEXT_LABEL')}
						onChange={handleTextLabelChange}
						labelPlacement="start"
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_FAMILY')}
						onChange={handleTextLabelFontChange}
						labelPlacement="start"
						control={
							<Select variant="standard" value={font}>
								{fonts.map((font) => (
									<MenuItem value={font} key={font}>
										{font}
									</MenuItem>
								))}
							</Select>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_SIZE')}
						onChange={handleTextLabelFontSizeChange}
						labelPlacement="start"
						control={
							<Select variant="standard" value={fontSize}>
								{Array.from(new Array(68)).map((s, size) => (
									<MenuItem value={size + 5} key={size}>
										{size + 5}
									</MenuItem>
								))}
							</Select>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_WEIGHT')}
						onChange={handleTextLabelFontWeightChange}
						labelPlacement="start"
						control={
							<Select variant="standard" value={fontWeight}>
								{fontWeights.map((fontWeight) => (
									<MenuItem value={fontWeight} key={fontWeight}>
										{fontWeight}
									</MenuItem>
								))}
							</Select>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_TEXT_COLOR')}
						onChange={handleTextLabelColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={color}
									onChangeComplete={(color: any) => handleTextLabelColorChange(undefined, color)}
								/>
								<Input value={color} />
								<IconButton
									size="small"
									onClick={() => handleTextLabelColorChange(undefined, { hex: '' })}
								>
									<Clear />
								</IconButton>
							</div>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
						onChange={handleBGColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={BGColor}
									onChangeComplete={(color: any) => handleBGColorChange(undefined, color)}
								/>
								<Input value={BGColor} />
								<IconButton size="small" onClick={() => handleBGColorChange(undefined, { hex: '' })}>
									<Clear />
								</IconButton>
							</div>
						}
					/>
				</>
			)}
			{textHoverSetting && (
				<>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_WEIGHT')}
						onChange={handleTextLabelHoverFontWeightChange}
						labelPlacement="start"
						control={
							<Select variant="standard" value={hoverFontWeight}>
								{fontWeights.map((fontWeight) => (
									<MenuItem value={fontWeight} key={fontWeight}>
										{fontWeight}
									</MenuItem>
								))}
							</Select>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_TEXT_COLOR')}
						onChange={handleTextLabelHoverColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={hoverColor}
									onChangeComplete={(color: any) => handleTextLabelHoverColorChange(undefined, color)}
								/>
								<Input value={hoverColor} />
								<IconButton
									size="small"
									onClick={() => handleTextLabelHoverColorChange(undefined, { hex: '' })}
								>
									<Clear />
								</IconButton>
							</div>
						}
					/>

					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
						onChange={handleHoverBGColorChange}
						labelPlacement="start"
						control={
							<div className="scene-widgets__sort-input-control">
								<ColorPicker
									width="40px"
									height="22px"
									padding="2px"
									color={hoverBGColor}
									onChangeComplete={(color: any) => handleHoverBGColorChange(undefined, color)}
								/>
								<Input value={hoverBGColor} />
								<IconButton
									size="small"
									onClick={() => handleHoverBGColorChange(undefined, { hex: '' })}
								>
									<Clear />
								</IconButton>
							</div>
						}
					/>
				</>
			)}
			<Divider className="divider-extra-space" />
			<Box className="button-icon-configure-container">
				<Typography>
					{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BUTTON_ICON_SETTING')}
				</Typography>
				<ToggleButtonGroup>
					<ToggleButton
						value={'NoIcon'}
						aria-label="left aligned"
						size="small"
						className="toggle-button-custom"
						onClick={() => {
							handleUpdateIconToButton('', undefined, undefined, undefined);
						}}
						selected={!isShowIconOpt}
					>
						<NotInterested color={!isShowIconOpt ? 'primary' : 'disabled'} />
					</ToggleButton>
					<ToggleButton
						value={'WithIcon'}
						aria-label="right aligned"
						size="small"
						className="toggle-button-custom"
						onClick={() => {
							handleUpdateIconToButton(
								(iconKey || 'ArrowRightAlt') as IconNames,
								size,
								position,
								spacing
							);
						}}
						selected={isShowIconOpt}
					>
						{IconToShowOnToggle}
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{isShowIconOpt && (
				<>
					<div className={'scene-widget__control'}>
						<div className="scene-widget__iconSelector--placeholder" onClick={onShowIconLibraryPickerModal}>
							{IconToShowOnPicker}
						</div>
					</div>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_PLACEMENT_LABEL')}
						onChange={(e: any) => {
							const position = e.target.value;
							handleUpdateIconToButton(
								(iconKey || 'ArrowRightAlt') as IconNames,
								size,
								position,
								spacing
							);
						}}
						labelPlacement="start"
						control={
							<Select variant="standard" value={position}>
								{ICON_PLACEMENT_OPTIONS.map(({ key, value, label }) => (
									<MenuItem value={value} key={key}>
										{translator(label)}
									</MenuItem>
								))}
							</Select>
						}
					/>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_SIZE')}
						labelPlacement="start"
						control={
							<CustomSlider
								value={Number(size)}
								min={0}
								max={200}
								marks={SLIDER_OPTION_MARKS}
								onChange={(e: any, value: number | number[]) => {
									handleUpdateIconToButton(
										(iconKey || 'ArrowRightAlt') as IconNames,
										String(value),
										position,
										spacing
									);
								}}
							/>
						}
					/>
					<FormControlLabel
						className={'scenario-chart__control'}
						label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_ICON_SPACING')}
						labelPlacement="start"
						control={
							<CustomSlider
								value={Number(spacing)}
								min={0}
								max={200}
								marks={SLIDER_OPTION_MARKS}
								onChange={(e: any, value: number | number[]) => {
									handleUpdateIconToButton(
										(iconKey || 'ArrowRightAlt') as IconNames,
										size,
										position,
										String(value)
									);
								}}
							/>
						}
					/>
				</>
			)}
		</CardData>
	);
};

export default ButtonContent;
