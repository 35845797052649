import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Divider from '@material-ui/core/Divider';
import { Add, Remove, Settings } from '@material-ui/icons';

import { setSelectedApp } from '../../../../actions/appsActions';
import {
	clearBreadCrumbLocationArray,
	updateScreenName,
	updateBreadCrumbLocationArray,
} from '../../../../actions/breadCrumbActions';
import { setIsMountedSubtitleUpdated } from '../../../../actions/speechToTextActions';
import { showNotification } from '../../../../actions/notificationActions';
import { showMessage } from '../../../../actions/globalActions';
import { sides, defaultVideoWidth, defaultVideoHeight } from '../../../../services/inspectScreenHelperService';
import {
	getMediaQueryDetails,
	deleteRestrictionRule,
	sendMediaScreenChanged,
	saveInspectScreenDetails,
	sendMediaScreenUnChanged,
	createNewRestrictionRule,
	shareThePostOnSocialMedia,
	applyRestrictionRuleOnMedia,
	deleteRestrictionRuleOnMedia,
	changeRouteAfterInspectScreen,
	updateExistingRestrictionRule,
	fetchPlayerEmbedConfiguration,
} from '../../../../actions/publishActions';
import { updateCurentComponentKey } from '../../../../actions/navActions';
import {
	clearInspectScreenStorage,
	updateMenuKeys,
	addMediaDetails,
	setSidePlayerConfigId,
} from '../../../../actions/inspectScreenActions';
import { generateId } from '../../../../services/stringHelperService';
import {
	BONNIER_NEW_PRESET_ID,
	DEFAULT_THEO_PLAYER_EMBED,
	THEO_PLAYER_PRESET_ID,
	mediaNames,
	messageTypes,
	renditionTypes,
} from '../../../../constants/mediaConstants';
import InformationSection from './../InspectSections/InformationSection/InformationSection';
import { loadPlayerConfiguration } from '../../../../actions/playerConfigurationActions';
import eye_black from '../../../../assets/images/eye_black.svg';
import download_new from '../../../../assets/images/download_new.svg';
import copy_text from '../../../../assets/images/copy_text.svg';
import { advancedSettingLabels, defaultSettingTypes } from '../../../../constants/playerConfigurationsConstant';
import { getAccountSettings } from '../../../../actions/profileActions';
import { qplayerLoaderUrl, qplayerDomain, readPublicApiEndpointV1 } from '../../../../utils/config';
import { a11yLanguages } from '../../../../constants/languageConstants';
import FeaturesService from '../../../../services/FeaturesService';

import './InspectPlayerPreviewArea.scss';

class InspectPlayerPreviewArea extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mobileView: false,
			mediaDetails: null,
			routeAfterSave: '',
			selectedResourceId: '',
			isSaveInProgress: false,
			mediaName: mediaNames.medias,
			mobileViewZone: sides.left,
			liveMediaChannelId: '',
			lastRoute: '',
			videoWidth: defaultVideoWidth,
			videoHeight: defaultVideoHeight,
			accessToLive: false,
			accessToInteract: false,
			accessToSTT: false,
			playerConfigurationId: 'default',
			liveManagerLoaded: false,
			interactiveManagerLoaded: false,
			startPlayerMuted: false,
			showPlayerSettingOptions: false,
			playerConfigs: [],
			selectedPlayerConfig: null,
			autostart: false,
			replay: false,
			muteAtStart: false,
			playerSize: 'responsive',
			fixedSize: { width: '640', height: '360' },
			videos: [],
			showSocialSharing: false,
			showDownloadButton: false,
			showOpenInNewWindow: false,
			softFullscreen: false,
			defaultPlayerConfigs: [],
			language: '',
		};
		this.mediaDetailWidget = 'mediaDetail-' + generateId();
		this.mediaDetailContainer = 'mediaDetailContainer-' + generateId();
		this.originPlayerConfig = null;
	}

	componentDidMount() {
		const { getMediaPreviewDialogRef } = this.props;
		this.loadAccountPlayerConfiguration();
		this.loadDefaultPlayerConfigs();
		this.getVideoDownloadData();
		this.initPlayer();
		getMediaPreviewDialogRef && getMediaPreviewDialogRef(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const { selectedPlayerConfig } = this.state;
		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;

		if (
			prevState.selectedPlayerConfig !== this.state.selectedPlayerConfig ||
			this.state.showDownloadButton !== prevState.showDownloadButton ||
			this.state.showSocialSharing !== prevState.showSocialSharing ||
			this.state.showOpenInNewWindow !== prevState.showOpenInNewWindow ||
			this.state.muteAtStart !== prevState.muteAtStart ||
			this.state.autostart !== prevState.autostart ||
			this.state.replay !== prevState.replay ||
			this.state.language !== prevState.language
		) {
			if (!isUsingTheoPlayer) {
				this.cleanTheoPlayer();
				window.QplayerLoader.killInteractivePlayer?.();
				const element = document.getElementById('player-preview');
				if (element) {
					element.innerHTML = '';
					this.initPlayer();
				}
			} else {
				const script = document.getElementById('theo-script-id');
				if (script) {
					script.remove();
				}
				const newScript = document.createElement('script');
				newScript.setAttribute(
					'src',
					selectedPlayerConfig.layouts[0]?.playerStyling?.script ?? DEFAULT_THEO_PLAYER_EMBED
				);
				newScript.setAttribute('async', true);
				document.head.appendChild(newScript);
			}
		}
		const shouldReinitPlayerOfInteract =
			this.props.placementType.toUpperCase() === 'SCENARIOS' &&
			this.props.reinitPlayer &&
			!prevProps.reinitPlayer;
		const shouldReinitPlayer =
			this.props.placementType.toUpperCase() !== 'SCENARIOS' &&
			this.props.refreshMediasIds.find((r) => r === this.props.mediaDetails?.id) &&
			((this.props.mediaDetails?.restrictions ?? []).length > 0 ||
				(prevProps.mediaDetails?.restrictions ?? []).length > 0);

		if (shouldReinitPlayer || shouldReinitPlayerOfInteract) {
			window.QplayerLoader.killInteractivePlayer?.();
			const playerPreview = document.querySelector('#player-preview');
			playerPreview.innerHTML = '';
			this.initPlayer();
		}
	}

	componentWillUnmount() {
		window.QplayerLoader.killInteractivePlayer?.();
	}

	initPlayer = () => {
		const { selectedPlayerConfig } = this.state;
		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;

		if (
			!isUsingTheoPlayer &&
			window.QplayerLoader.initiateInteractivePlayer &&
			typeof window.QplayerLoader.initiateInteractivePlayer === 'function'
		) {
			window.QplayerLoader.initiateInteractivePlayer();
		}
	};

	cleanTheoPlayer = () => {
		const theoPlayerInstant = document.querySelector('.LivePlayerWrapper > iframe');
		if (theoPlayerInstant) {
			theoPlayerInstant.remove();
		}
	};

	loadAccountPlayerConfiguration = () => {
		const { defaultAccountId, t, showMessage } = this.props;
		this.props.loadPlayerConfiguration(defaultAccountId).then((data) => {
			if (!data) {
				showMessage(t('SETTINGS_PRESET_CONFIG_LOAD_DATA_FAIL'), messageTypes.error);
				return;
			}

			let selectedPlayerConfig = data.find((config) => config.recommended);
			selectedPlayerConfig = selectedPlayerConfig ?? data[0];

			const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;

			const desktopLayout =
				selectedPlayerConfig.layouts.find((l) => l.type === 'desktop') ?? selectedPlayerConfig.layouts[0];
			const muteAtStart = !isUsingTheoPlayer
				? !!desktopLayout?.playbackOptions?.muteAtStart
				: !!desktopLayout?.playbackOptions?.muted;
			const replay = !!desktopLayout?.playbackOptions?.replay;
			const autostart = !!desktopLayout?.playbackOptions?.autostart;
			const showSocialSharing = !!desktopLayout?.topControls?.showSocialSharing;
			const showDownloadButton = !isUsingTheoPlayer
				? !!desktopLayout?.topControls?.showDownloadButton
				: !!desktopLayout?.controls?.download;
			const showOpenInNewWindow = !!desktopLayout?.topControls?.showOpenInNewWindow;
			const playerSize = !isUsingTheoPlayer ? 'responsive' : 'fixedSize';
			const language = desktopLayout?.accessibilitySettings?.language;

			this.originPlayerConfig = {
				muteAtStart: muteAtStart,
				replay: replay,
				autostart: autostart,
				showSocialSharing: showSocialSharing,
				showDownloadButton: showDownloadButton,
				showOpenInNewWindow: showOpenInNewWindow,
				language: language,
				softFullscreen: false,
			};

			this.setState({
				playerConfigs: [...data],
				selectedPlayerConfig,
				replay,
				muteAtStart,
				autostart,
				showSocialSharing,
				showDownloadButton,
				showOpenInNewWindow,
				playerSize,
				language,
			});
		});
	};

	loadDefaultPlayerConfigs = () => {
		const { defaultAccountId, getAccountSettings } = this.props;
		getAccountSettings(defaultAccountId).then((settings) => {
			if (!settings || settings.length === 0) {
				return;
			}

			let defaultPlayerConfigs = [];
			if (
				settings.hasOwnProperty('qbrickPlayer') &&
				settings['qbrickPlayer'] &&
				settings['qbrickPlayer'].length > 0
			) {
				defaultPlayerConfigs = settings['qbrickPlayer'].find((v) => v.version === '2')?.presets?.defaults;
			}
			this.setState({ defaultPlayerConfigs: defaultPlayerConfigs });
		});
	};

	togglePlayerSettingOptions = (e) => {
		e.preventDefault();
		this.setState((prevState) => ({ showPlayerSettingOptions: !prevState.showPlayerSettingOptions }));
	};

	handlePlayerConfigsSelection = (event) => {
		const id = event.target.value;

		if (!id) {
			return;
		}

		const selectedPlayerConfig = this.state.playerConfigs.find((setting) => setting.id === id);

		this.setState((prevState) => {
			if (!prevState.selectedPlayerConfig || prevState.selectedPlayerConfig.id !== id) {
				const isUsingTheoPlayer = selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;

				const desktopLayout =
					selectedPlayerConfig.layouts?.find((l) => l.type === 'desktop') ??
					selectedPlayerConfig.layouts?.[0];
				const muteAtStart = !isUsingTheoPlayer
					? !!desktopLayout?.playbackOptions?.muteAtStart
					: !!desktopLayout?.playbackOptions?.muted;
				const replay = !!desktopLayout?.playbackOptions?.replay;
				const autostart = !!desktopLayout?.playbackOptions?.autostart;
				const showSocialSharing = !!desktopLayout?.topControls?.showSocialSharing;
				const showDownloadButton = !isUsingTheoPlayer
					? !!desktopLayout?.topControls?.showDownloadButton
					: !!desktopLayout?.controls?.download;
				const showOpenInNewWindow = !!desktopLayout?.topControls?.showOpenInNewWindow;
				const playerSize = !isUsingTheoPlayer ? 'responsive' : 'fixedSize';
				const language = desktopLayout?.accessibilitySettings?.language;

				this.originPlayerConfig = {
					muteAtStart: muteAtStart,
					replay: replay,
					autostart: autostart,
					showSocialSharing: showSocialSharing,
					showDownloadButton: showDownloadButton,
					showOpenInNewWindow: showOpenInNewWindow,
					language: language,
					softFullscreen: false,
				};

				return {
					selectedPlayerConfig: selectedPlayerConfig,
					replay,
					muteAtStart,
					autostart,
					showSocialSharing,
					showDownloadButton,
					showOpenInNewWindow,
					playerSize,
					language,
				};
			}
			return null;
		});
	};

	renderVideoActions = () => {
		const { selectedPlayerConfig } = this.state;
		const { scenario } = this.props;
		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;
		const previewLink = !isUsingTheoPlayer && this.getPreviewLink();
		const downloadLink = this.getDownloadLink();

		return (
			<div className="player-preview__video-actions">
				{!scenario && (
					<a target="_blank" href={downloadLink} download className="player-preview__btn">
						<img src={download_new} />
					</a>
				)}
				{!isUsingTheoPlayer && (
					<a target="_blank" href={previewLink} className="player-preview__btn">
						<img src={eye_black} />
					</a>
				)}
			</div>
		);
	};

	getDownloadLink = () => {
		const { videos } = this.state;
		return videos.length > 0 ? videos[0].link + '?download=true' : '#';
	};

	getSortedData = (list, property) => {
		let sortedArray = list.sort((a, b) => (a[property] <= b[property] ? 1 : -1));
		return sortedArray;
	};

	getVideoDownloadData = () => {
		const { mediaDetails } = this.props;
		let videos = [];
		mediaDetails &&
			mediaDetails.asset &&
			mediaDetails.asset.resources &&
			mediaDetails.asset.resources.map((resource) => {
				if (resource && resource.renditions) {
					resource.renditions.map((rendition) => {
						const link = rendition.links ? rendition.links[0].href : '';
						const arr = link ? link.split('/') : rendition.path ? rendition.path.split('/') : [];
						const lastName = arr.length > 0 ? arr[arr.length - 1] : '';
						const defaultName = '...' + (link ? link.slice(-17) : '');
						const name = lastName || defaultName;
						const genericObj = {
							resourceId: resource.id,
							renditionId: rendition.id,
							name: name,
							link: link,
						};
						switch (rendition.type) {
							case renditionTypes.VIDEO: {
								let tempVideoObj = {};
								if (rendition.videos && rendition.videos.length > 0) {
									tempVideoObj = { ...rendition.videos[0] };
								}
								const videoObj = {
									...genericObj,
									...rendition,
									...tempVideoObj,
								};
								videos.push(videoObj);
								break;
							}

							default:
								break;
						}
					});
				}
			});
		videos = this.getSortedData(videos, 'bitrate');
		this.setState({ videos: videos });
	};

	renderPlayerSettingSection = () => {
		const enabled = true;
		const {
			playerConfigs,
			showPlayerSettingOptions,
			selectedPlayerConfig,
			autostart,
			muteAtStart,
			replay,
			fixedSize,
			playerSize,
			showSocialSharing,
			showDownloadButton,
			showOpenInNewWindow,
			defaultPlayerConfigs,
			softFullscreen,
			uniqueFrame,
			language,
		} = this.state;
		const { t, userPermissions } = this.props;
		const configs = [...(playerConfigs ?? [])].filter((c) => c.id !== 'Shopping');
		if (!configs || configs.length === 0) {
			return null;
		}

		const defaultConfigs = configs.filter((s) => (defaultPlayerConfigs ?? []).includes(s.id));
		const presetConfigs = [...configs.filter((s) => !(defaultPlayerConfigs ?? []).includes(s.id))];
		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;
		const isUsingBonnierNews = selectedPlayerConfig && selectedPlayerConfig.id === BONNIER_NEW_PRESET_ID;

		return (
			<div className="player-preview__player-setting-section">
				<FormControl fullWidth className="saved-preset-select__control">
					<div className="saved-preset-select__control-header">
						<label id={'player-config-select-label'} htmlFor={'player-config-select'}>
							{t('COMMON_PLAYER')}
						</label>
						{FeaturesService.IsEnabled(userPermissions, 'QbrickPlayerEditor') && (
							<a href="#/settings/players" target="_blank" className="player-preview__link">
								<Settings />
								<span>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_PRESET_SETTINGS')}</span>
							</a>
						)}
					</div>

					{configs && configs.length > 0 && (
						<Select
							labelId={'player-config-select-label'}
							id={'player-config-select'}
							value={selectedPlayerConfig ? selectedPlayerConfig.id : 0}
							disableUnderline
							style={{ marginTop: '6px' }}
							onChange={this.handlePlayerConfigsSelection}
						>
							<MenuItem key={0} value={0} className="player-config-select-option" disabled>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_CHOOSE_PLAYER')}
							</MenuItem>
							<div className="player-config-select__subheader">
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_DEFAULT_PRESETS')}
							</div>
							{defaultConfigs.map((config, index) => (
								<MenuItem key={index} value={config.id} className="player-config-select-option">
									<div className="player-config-select-option__default-label">
										<div className="player-config-select-option__primary-text">
											{defaultSettingTypes[config.id.toLowerCase()].name}
										</div>
										<div className="player-config-select-option__secondary-text">
											{t(defaultSettingTypes[config.id.toLowerCase()].note)}
										</div>
									</div>
								</MenuItem>
							))}
							{presetConfigs && presetConfigs.length > 0 && <Divider />}
							{presetConfigs && presetConfigs.length > 0 && (
								<div className="player-config-select__subheader">
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_SAVED_PRESETS')}
								</div>
							)}
							{presetConfigs &&
								presetConfigs.length > 0 &&
								presetConfigs.map((config, index) => (
									<MenuItem key={index} value={config.id} className="player-config-select-option">
										{config.name}
									</MenuItem>
								))}
						</Select>
					)}
				</FormControl>

				<div className="player-preview__player-setting-options">
					{!isUsingBonnierNews && (
						<a href="/#" onClick={this.togglePlayerSettingOptions} className="player-preview__link">
							{showPlayerSettingOptions ? (
								<>
									<Remove />{' '}
									<span>
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_HIDE_OPTIONS')}
									</span>
								</>
							) : (
								<>
									<Add />{' '}
									<span>
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_SHOW_OPTIONS')}
									</span>
								</>
							)}
						</a>
					)}
					{showPlayerSettingOptions && !isUsingBonnierNews && (
						<>
							{!isUsingTheoPlayer && (
								<FormControl fullWidth>
									<FormControlLabel
										control={
											<Checkbox
												style={enabled !== false ? { color: '#126CFC' } : undefined}
												name="4"
												checked={softFullscreen}
											/>
										}
										label={t(advancedSettingLabels['softFullscreen'])}
										onChange={this.handleSoftFullscreenChange}
									/>
								</FormControl>
							)}
							<FormControl fullWidth>
								<FormControlLabel
									control={
										<Checkbox
											style={enabled !== false ? { color: '#126CFC' } : undefined}
											name="4"
											checked={autostart}
										/>
									}
									label={t(advancedSettingLabels['autostart'])}
									onChange={this.handleAutoStartChange}
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormControlLabel
									control={
										<Checkbox
											style={enabled !== false ? { color: '#126CFC' } : undefined}
											name="4"
											checked={muteAtStart}
										/>
									}
									label={t(advancedSettingLabels['muteAtStart'])}
									onChange={this.handleMuteAtStartChange}
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormControlLabel
									control={
										<Checkbox
											style={enabled !== false ? { color: '#126CFC' } : undefined}
											name="4"
											checked={replay}
										/>
									}
									label={t(advancedSettingLabels['replay'])}
									onChange={this.handleReplayChange}
								/>
							</FormControl>
							{!isUsingTheoPlayer && (
								<FormControl fullWidth>
									<FormControlLabel
										control={
											<Checkbox
												style={enabled !== false ? { color: '#126CFC' } : undefined}
												name="4"
												checked={showSocialSharing}
											/>
										}
										label={t(advancedSettingLabels['showSocialSharing'])}
										onChange={this.handleShowSocialSharingChange}
									/>
								</FormControl>
							)}

							<FormControl fullWidth>
								<FormControlLabel
									control={
										<Checkbox
											style={enabled !== false ? { color: '#126CFC' } : undefined}
											name="4"
											checked={showDownloadButton}
										/>
									}
									label={t(advancedSettingLabels['showDownloadButton'])}
									onChange={this.handleShowDownloadButtonChange}
								/>
							</FormControl>

							{!isUsingTheoPlayer && (
								<FormControl fullWidth>
									<FormControlLabel
										control={
											<Checkbox
												style={enabled !== false ? { color: '#126CFC' } : undefined}
												name="4"
												checked={showOpenInNewWindow}
											/>
										}
										label={t(advancedSettingLabels['showOpenInNewWindow'])}
										onChange={this.handleShowOpenInNewWindowButtonChange}
									/>
								</FormControl>
							)}

							{!isUsingTheoPlayer && (
								<FormControl fullWidth>
									<FormControlLabel
										control={
											<Checkbox
												style={enabled !== false ? { color: '#126CFC' } : undefined}
												name="4"
												checked={uniqueFrame}
											/>
										}
										label={t(advancedSettingLabels['uniqueFrame'])}
										onChange={this.handleShowUniqueFrameChange}
									/>
								</FormControl>
							)}

							{!isUsingTheoPlayer && (
								<FormControl fullWidth>
									<FormControlLabel
										className="player-preview__select"
										fullWidth
										control={
											<Select value={language} variant="outlined">
												{a11yLanguages.map((lang, index) => (
													<MenuItem
														key={index}
														value={lang.languageCode}
														className="a11y-lang-select-option"
													>
														{lang.value}
													</MenuItem>
												))}
											</Select>
										}
										label={t('COMMON_LANGUAGE')}
										labelPlacement="top"
										onChange={this.handleLanguageChange}
									/>
								</FormControl>
							)}

							<div className="player-preview__size-options">
								<FormControl fullWidth>
									<RadioGroup
										defaultValue="responsive"
										value={playerSize}
										onChange={this.handlePlayerSizeChange}
									>
										{!isUsingTheoPlayer && (
											<FormControlLabel
												control={
													<Radio
														style={enabled !== false ? { color: '#126CFC' } : undefined}
													/>
												}
												label={t('COMMON_RESPONSIVE')}
												value="responsive"
											/>
										)}
										<div className="player-preview__fixed-size">
											{!isUsingTheoPlayer ? (
												<FormControlLabel
													control={
														<Radio
															style={enabled !== false ? { color: '#126CFC' } : undefined}
														/>
													}
													label={t(
														'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_FIXED_SIZE'
													)}
													value="fixedSize"
												/>
											) : (
												<span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">
													{`${t(
														'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_FIXED_SIZE'
													)}:`}
												</span>
											)}

											<div className="player-preview__fixed-size-inputs">
												<input
													type="number"
													value={fixedSize.width}
													disabled={playerSize !== 'fixedSize'}
													onChange={this.handleFixedSizeWidthChange}
												/>
												<span>x</span>
												<input
													type="number"
													value={fixedSize.height}
													disabled={playerSize !== 'fixedSize'}
													onChange={this.handleFixedSizeHeightChange}
												/>
											</div>
										</div>
									</RadioGroup>
								</FormControl>
							</div>
						</>
					)}
				</div>
			</div>
		);
	};

	handleSoftFullscreenChange = (e, checked) => {
		this.setState({ softFullscreen: checked });
	};

	handleAutoStartChange = (e, checked) => {
		this.setState({ autostart: checked });
	};

	handleReplayChange = (e, checked) => {
		this.setState({ replay: checked });
	};

	handleMuteAtStartChange = (e, checked) => {
		this.setState({ muteAtStart: checked });
	};

	handleShowSocialSharingChange = (e, checked) => {
		this.setState({ showSocialSharing: checked });
	};

	handleShowDownloadButtonChange = (e, checked) => {
		this.setState({ showDownloadButton: checked });
	};

	handleShowOpenInNewWindowButtonChange = (e, checked) => {
		this.setState({ showOpenInNewWindow: checked });
	};

	handleShowUniqueFrameChange = (e, checked) => {
		this.setState({ uniqueFrame: checked });
	};

	handleLanguageChange = (e) => {
		this.setState({ language: e.target.value });
	};

	handlePlayerSizeChange = (e, value) => {
		this.setState({ playerSize: value });
	};

	handleFixedSizeWidthChange = (e) => {
		this.setState((prevState) => ({ fixedSize: { width: e.target.value, height: prevState.fixedSize.height } }));
	};

	handleFixedSizeHeightChange = (e) => {
		this.setState((prevState) => ({ fixedSize: { height: e.target.value, width: prevState.fixedSize.width } }));
	};

	renderEmbedSection = () => {
		const { t } = this.props;
		const { selectedPlayerConfig } = this.state;
		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;
		const isUsingBonnierNews = selectedPlayerConfig && selectedPlayerConfig.id === BONNIER_NEW_PRESET_ID;
		const divEmbedCode = this.getDivEmbedCode();
		const previewLink = !isUsingTheoPlayer && this.getPreviewLink();
		const iFrameEmbedCode = !isUsingTheoPlayer && this.getIframeEmbedCode();

		return (
			<div className="player-preview__embed-section">
				<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_DIV_EMBED')}</label>
				<div className="player-preview__embed-code-container">
					<textarea
						className="player-preview__embed-code"
						placeholder="Div embed"
						readOnly
						value={divEmbedCode}
					/>
					<CopyToClipboard text={divEmbedCode} onCopy={this.copyDivEmbed}>
						<button title={t('COMMON_CLICK_TO_COPY')} className="player-preview__btn">
							<img src={copy_text} />
						</button>
					</CopyToClipboard>
				</div>

				{!isUsingTheoPlayer && !isUsingBonnierNews && (
					<>
						<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_IFRAME_EMBED')}</label>
						<div className="player-preview__embed-code-container">
							<textarea
								className="player-preview__embed-code"
								placeholder="iFrame embed"
								readOnly
								value={iFrameEmbedCode}
							/>
							<CopyToClipboard text={iFrameEmbedCode} onCopy={this.copyIFrameEmbedCode}>
								<button title={t('COMMON_CLICK_TO_COPY')} className="player-preview__btn">
									<img src={copy_text} />
								</button>
							</CopyToClipboard>
						</div>
						<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_PREVIEW_LINK')}</label>
						<div className="player-preview__embed-code-container player-preview__embed-code-preview-link">
							<input
								className="player-preview__embed-code"
								placeholder="Preview link"
								readOnly
								value={previewLink}
							/>

							<CopyToClipboard text={previewLink} onCopy={this.copyPreviewLink}>
								<button title={t('COMMON_CLICK_TO_COPY')} className="player-preview__btn">
									<img src={copy_text} />
								</button>
							</CopyToClipboard>
						</div>
					</>
				)}
			</div>
		);
	};

	copyPreviewLink = (_) => {
		this.props.showNotification(
			this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_LABEL_PREVIEW_LINK_COPIED')
		);
	};

	copyDivEmbed = (_) => {
		this.props.showNotification(
			this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_LABEL_EMBED_CODE_COPIED')
		);
	};

	copyIFrameEmbedCode = (_) => {
		this.props.showNotification(
			this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_LABEL_IFRAME_CODE_COPIED')
		);
	};

	getIframeEmbedCode = (_) => {
		const {
			replay,
			autostart,
			selectedPlayerConfig,
			muteAtStart,
			fixedSize,
			playerSize,
			showDownloadButton,
			showSocialSharing,
			showOpenInNewWindow,
			uniqueFrame,
			language,
		} = this.state;
		const { defaultAccountId, placementType, mediaDetails } = this.props;
		if (!selectedPlayerConfig) {
			return '';
		}

		const title = mediaDetails?.metadata?.title;
		const themeOptionsDefault = selectedPlayerConfig.id;
		const mediaIdOrPlaylistId =
			placementType && placementType.toUpperCase() === 'MEDIAS'
				? 'mediaId'
				: placementType.toUpperCase() === 'SCENARIOS'
				? 'scenarioId'
				: 'playlistId';
		const width = fixedSize && playerSize === 'fixedSize' ? fixedSize.width : '100%';
		const height = fixedSize && playerSize === 'fixedSize' ? fixedSize.height : '100%';

		const muteAtStartAttr =
			this.originPlayerConfig && muteAtStart !== this.originPlayerConfig.muteAtStart
				? `&mute=${muteAtStart}`
				: '';
		const autostartAttr =
			this.originPlayerConfig && autostart !== this.originPlayerConfig.autostart ? `&autoplay=${autostart}` : '';
		const showSocialSharingAttr =
			this.originPlayerConfig && showSocialSharing !== this.originPlayerConfig.showSocialSharing
				? `&sharing=${showSocialSharing}`
				: '';
		const replayAttr =
			this.originPlayerConfig && replay !== this.originPlayerConfig.replay ? `&repeat=${replay}` : '';
		const showDownloadButtonAttr =
			this.originPlayerConfig && showDownloadButton !== this.originPlayerConfig.showDownloadButton
				? `&downloadMedia=${showDownloadButton}`
				: '';
		const showOpenInNewWindowAttr =
			this.originPlayerConfig && showOpenInNewWindow !== this.originPlayerConfig.showOpenInNewWindow
				? `&openInNewWindow=${showOpenInNewWindow}`
				: '';
		const uniqueFrameAttr = uniqueFrame ? `&uniqueFrame=${uniqueFrame}` : '';
		const languageAttr =
			language && this.originPlayerConfig && language !== this.originPlayerConfig.language
				? `&language=${language}`
				: '';

		if (playerSize === 'responsive') {
			return (
				`<div style="position:relative;padding-bottom:56.25%;">` +
				`<iframe title="${title}" src="${qplayerDomain}?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${mediaDetails.id}&configId=${themeOptionsDefault}${languageAttr}${autostartAttr}${showSocialSharingAttr}${showDownloadButtonAttr}${showOpenInNewWindowAttr}${replayAttr}${muteAtStartAttr}${uniqueFrameAttr}"` +
				` allowFullScreen="true" frameborder="0" border="0" height="${height}" width="${width}" style="position:absolute;top:0;left:0;"></iframe>` +
				`</div>`
			);
		}

		return `<iframe title="${title}" src="${qplayerDomain}?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${mediaDetails.id}&configId=${themeOptionsDefault}${languageAttr}${autostartAttr}${showSocialSharingAttr}${showDownloadButtonAttr}${showOpenInNewWindowAttr}${replayAttr}${muteAtStartAttr}${uniqueFrameAttr}"" allowFullScreen="true" frameborder="0" border="0" height="${height}" width="${width}"></iframe>`;
	};

	getPreviewLink = (_) => {
		const {
			replay,
			autostart,
			selectedPlayerConfig,
			muteAtStart,
			showDownloadButton,
			showSocialSharing,
			showOpenInNewWindow,
			uniqueFrame,
			language,
		} = this.state;
		const { mediaDetails, defaultAccountId, placementType } = this.props;
		if (!selectedPlayerConfig) {
			return '';
		}

		const mediaIdOrPlaylistId =
			placementType && placementType.toUpperCase() === 'MEDIAS'
				? 'mediaId'
				: placementType.toUpperCase() === 'SCENARIOS'
				? 'scenarioId'
				: 'playlistId';
		const themeOptionsDefault = selectedPlayerConfig.id;

		const muteAtStartAttr =
			this.originPlayerConfig && muteAtStart !== this.originPlayerConfig.muteAtStart
				? `&mute=${muteAtStart}`
				: '';
		const autostartAttr =
			this.originPlayerConfig && autostart !== this.originPlayerConfig.autostart ? `&autoplay=${autostart}` : '';
		const showSocialSharingAttr =
			this.originPlayerConfig && showSocialSharing !== this.originPlayerConfig.showSocialSharing
				? `&sharing=${showSocialSharing}`
				: '';
		const replayAttr =
			this.originPlayerConfig && replay !== this.originPlayerConfig.replay ? `&repeat=${replay}` : '';
		const showDownloadButtonAttr =
			this.originPlayerConfig && showDownloadButton !== this.originPlayerConfig.showDownloadButton
				? `&downloadMedia=${showDownloadButton}`
				: '';
		const showOpenInNewWindowAttr =
			this.originPlayerConfig && showOpenInNewWindow !== this.originPlayerConfig.showOpenInNewWindow
				? `&openInNewWindow=${showOpenInNewWindow}`
				: '';
		const uniqueFrameAttr = uniqueFrame ? `&uniqueFrame=${uniqueFrame}` : '';
		const languageAttr =
			language && this.originPlayerConfig && language !== this.originPlayerConfig.language
				? `&language=${language}`
				: '';

		return `${qplayerDomain}?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${mediaDetails.id}&configId=${themeOptionsDefault}${languageAttr}${autostartAttr}${showSocialSharingAttr}${showDownloadButtonAttr}${showOpenInNewWindowAttr}${replayAttr}${muteAtStartAttr}${uniqueFrameAttr}`;
	};

	getDivEmbedCode = (_) => {
		const {
			replay,
			autostart,
			selectedPlayerConfig,
			muteAtStart,
			showSocialSharing,
			showDownloadButton,
			showOpenInNewWindow,
			playerSize,
			fixedSize,
			softFullscreen,
			uniqueFrame,
			language,
		} = this.state;
		const { mediaDetails, defaultAccountId, placementType } = this.props;
		if (!selectedPlayerConfig) {
			return '';
		}

		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;
		const isUsingBonnierNews = selectedPlayerConfig && selectedPlayerConfig.id === BONNIER_NEW_PRESET_ID;

		const title = mediaDetails?.metadata?.title;
		const muteAtStartAttr =
			this.originPlayerConfig && muteAtStart !== this.originPlayerConfig.muteAtStart
				? `data-qplayer-mute="${muteAtStart}" `
				: '';
		const autostartAttr =
			this.originPlayerConfig && autostart !== this.originPlayerConfig.autostart
				? `data-qplayer-autoplay="${autostart}" `
				: '';
		const showSocialSharingAttr =
			this.originPlayerConfig && showSocialSharing !== this.originPlayerConfig.showSocialSharing
				? `data-qplayer-sharing="${showSocialSharing}" `
				: '';
		const replayAttr =
			this.originPlayerConfig && replay !== this.originPlayerConfig.replay
				? `data-qplayer-repeat="${replay}" `
				: '';
		const showDownloadButtonAttr =
			this.originPlayerConfig && showDownloadButton !== this.originPlayerConfig.showDownloadButton
				? `data-qplayer-download="${showDownloadButton}" `
				: '';
		const showOpenInNewWindowAttr =
			this.originPlayerConfig && showOpenInNewWindow !== this.originPlayerConfig.showOpenInNewWindow
				? `data-qplayer-openInNewWindow="${showOpenInNewWindow}" `
				: '';
		const uniqueFrameAttr = uniqueFrame ? `data-qplayer-unique-frame="${uniqueFrame}"` : '';
		const softFullscreenAttr = softFullscreen ? 'data-qplayer-inline="off" ' : '';
		const mediaType =
			placementType && placementType.toUpperCase() === 'MEDIAS'
				? 'media'
				: placementType.toUpperCase() === 'SCENARIOS'
				? 'scenario'
				: 'playlist';
		const languageAttr =
			language && this.originPlayerConfig && language !== this.originPlayerConfig.language
				? `data-qplayer-language="${language}"`
				: '';

		if (isUsingTheoPlayer) {
			return (
				`<div ${placementType !== 'playlist' ? 'bonnier-player-media-id' : 'bonnier-player-playlist-id'}="${
					mediaDetails.id
				}" ` +
				`account="${defaultAccountId}" ` +
				(autostart ? `autoplay ` : '') +
				`backdrop ` +
				(replay ? `replay ` : '') +
				(showDownloadButton ? `download ` : '') +
				(muteAtStart ? `muted ` : '') +
				`style="width:${fixedSize.width}px;height:${fixedSize.height}px"/>` +
				`<script type="text/javascript" src="${
					selectedPlayerConfig.layouts[0]?.playerStyling?.script ?? DEFAULT_THEO_PLAYER_EMBED
				}" async ></script>`
			);
		}

		if (isUsingBonnierNews) {
			return `<div class="bn-video-player" data-api-url="${readPublicApiEndpointV1}${defaultAccountId}/${mediaType}s/${mediaDetails.id}"></div>`;
		}

		const isDevEnv = process.env.NODE_ENV !== 'production';

		// video ratio 16:9, set padding-bottom 56.25%; video ratio 1:1, set padding-bottom 100%
		if (playerSize === 'responsive') {
			return (
				`<div style="position:relative;padding-bottom:56.25%;">` +
				`<div style="position:absolute;top:0;left:0;" ` +
				`title="${title}" ` +
				`data-qplayer-account-id="${defaultAccountId}" ` +
				`data-qplayer-${mediaType}-id="${mediaDetails.id}" ` +
				`data-qplayer-preset-id="${selectedPlayerConfig.id}" ` +
				`${isDevEnv ? 'data-qplayer-test="true" ' : ''}` +
				`${languageAttr}` +
				`${softFullscreenAttr}` +
				`${autostartAttr}` +
				`${showSocialSharingAttr}` +
				`${muteAtStartAttr}` +
				`${replayAttr}` +
				`${uniqueFrameAttr}` +
				`${showDownloadButtonAttr}` +
				`${showOpenInNewWindowAttr}></div>\n` +
				`<script id="qplayer" src="${qplayerLoaderUrl}"></script>` +
				`</div>`
			);
		}

		return (
			`<div style="width:${fixedSize.width}px;height:${fixedSize.height}px" ` +
			`title="${title}" ` +
			`data-qplayer-thumbnail-width="${fixedSize.width}" ` +
			`data-qplayer-thumbnail-height="${fixedSize.height}" ` +
			`data-qplayer-account-id="${defaultAccountId}" ` +
			`data-qplayer-${mediaType}-id="${mediaDetails.id}" ` +
			`data-qplayer-preset-id="${selectedPlayerConfig.id}" ` +
			`${isDevEnv ? 'data-qplayer-test="true" ' : ''}` +
			`${languageAttr}` +
			`${softFullscreenAttr}` +
			`${autostartAttr}` +
			`${showSocialSharingAttr}` +
			`${muteAtStartAttr}` +
			`${replayAttr}` +
			`${uniqueFrameAttr}` +
			`${showDownloadButtonAttr}></div>\n` +
			`<script id="qplayer" src="${qplayerLoaderUrl}"></script>`
		);
	};

	renderInformationSection = (_) => {
		const { t, mediaDetails, liveMediaChannelId, videoWidth } = this.props;
		const { videos } = this.state;
		const video = videos && videos.length > 0 ? videos[0] : undefined;
		return (
			<InformationSection
				t={t}
				mediaDetails={mediaDetails}
				liveMediaChannelId={liveMediaChannelId}
				width={videoWidth}
				codec={video?.codec}
				format={video?.mimeType}
				resolution={video ? `${video.width} x ${video.height}` : undefined}
			/>
		);
	};

	render() {
		const {
			t,
			defaultAccountId,
			mediaDetails,
			videoWidth,
			videoHeight,
			playerConfigurationId,
			scenario,
			placementType,
			breadCrumbHeight,
		} = this.props;
		const {
			selectedPlayerConfig,
			autostart,
			replay,
			muteAtStart,
			showSocialSharing,
			softFullscreen,
			showDownloadButton,
			showOpenInNewWindow,
		} = this.state;

		const isUsingTheoPlayer = selectedPlayerConfig && selectedPlayerConfig.id === THEO_PLAYER_PRESET_ID;
		const mediaType = scenario
			? 'scenario'
			: placementType && placementType.toUpperCase() === 'MEDIAS'
			? 'media'
			: placementType.toUpperCase() === 'SCENARIOS'
			? 'scenario'
			: 'playlist';

		return (
			<>
				{isUsingTheoPlayer && (
					<Helmet>
						<script
							id="theo-script-id"
							src={selectedPlayerConfig.layouts[0]?.playerStyling?.script ?? DEFAULT_THEO_PLAYER_EMBED}
							type="text/javascript"
							async="true"
						/>
					</Helmet>
				)}
				<div id="inspectViewLivePlayer" className="player-preview">
					{isUsingTheoPlayer ? (
						<div
							dangerouslySetInnerHTML={{
								__html: `<div id='player-preview' bonnier-player-${
									placementType === 'medias' ? 'media' : 'playlist'
								}-id= ${mediaDetails.id}
								account=${defaultAccountId}
								${autostart ? 'autoplay' : ''}
								backdrop
								${replay ? 'replay' : ''}
								${muteAtStart ? 'muted' : ''}
								${showDownloadButton ? 'download' : ''}
								style='width: ${videoWidth}px; height: ${videoHeight}px'/>`,
							}}
						/>
					) : (
						<div className="LivePlayerWrapper">
							<div
								id="player-preview"
								style={{ width: videoWidth + 'px', height: videoHeight + 'px' }}
								{...{ [`data-qplayer-${mediaType}-id`]: mediaDetails.id }}
								data-qplayer-id={mediaDetails.id}
								data-qplayer-account-id={defaultAccountId}
								data-qplayer-preset-id={
									selectedPlayerConfig ? selectedPlayerConfig.id : playerConfigurationId
								}
								data-qplayer-autoplay={autostart}
								data-qplayer-sharing={showSocialSharing}
								data-qplayer-openInNewWindow={showOpenInNewWindow}
								data-qplayer-mute={muteAtStart}
								data-qplayer-repeat={replay}
								data-qplayer-download={showDownloadButton}
								data-qplayer-inline={softFullscreen ? 'off' : 'on'}
								data-qplayer-thumbnail-height={`${videoHeight}px`}
								data-qplayer-thumbnail-width={`${videoWidth}px`}
								data-qplayer-analytics={'off'}
								data-qplayer-test={process.env.NODE_ENV !== 'production' ? 'true' : 'false'}
							/>
						</div>
					)}

					<div
						className="player-preview__embed-setting"
						style={{
							height: `calc(100% - ${videoHeight - (breadCrumbHeight ?? 0)}px)`,
							overflow: 'auto',
							paddingBottom: '24px',
						}}
					>
						<div className="player-preview__header">
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_PLAYER_INFO_EMBED_SETTING')}
							{this.renderVideoActions()}
						</div>
						{this.renderPlayerSettingSection()}
						{this.renderEmbedSection()}
						{this.renderInformationSection()}
					</div>
				</div>
			</>
		);
	}
}

InspectPlayerPreviewArea.propTypes = {
	mediaId: PropTypes.string,
};

const mapStateToProps = ({
	apps,
	session,
	publish,
	breadCrumbReducer,
	navReducer,
	inspectScreenReducer,
	interactTimelineReducer,
	interactElementsReducer,
	speechToText,
}) => ({
	apps: apps.apps,
	open: navReducer.open,
	loading: publish.loading,
	interactLoading: interactElementsReducer.loading,
	username: session.username,
	accounts: session.accounts,
	isLoggedIn: session.isLoggedIn,
	routeAfterSave: publish.routeAfterSave,
	defaultAccountId: session.defaultAccountId,
	locationArray: breadCrumbReducer.locationArray,
	isInspectScreenModified: publish.isInspectScreenModified,
	userPermissions: session.userPermissions,
	playerConfigId: inspectScreenReducer.playerConfigId,
	videoPlayerMuted: inspectScreenReducer.videoPlayerMuted,
	isInteractScreenModified: interactTimelineReducer.isInteractScreenModified,
	isMountedSubtitleUpdated: speechToText.isMountedSubtitleUpdated,
	currentComponentKey: navReducer.currentComponentKey,
	refreshMediasIds: publish.refreshMediasIds,
	refreshScenario: publish.refreshScenario,
});

const mapDispatchToProps = (dispatch) => ({
	updateScreenName: (name) => dispatch(updateScreenName(name)),
	sendMediaScreenChanged: (_) => dispatch(sendMediaScreenChanged()),
	showNotification: (message) => dispatch(showNotification(message)),
	sendMediaScreenUnChanged: (_) => dispatch(sendMediaScreenUnChanged()),
	clearBreadCrumbLocationArray: (_) => dispatch(clearBreadCrumbLocationArray()),
	changeRouteAfterInspectScreen: (route) => dispatch(changeRouteAfterInspectScreen(route)),
	updateBreadCrumbLocationArray: (key, location) => dispatch(updateBreadCrumbLocationArray(key, location)),
	deleteRestrictionRule: (accountId, restrictionId) => dispatch(deleteRestrictionRule(accountId, restrictionId)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	shareThePostOnSocialMedia: (accId, mediaId, post) => dispatch(shareThePostOnSocialMedia(accId, mediaId, post)),
	createNewRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(createNewRestrictionRule(accountId, restrictionId, body)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	updateExistingRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(updateExistingRestrictionRule(accountId, restrictionId, body)),
	applyRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(applyRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	deleteRestrictionRuleOnMedia: (accountId, mediaName, mediaId, restrictionId) =>
		dispatch(deleteRestrictionRuleOnMedia(accountId, mediaName, mediaId, restrictionId)),
	setSelectedApp: (app) => dispatch(setSelectedApp(app)),
	clearInspectScreenStorage: () => dispatch(clearInspectScreenStorage()),
	updateMenuKeys: (key) => dispatch(updateMenuKeys(key)),
	addMediaDetails: (details) => dispatch(addMediaDetails(details)),
	setIsMountedSubtitleUpdated: (mountedSubUpdate) => dispatch(setIsMountedSubtitleUpdated(mountedSubUpdate)),
	updateCurentComponentKey: (key) => dispatch(updateCurentComponentKey(key)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	fetchPlayerEmbedConfiguration: (accId) => dispatch(fetchPlayerEmbedConfiguration(accId)),
	setSidePlayerConfigId: (value) => dispatch(setSidePlayerConfigId(value)),
	loadPlayerConfiguration: (accountId) => dispatch(loadPlayerConfiguration(accountId)),
	getAccountSettings: (accountId) => dispatch(getAccountSettings(accountId)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(InspectPlayerPreviewArea);
