import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { showAlert } from '../../actions/globalActions';

import './Global.scss';

class Global extends PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		window.addEventListener('beforeunload', this.handleBeforeWindowUnload);
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props.stackMessage) !== JSON.stringify(prevProps.stackMessage)) {
			this.showMessage(this.props.stackMessage.message, this.props.stackMessage.type);
		}
		if (JSON.stringify(this.props.stackAlert) !== JSON.stringify(prevProps.stackAlert)) {
			this.showAlert(this.props.stackAlert.message, this.props.stackAlert.type);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('beforeunload', this.handleBeforeWindowUnload);
	}

	showAlert = (message, type) => {
		if (!message) {
			return;
		}

		this.props.enqueueSnackbar(message, {
			variant: type,
			autoHideDuration: null,
			persist: true,
			root: {
				top: '65px',
			},
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			className: 'inspectSnackBar',
			action: (messId) => (
				<IconButton
					size="small"
					onClick={() => {
						this.props.closeSnackbar(messId);
						this.props.showAlert('');
					}}
				>
					<Clear htmlColor="#fff" />
				</IconButton>
			),
		});
	};

	showMessage = (message, type) => {
		this.props.enqueueSnackbar(message, {
			variant: type,
			autoHideDuration: 3000,
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'right',
			},
			className: 'inspectSnackBar',
		});
	};

	handleBeforeWindowUnload = (event) => {
		if (this.props.isUploading) {
			event.preventDefault();
			let confirmationMessage = 'prompt-before-unload';

			(event || window.event).returnValue = confirmationMessage;
			return confirmationMessage;
		}
	};

	render() {
		return <></>;
	}
}

const mapStateToProps = ({ session, globalReducer, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	stackMessage: globalReducer.stackMessage,
	stackAlert: globalReducer.stackAlert,
	isAEEditorOpen: session.isAEEditorOpen,
	isUploading: publish.isUploading,
});

const mapDispatchToProps = (dispatch) => ({
	showAlert: (value, type) => dispatch(showAlert(value, type)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(withSnackbar(Global));
