import React, { useCallback, forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingOverlay from 'react-loading-overlay';
import { ScaleLoader } from 'react-spinners';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import _ from 'lodash';

import display_blue_fill from '../../../assets/images/display-blue-fill.svg';
import phone_white_fill from '../../../assets/images/phone-white-fill.svg';
import display_grey_fill_large from '../../../assets/images/display-grey-fill-large.svg';
import phone_blue_fill_large from '../../../assets/images/phone-blue-fill-large.svg';
import { joinClassNames } from '../../../services/elementHelperService';
import {
	getScenario,
	setRefreshSceneMedia,
	setOriginScenarioAction,
	setSceneLoading,
	updateScenarioActiveLayoutAction,
	updateUploadingScenesAction,
} from '../../../actions/scenarioActions';
import { showMessage } from '../../../actions/globalActions';
import { messageTypes } from '../../../constants/mediaConstants';
import { screenRoutes } from '../../../constants/routesPath';
import { getDifferenceBetweenTime } from '../../../services/timeStampService';
import { getEncoderJobs, refreshMedias } from '../../../actions/publishActions';
import { qplayerDomain } from '../../../utils/config';

const baseClassName = 'scenario-chart';
const headerBaseClassName = `${baseClassName}__header`;
const bodyBaseClassName = `${baseClassName}__body`;

const SAVING_STATE_IDLE = 'SCENARIO_STORYBOARD_SAVING_STATE_IDLE';
const SAVING_STATE_SAVING = 'SCENARIO_STORYBOARD_SAVING_STATE_SAVING';

const ScenarioScreenContainer = forwardRef(function ScenarioContainer(props, ref) {
	const dispatch = useDispatch();
	const param = useParams();
	const history = useHistory();

	const { t } = props;

	const sceneLoading = useSelector((state) => state.scenarioReducer.sceneLoading);
	const scenario = useSelector((state) => state.scenarioReducer.scenario);
	const scenes = useSelector((state) => state.scenarioReducer.scenes);
	const uploadingScenes = useSelector((state) => state.scenarioReducer.uploadingScenes);
	const sceneMediaIdsToRefresh = useSelector((state) => state.scenarioReducer.sceneMediaIdsToRefresh);
	const activeLayout = useSelector((state) => state.scenarioReducer.activeLayout ?? 'desktop');
	const defaultAccountId = useSelector((state) => state.session.defaultAccountId);
	const editingScene = useSelector((state) => state.scenarioReducer.editingScene);

	const onGoingEncodingIntervalId = useRef(undefined);
	const [savingState, setSavingState] = useState(SAVING_STATE_IDLE);
	const [durationFromLastUpdated, setDurationFromLastUpdated] = useState('');
	const [lastUpdated, setLastUpdated] = useState(null);
	const [showPreviewModal, setShowPreviewModal] = useState(false);

	useEffect(() => {
		setLastUpdated(scenario.updated ? new Date(scenario.updated) : null);
	}, [scenario.updated]);

	useEffect(() => {
		setDurationFromLastUpdated(
			new Date() - lastUpdated > 1000 ? getDifferenceBetweenTime(new Date(), lastUpdated) : ''
		);
		const calculatedDurationFromLastUpdatedTimer = setInterval(() => {
			if (!lastUpdated) {
				setDurationFromLastUpdated('');
				return;
			}
			setDurationFromLastUpdated(getDifferenceBetweenTime(new Date(), lastUpdated));
		}, 10000);

		return () => {
			clearInterval(calculatedDurationFromLastUpdatedTimer);
		};
	}, [lastUpdated]);

	useImperativeHandle(ref, () => ({
		onSaveScenario: (isRedirectUser) => {
			return onSaveChanges(isRedirectUser);
		},
		isAutoSaving: (value) => {
			if (value) {
				setSavingState(SAVING_STATE_SAVING);
				return;
			}
			setLastUpdated(new Date());
			setSavingState(SAVING_STATE_IDLE);
		},
	}));

	const setActiveLayout = useCallback(
		(layout) => {
			dispatch(updateScenarioActiveLayoutAction(layout));
			props.onActiveLayoutChange?.(layout);
		},
		[props.onActiveLayoutChange, dispatch]
	);

	const _loadScenario = (forceLoad = false) => {
		if (!forceLoad && (!scenario || !scenario.id)) {
			dispatch(setSceneLoading(false));
			return;
		}
		dispatch(getScenario(defaultAccountId, scenario.id)).then((updatedScenario) => {
			if (!updatedScenario) {
				dispatch(setSceneLoading(false));
				dispatch(showMessage("Can't get scenario", messageTypes.error));
				return;
			}

			dispatch(setOriginScenarioAction(updatedScenario));
			dispatch(setSceneLoading(false));
		});
	};

	//remove soon
	const onSaveChanges = (isRedirectUser) => {
		if (!scenario || !scenario.id) {
			setSavingState(SAVING_STATE_IDLE);
			return;
		}

		if (!scenario.defaults?.sceneId) {
			setSavingState(SAVING_STATE_IDLE);
			dispatch(showMessage(t('SCENARIO_STORYBOARD_MISSING_HOME_SCENE'), messageTypes.info));
			return;
		}

		if (isRedirectUser) {
			dispatch(setSceneLoading(true));
		}

		props.onSaveChangesClick?.();

		return Promise.resolve();
	};

	const changeScene = (sceneId) => {
		history.push(`${screenRoutes.PUBLISH_SCENARIOS}/${param.scenarioId}/detail/scenes/${sceneId}/edit`, '_self');
	};

	const sortedScene = _.orderBy(scenes, [(scene) => scene.name?.toLowerCase(), 'id'], ['asc']);

	const checkForOngoingEncoding = useCallback(() => {
		dispatch(getEncoderJobs(defaultAccountId)).then((res) => {
			const allJobs = res?.jobs ?? [];
			let newFinishedJobs = [];
			const newEncodingFiles = [];
			for (let i = 0; i < allJobs.length; i++) {
				const job = allJobs[i];
				if (job?.status === 'SUCCESS') {
					newFinishedJobs.push(job.media_id);
				} else if ((job.steps ?? []).find((s) => s.type === 'encode' && s.status === 'SUCCESS')) {
					newFinishedJobs.push(job.media_id);
				} else {
					newEncodingFiles.push(job.media_id);
				}
			}

			newFinishedJobs = newFinishedJobs.filter((f) => uploadingScenes.find((u) => u === f));
			if (newFinishedJobs.join(',') !== sceneMediaIdsToRefresh.join(',')) {
				dispatch(refreshMedias());
			}

			dispatch(setRefreshSceneMedia(newFinishedJobs));
			dispatch(updateUploadingScenesAction(newEncodingFiles));
		});
	}, [defaultAccountId, dispatch, sceneMediaIdsToRefresh, uploadingScenes]);

	const getPreviewLink = useCallback(() => {
		return `${qplayerDomain}?accountId=${defaultAccountId}&scenarioId=${scenario?.id}${
			editingScene?.id ? `&sceneId=` + editingScene.id : ''
		}&configId=Interactive`;
	}, [defaultAccountId, editingScene?.id, scenario?.id]);

	const onPreviewBtnClick = useCallback(() => {
		props.onOpenPreview?.();
		setShowPreviewModal(true);
	}, [props.onOpenPreview]);

	useEffect(() => {
		if (onGoingEncodingIntervalId?.current) {
			onGoingEncodingIntervalId?.current && clearInterval(onGoingEncodingIntervalId.current);
		}
		onGoingEncodingIntervalId.current = setInterval(checkForOngoingEncoding, 10000);

		return () => onGoingEncodingIntervalId?.current && clearInterval(onGoingEncodingIntervalId.current);
	}, [checkForOngoingEncoding]);

	return (
		<>
			<div className={joinClassNames(baseClassName, props.classNames)}>
				<div className={headerBaseClassName}>
					<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--left`}>
						<Button color="primary" startIcon={<ChevronLeft />} onClick={props.onExitButtonClick}>
							{props.t('COMMON_BACK')}
						</Button>
						{param.sceneId && (
							<Select variant="standard" value={param.sceneId} className={`${baseClassName}__select`}>
								{sortedScene.map((scene) => (
									<MenuItem
										value={scene?.id}
										key={scene?.id}
										onClick={() => {
											changeScene(scene?.id);
										}}
									>
										{scene?.name?.length > 10 ? `${scene.name.substring(0, 10)}...` : scene?.name}
									</MenuItem>
								))}
							</Select>
						)}
					</div>
					{!props.hideDevicesSwitch && (
						<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--center`}>
							<ButtonGroup>
								<Button
									variant="outlined"
									color={activeLayout === 'desktop' ? 'primary' : undefined}
									onClick={() => setActiveLayout('desktop')}
									title={t('COMMON_DESKTOP')}
								>
									<img
										style={{ width: '15px', height: '12px' }}
										src={activeLayout === 'desktop' ? display_blue_fill : display_grey_fill_large}
									/>
								</Button>
								<Button
									variant="outlined"
									color={activeLayout === 'mobile' ? 'primary' : undefined}
									onClick={() => setActiveLayout('mobile')}
									title={t('COMMON_MOBILE')}
								>
									<img
										style={{ width: '9px', height: '16px' }}
										src={activeLayout === 'mobile' ? phone_blue_fill_large : phone_white_fill}
									/>
								</Button>
							</ButtonGroup>
						</div>
					)}
					<div className={`${headerBaseClassName}-slot ${headerBaseClassName}-slot--right`}>
						<div className={`${baseClassName}__saving-status`}>
							<span>{`${t(savingState)}`}</span>
							<div>
								{lastUpdated &&
									durationFromLastUpdated !== '' &&
									`${t('SCENARIO_STORYBOARD_LAST_SAVED')} ${durationFromLastUpdated}`}
							</div>
						</div>
						<Button
							variant="outlined"
							color="primary"
							onClick={onPreviewBtnClick}
							style={{ marginRight: '12px' }}
						>
							{props.t('COMMON_PREVIEW')}
						</Button>
					</div>
				</div>

				<div className={bodyBaseClassName ? bodyBaseClassName : 'scenario-chart__body'}>
					{props.children}
					{sceneLoading && (
						<LoadingOverlay
							className="loadingStreamOverlay"
							active
							spinner={<ScaleLoader color={'#ffffff'} />}
							text={''}
						/>
					)}
				</div>

				<Dialog
					fullWidth={true}
					maxWidth={'lg'}
					open={showPreviewModal && scenario}
					onClose={() => setShowPreviewModal(false)}
				>
					<DialogContent style={{ padding: '0', margin: '0', overflow: 'hidden' }}>
						<div
							style={{
								position: 'relative',
								paddingBottom: '56.25%',
								height: '100%',
								width: '100%',
								backgroundColor: '#000',
							}}
						>
							<iframe
								title={scenario?.metadata?.title}
								src={getPreviewLink()}
								width={'100%'}
								height={'100%'}
								style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
								allowFullScreen="true"
								frameborder="0"
								border="0"
							/>
						</div>
						<IconButton
							onClick={() => setShowPreviewModal(false)}
							style={{ position: 'absolute', right: '0', top: '0' }}
						>
							<Close fontSize="large" htmlColor="#fff" />
						</IconButton>
					</DialogContent>
				</Dialog>
			</div>
		</>
	);
});

export default ScenarioScreenContainer;
