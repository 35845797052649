import React, { useState } from 'react';
import { WidgetProps } from './WidgetProps';

const generateTextStyle = ({
	isShowWidget,
	style,
	isHover,
}: {
	isShowWidget?: boolean;
	style: WidgetProps.StyleProps;
	isHover?: boolean;
}) => {
	const {
		textAlignment,
		contentSpacing,
		headerLabel: {
			font: headerFont,
			fontSize: headerFontSize,
			fontWeight: headerFontWeight,
			lineHeight: headerLineHeight,
			color: headerColor,
			shadowColor: headerShadowColor,
		},
		textLabel: {
			font: paragraphFont,
			fontSize: paragraphFontSize,
			fontWeight: paragraphFontWeight,
			lineHeight: paragraphLineHeight,
			color: paragraphColor,
			shadowColor: paragraphShadowColor,
		},
		backgroundSettings: {
			backgroundColor,
			borderType,
			borderWidth,
			borderColor,
			hoverBackgroundColor,
			hoverBorderType,
			hoverBorderWidth,
			hoverBorderColor,
		},
		transformSettings: {
			paddingBottom,
			paddingLeft,
			paddingRight,
			paddingTop,
			radiusBottom,
			radiusLeft,
			radiusRight,
			radiusTop,
			hoverRadiusTop,
			hoverRadiusRight,
			hoverRadiusBottom,
			hoverRadiusLeft,
		},
	} = style;

	const backgroundStyle = {
		display: isShowWidget ? 'flex' : 'none',
		flexDirection: 'column',
		backgroundColor: !isHover ? backgroundColor : hoverBackgroundColor,
		border: !isHover
			? `${borderWidth}px ${borderType} ${borderColor}`
			: `${hoverBorderWidth}px ${hoverBorderType} ${hoverBorderColor}`,
		paddingBottom: `${paddingBottom}px`,
		paddingLeft: `${paddingLeft}px`,
		paddingRight: `${paddingRight}px`,
		paddingTop: `${paddingTop}px`,
		borderTopRightRadius: !isHover ? `${radiusTop}px` : `${hoverRadiusTop}px`,
		borderBottomRightRadius: !isHover ? `${radiusRight}px` : `${hoverRadiusRight}px`,
		borderBottomLeftRadius: !isHover ? `${radiusBottom}px` : `${hoverRadiusBottom}px`,
		borderTopLeftRadius: !isHover ? `${radiusLeft}px` : `${hoverRadiusLeft}px`,
		width: '100%',
		height: '100%',
	};

	const headerStyle = {
		fontFamily: `${headerFont}, Arial, sans-serif`,
		fontSize: `${headerFontSize}px`,
		fontWeight: headerFontWeight,
		lineHeight: `${headerLineHeight}%`,
		textAlign: textAlignment,
		color: headerColor,
		textShadow: `2px 2px ${headerShadowColor}`,
		marginBottom: `${contentSpacing}px`,
	};

	const paragraphStyle = {
		fontFamily: `${paragraphFont}, Arial, sans-serif`,
		fontSize: `${paragraphFontSize}px`,
		fontWeight: paragraphFontWeight,
		lineHeight: `${paragraphLineHeight}%`,
		textAlign: textAlignment,
		color: paragraphColor,
		textShadow: `2px 2px ${paragraphShadowColor}`,
	};

	return {
		headerStyle,
		paragraphStyle,
		backgroundStyle,
	};
};

const TextWidget = ({
	id,
	isShowWidget,
	itemRef,
	onClickItem,
	widgetTemplateId,
	style,
	isSafari,
	onContextMenu,
}: WidgetProps.BaseProps) => {
	const [isHover, setIsHover] = useState(false);

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const {
		headerLabel: { text: header },
		textLabel: { text: paragraph },
	} = style;

	const { headerStyle, paragraphStyle, backgroundStyle } = generateTextStyle({ isShowWidget, style, isHover });

	return (
		<g id={widgetTemplateId} className={`${!isSafari ? 'widget' : ''} widget_${widgetTemplateId}`}>
			<g
				ref={itemRef}
				data-widget-id={id}
				data-template-id={widgetTemplateId}
				onClick={(e) => {
					e.stopPropagation();
					isShowWidget && onClickItem?.(itemRef);
				}}
				onContextMenu={(e) => {
					e.preventDefault();
					onContextMenu?.(itemRef, !!isShowWidget);
					return false;
				}}
			>
				<foreignObject>
					<div
						className="text-widget"
						style={backgroundStyle as any}
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
					>
						{headerStyle && <div style={headerStyle as any}>{header}</div>}
						<div style={paragraphStyle as any}>{paragraph}</div>
					</div>
				</foreignObject>
			</g>
		</g>
	);
};

export default TextWidget;
