import React from 'react';
import { VideoLibraryOutlined } from '@material-ui/icons';

export namespace MediaDetailUtils {
	export const MenuItems = [
		{
			value: 'detail',
			icon: <VideoLibraryOutlined />,
			activeIcon: <VideoLibraryOutlined color="primary" />,
		},
	];
}
