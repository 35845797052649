import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormControlLabel, Switch, Tab, Tabs, TextField } from '@material-ui/core';

import { joinClassNames } from '../../../../services/elementHelperService';
import { ViewerEngagementTemplate } from '../ViewerEngagementTemplate';
import { QuestionAndAnswerTemplate } from './QuestionAnsAnswerTemplate';

import './QuestionAndAnswerContainer.scss';
import { useFetchQnAData } from './useFetchQnAData';
import { useDispatch, useSelector } from 'react-redux';
import {
	answerQuestion,
	approveQuestion,
	createQnA,
	discardQuestion,
	publishQnAPrompt,
	unpublishQnAPrompt,
	updateQnA,
} from '../../../../actions/liveChatActions';
import { generateId } from '../../../../services/stringHelperService';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import { InfoOutlined } from '@material-ui/icons';

interface QuestionAndAnswerContainerProps {
	mediaId: string;
	settings: any;
}

const qnaBaseClassName = QuestionAndAnswerTemplate.qnaBaseClassName;

const QuestionAndAnswerContainer: React.FC<QuestionAndAnswerContainerProps> = ({ mediaId, settings }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId, username } = useSelector((state: any) => state.session);

	const [selectedTab, setSelectedTab] = useState<string>(QuestionAndAnswerTemplate.TAB.review);
	const [refresh, setRefresh] = useState<boolean>(false);
	const [isQnAActive, setIsQnAActive] = useState<boolean>(false);
	const [promptValue, setPromptValue] = useState<string>('');
	const [answeringQuestion, setAnsweringQuestion] = useState<QuestionAndAnswerTemplate.Question | undefined>(
		undefined
	);
	const [answerText, setInputText] = useState<string>('');
	const [currentQnA, setCurrentQnA] = useState<any | undefined>(undefined);

	const { qnas, questions } = useFetchQnAData({ defaultAccountId, mediaId, refresh });

	useEffect(() => {
		if (!qnas || qnas.length === 0) {
			return;
		}
		setCurrentQnA(qnas[0]);
		setPromptValue(qnas[0]?.text);
		setIsQnAActive(qnas[0]?.published);
	}, [qnas]);

	const onTabChange = useCallback((_: React.ChangeEvent<{}>, value: string) => {
		setSelectedTab(value);
	}, []);

	const onToggleQnA = useCallback(
		(_: React.ChangeEvent<{}>, checked: boolean) => {
			setIsQnAActive(checked);
			if (checked) {
				const qnaId = currentQnA?.id ?? generateId();
				const body = {
					id: qnaId,
					sender: {
						userId: username,
						name:
							settings && settings.adminProfile && settings.adminProfile.chatName
								? settings.adminProfile.chatName
								: 'admin',
						admin: true,
						email: username,
					},
					text: promptValue,
				};
				if (!currentQnA) {
					dispatch(createQnA({ accountId: defaultAccountId, mediaId, body }))
						.then(() => {
							dispatch(publishQnAPrompt({ accountId: defaultAccountId, mediaId, qnaId }))
								.then(() => {
									dispatch(
										showMessage(
											t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_PUBLISHED'),
											messageTypes.success
										)
									);
									setRefresh((prev) => !prev);
								})
								.catch((e: any) =>
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_CANNOT_BE_PUBLISHED'
											)} ${e}`,
											messageTypes.error
										)
									)
								);
						})
						.catch((e: any) =>
							dispatch(
								showMessage(
									`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_FAILED')} ${e}`,
									messageTypes.error
								)
							)
						);
				} else {
					dispatch(updateQnA({ accountId: defaultAccountId, mediaId, qnaId, body }))
						.then(() => {
							dispatch(publishQnAPrompt({ accountId: defaultAccountId, mediaId, qnaId }))
								.then(() => {
									dispatch(
										showMessage(
											t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_PUBLISHED'),
											messageTypes.success
										)
									);
									setRefresh((prev) => !prev);
								})
								.catch((e: any) =>
									dispatch(
										showMessage(
											`${t(
												'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_CANNOT_BE_PUBLISHED'
											)} ${e}`,
											messageTypes.error
										)
									)
								);
						})
						.catch((e: any) =>
							dispatch(
								showMessage(
									`${t(
										'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_UPDATE_PROMPT_FAILED'
									)} ${e}`,
									messageTypes.error
								)
							)
						);
				}
			} else {
				if (!currentQnA) {
					return;
				}
				dispatch(unpublishQnAPrompt({ accountId: defaultAccountId, mediaId, qnaId: currentQnA?.id }))
					.then(() => {
						dispatch(
							showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_UNPUBLISHED'),
								messageTypes.success
							)
						);
						setRefresh((prev) => !prev);
					})
					.catch((e: any) =>
						dispatch(
							showMessage(
								`${t(
									'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_CANNOT_BE_UNPUBLISHED'
								)} ${e}`,
								messageTypes.error
							)
						)
					);
			}
		},
		[currentQnA, defaultAccountId, dispatch, mediaId, promptValue, settings, t, username]
	);

	const onPromptValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPromptValue(event.target.value);
	}, []);

	const onAnswerInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setInputText(event.target.value);
	}, []);

	const onApproveQuestion = useCallback(
		(question: QuestionAndAnswerTemplate.Question) => {
			if (!question?.id) {
				return;
			}
			dispatch(
				approveQuestion({
					accountId: defaultAccountId,
					mediaId,
					questionId: question.id,
					qnaId: question.qnaId,
					body: {
						approve: true,
						id: question.id,
						sender: {
							userId: username,
							name:
								settings && settings.adminProfile && settings.adminProfile.chatName
									? settings.adminProfile.chatName
									: 'admin',
							email: username,
							admin: true,
						},
					},
				})
			)
				.then(() => {
					setRefresh((prev) => !prev);
					dispatch(
						showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QUESTION_APPROVED_MESSAGE'),
							messageTypes.success
						)
					);
				})
				.catch((e: any) =>
					dispatch(
						showMessage(
							`${t(
								'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QUESTION_CANNOT_BE_APPROVED_MESSAGE'
							)} ${e}`,
							messageTypes.error
						)
					)
				);
		},
		[defaultAccountId, dispatch, mediaId, settings, username]
	);

	const onDiscardQuestion = useCallback(
		(question: QuestionAndAnswerTemplate.Question) => {
			if (!question?.id) {
				return;
			}
			dispatch(
				discardQuestion({
					accountId: defaultAccountId,
					mediaId,
					questionId: question.id,
					qnaId: question.qnaId,
					body: {
						discard: true,
						id: question.id,
						sender: {
							userId: username,
							name:
								settings && settings.adminProfile && settings.adminProfile.chatName
									? settings.adminProfile.chatName
									: 'admin',
							email: username,
							admin: true,
						},
					},
				})
			)
				.then(() => {
					setRefresh((prev) => !prev);
					dispatch(
						showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_DISCARDED_QUESTION_MESSAGE'),
							messageTypes.success
						)
					);
				})
				.catch((e: any) =>
					dispatch(
						showMessage(
							`${t(
								'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CANNOT_DISCARD_QUESTION_MESSAGE'
							)} ${e}`,
							messageTypes.error
						)
					)
				);
		},
		[defaultAccountId, dispatch, mediaId, settings, username]
	);

	const onUndiscardQuestion = useCallback(
		(question: QuestionAndAnswerTemplate.Question) => {
			if (!question?.id) {
				return;
			}
			dispatch(
				discardQuestion({
					accountId: defaultAccountId,
					mediaId,
					questionId: question.id,
					qnaId: question.qnaId,
					body: {
						discard: false,
						id: question.id,
						sender: {
							userId: username,
							name:
								settings && settings.adminProfile && settings.adminProfile.chatName
									? settings.adminProfile.chatName
									: 'admin',
							email: username,
							admin: true,
						},
					},
				})
			).then(() => setRefresh((prev) => !prev));
		},
		[defaultAccountId, dispatch, mediaId, settings, username]
	);

	const onAnswerButtonClick = useCallback((question?: QuestionAndAnswerTemplate.Question) => {
		setAnsweringQuestion(question);
	}, []);

	const onAnswerQuestion = useCallback(
		(_event: React.MouseEvent) => {
			if (!answeringQuestion?.id) {
				return;
			}
			dispatch(
				answerQuestion({
					accountId: defaultAccountId,
					mediaId,
					questionId: answeringQuestion.id,
					qnaId: answeringQuestion.qnaId,
					body: {
						reply: answerText,
						id: answeringQuestion.id,
						sender: {
							userId: username,
							name:
								settings && settings.adminProfile && settings.adminProfile.chatName
									? settings.adminProfile.chatName
									: 'admin',
							email: username,
							admin: true,
						},
					},
				})
			).then(() => {
				setRefresh((prev) => !prev);
				setAnsweringQuestion(undefined);
				setInputText('');
			});
		},
		[answerText, answeringQuestion, defaultAccountId, dispatch, mediaId, settings, username]
	);

	const answeredQuestionIds = useMemo(() => {
		return questions?.filter((q) => !!q?.recipient?.replyQuestionId).map((q) => q?.recipient?.replyQuestionId);
	}, [questions]);

	return (
		<>
			<ViewerEngagementTemplate.TabContentWrapper
				useShortContainer={!!answeringQuestion}
				className={`${qnaBaseClassName}__wrapper`}
			>
				<FormControl fullWidth className={`${qnaBaseClassName}__header`}>
					<FormControlLabel
						label={
							<>
								<ViewerEngagementTemplate.Indicator isActive={isQnAActive && !!promptValue} />
								{t(
									isQnAActive && !!promptValue
										? 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_ACTIVE_LABEL'
										: 'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_INACTIVE_LABEL'
								)}
							</>
						}
						labelPlacement="start"
						onChange={onToggleQnA}
						control={<Switch color="primary" checked={isQnAActive && !!promptValue} />}
						disabled={promptValue === ''}
					/>
				</FormControl>

				<FormControl fullWidth className={`${qnaBaseClassName}__prompt`}>
					<FormControlLabel
						label={
							<div style={{ display: 'flex' }}>
								<span style={{ marginRight: '5px' }}>
									{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_LABEL')}
								</span>
								<span
									title={t(
										'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SET_PROMPT_ACTIVE_EDIT_DESCRIPTION'
									)}
								>
									<InfoOutlined htmlColor="rgba(255, 255, 255, 0.6)" className="infoIcon" />
								</span>
							</div>
						}
						labelPlacement="top"
						placeholder={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_QNA_PROMPT_PLACEHOLDER')}
						control={
							<TextField
								fullWidth
								variant="standard"
								value={promptValue}
								color="primary"
								onChange={onPromptValueChange}
								disabled={isQnAActive}
							/>
						}
					/>
				</FormControl>

				<Tabs
					value={selectedTab}
					onChange={onTabChange}
					className={`${qnaBaseClassName}__tab-bar ${ViewerEngagementTemplate.baseClassName}__tab-bar--horizontal`}
					TabIndicatorProps={{
						style: {
							color: '#ffffff',
							backgroundColor: 'transparent',
						},
					}}
				>
					{QuestionAndAnswerTemplate.TabItems.map((item) => (
						<Tab
							title={item.value === 'archive' ? 'Archive' : undefined}
							value={item.value}
							label={typeof item.label === 'string' ? t(item.label) : item.label}
							key={item.value}
							className={joinClassNames([
								`${ViewerEngagementTemplate.baseClassName}__tab`,
								item.value === selectedTab
									? `${ViewerEngagementTemplate.baseClassName}__tab--selected`
									: '',
							])}
						/>
					))}
				</Tabs>

				{selectedTab === QuestionAndAnswerTemplate.TAB.review && (
					<div className={`${qnaBaseClassName}__panel`}>
						<QuestionAndAnswerTemplate.ReviewPanel
							questions={questions?.filter(
								(q) => !q.approved && !q.discarded && !q.reply && !answeredQuestionIds?.includes(q.id)
							)}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
						/>
					</div>
				)}

				{selectedTab === QuestionAndAnswerTemplate.TAB.approved && (
					<div className={`${qnaBaseClassName}__panel`}>
						<QuestionAndAnswerTemplate.ApprovedPanel
							questions={questions?.filter(
								(q) => q.approved && !q.discarded && !q.reply && !answeredQuestionIds?.includes(q.id)
							)}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
							onAnswerButtonClick={onAnswerButtonClick}
						/>
					</div>
				)}

				{selectedTab === QuestionAndAnswerTemplate.TAB.answered && (
					<div className={`${qnaBaseClassName}__panel`}>
						<QuestionAndAnswerTemplate.AnsweredPanel
							allQuestions={questions}
							questions={questions?.filter(
								(q) => !q.reply && !q.discarded && answeredQuestionIds?.includes(q.id)
							)}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
						/>
					</div>
				)}

				{selectedTab === QuestionAndAnswerTemplate.TAB.archive && (
					<div className={`${qnaBaseClassName}__panel`}>
						<QuestionAndAnswerTemplate.ArchivePanel
							allQuestions={questions}
							questions={questions?.filter((q) => q.discarded && !q.reply)}
							onApproveQuestion={onApproveQuestion}
							onDiscardQuestion={onDiscardQuestion}
							onUndiscardQuestion={onUndiscardQuestion}
						/>
					</div>
				)}
			</ViewerEngagementTemplate.TabContentWrapper>

			{answeringQuestion && (
				<ViewerEngagementTemplate.Input
					value={answerText}
					onChange={onAnswerInputChange}
					onSendClick={onAnswerQuestion}
				>
					<QuestionAndAnswerTemplate.QuestionPanel
						question={answeringQuestion}
						isAnswering
						onAnswerButtonClick={onAnswerButtonClick}
					/>
				</ViewerEngagementTemplate.Input>
			)}
		</>
	);
};

export default QuestionAndAnswerContainer;
