import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { dialogSizes } from '../../../../../../constants/dialogSizesConstant';
import { showNotification } from '../../../../../../actions/notificationActions';
import { getPlayIcon } from '../../../../../../services/mediaDisplayService';
import { showMessage } from '../../../../../../actions/globalActions';
import { messageTypes } from '../../../../../../constants/mediaConstants';

import './UploadSubtitleDialog.scss';

const styles = (theme) => ({
	form: {
		display: 'flex',
		flexDirection: 'column',
		margin: 'auto',
		width: 'fit-content',
	},
	formControl: {
		//marginTop: theme.spacing.unit * 2,
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
});

class UploadSubtitleDialog extends Component {
	state = {
		fullWidth: true,
		maxWidth: dialogSizes.SMALL,
		filePresent: false,
		isImpairedChecked: false,
		//languageOptionsDropDownMenu:languageOptionsDropDownMenu
	};

	componentDidMount() {
		this.props.handleHearingImpairedChange(false);
	}

	_getThePlayIcon = (mimeType) => {
		const splittedFileType = mimeType.split('/');
		return getPlayIcon(splittedFileType[0], false);
	};

	toggleFilePresent = (_) => {
		if (this.state.filePresent === false) {
			this.setState({ filePresent: true });
		} else {
			this.setState({ filePresent: false });
		}
	};

	getDialogStyle = (_) => ({ paddingLeft: 53, paddingTop: 0, paddingRight: 53, paddingBottom: 0 });

	handleChangeImpairedCheckbox = (_) => {
		if (this.state.isImpairedChecked === false) {
			this.setState({ isImpairedChecked: true });
			this.props.handleHearingImpairedChange(true);
		} else {
			this.setState({ isImpairedChecked: false });
			this.props.handleHearingImpairedChange(false);
		}
	};

	render() {
		const {
			t,
			closeInformationDialog,
			handleSubtitleLanguageChange,
			beginTheMediaItemsUploadProcess,
			loadTheSelectedMediaItems,
			handleRemoveTheSelectedMediaItem,
			state,
			open,
			showMessage,
		} = this.props;

		return (
			<>
				<Dialog
					id="SubtitleDialog"
					style={this.getDialogStyle()}
					fullWidth={true}
					maxWidth={dialogSizes.SMALL}
					open={open}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					onClose={closeInformationDialog}
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_ADD_NEW_SUBTITLE')}
						</Typography>
						<IconButton onClick={closeInformationDialog}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div className="dialogContentWrapper container">
							{this.state.filePresent ? (
								''
							) : (
								<Dropzone
									maxFiles={1}
									multiple={false}
									parallelChunkUploads={true}
									accept=".srt, .vtt, .json, .dfxp, .ttml"
									onDrop={(acceptedFiles, fileRejections) => {
										if (fileRejections.length > 0) {
											showMessage(t('COMMON_LABEL_NOT_SUPPORTED_FILES'), messageTypes.error);
											return;
										}
										loadTheSelectedMediaItems(acceptedFiles);
										this.toggleFilePresent();
									}}
									inputContent={(files, extra) => (extra.reject ? 'SRT files only' : 'Drag Files')}
								>
									{({ getRootProps, getInputProps }) => (
										<div {...getRootProps({ className: 'dropzone' })}>
											<input {...getInputProps()} />
											<div className={`mediaUploadContentArea mediaContentBorderBottomOn`}>
												<button>{t('COMMON_LABEL_BROWSE')}</button>
												<label style={{ marginBottom: '5px' }}>
													{t('COMMON_LABEL_BROWSE_OR_DRAG')}
												</label>
												<label>
													{t(
														'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_LABEL_SUBTITLE_ACCEPTED_WARNING'
													)}
												</label>
											</div>
										</div>
									)}
								</Dropzone>
							)}
							{state.selectedMediaItems && state.selectedMediaItems.length >= 1 && (
								<div className="selectedSubtitleWrapper">
									{state.selectedMediaItems &&
										state.selectedMediaItems.map((eachFile, i) => (
											<div key={i} className="eachSubtitleWrapper">
												<button>
													<img
														className="playicon"
														src={this._getThePlayIcon(eachFile.type)}
													/>
												</button>
												<label className="fileNameUploaded" title={eachFile.name}>
													{eachFile.name}
												</label>
												<button
													className="headercloseBtn"
													onClick={() => {
														handleRemoveTheSelectedMediaItem(eachFile.name);
														this.toggleFilePresent();
													}}
												>
													<Close htmlColor="rgba(0, 0, 0, 0.54)" />
												</button>
											</div>
										))}
								</div>
							)}
						</div>

						<div className="dialogContentWrapper">
							<FormControlLabel
								control={
									<Checkbox
										checked={this.state.isImpairedChecked}
										onChange={this.handleChangeImpairedCheckbox}
										name="checkedB"
										color="primary"
									/>
								}
								label={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_LABEL_MARK_AS_CLOSE_CAPTION')}
							/>
						</div>
						<div id="encodingProfilesDropdownWrapper" className="dialogContentWrapper">
							<label>{t('COMMON_LANGUAGE')}</label>
							<div className="sortingDropDownWrapper fullWidthControl">
								<select onChange={handleSubtitleLanguageChange} className="sortingDropDown">
									{/* <option value={''}>{"- Select -"}</option> */}
									<option value="" selected disabled hidden>
										{`- ${t('COMMON_SELECT')} -`}
									</option>
									{state.allLanguages &&
										state.allLanguages.map((eachOption, i) => (
											<option
												key={i}
												value={eachOption.key}
												selected={state.allLanguages.length === 1}
											>
												{eachOption.value}
											</option>
										))}
								</select>
							</div>

							<DialogContentText>
								<br />
								<br />
								<p>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_PROCESSING_SUBTITLES')}</p>
							</DialogContentText>
						</div>
					</DialogContent>

					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock dialogBtnBottomContainer">
								<Button
									variant="contained"
									className="defaultActionBtn"
									disabled={this.state.filePresent && state.languageSelected ? false : true}
									onClick={beginTheMediaItemsUploadProcess}
								>
									{t('COMMON_UPLOAD')}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles)(UploadSubtitleDialog));
