import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { EditOutlined, Lock } from '@material-ui/icons';

import { mediaTypes } from '../../../../../constants/mediaConstants';
import { AdobeExpressUtils } from '../../../AdobeExpress/utils';
import { updateAdobeExpressEditorModal } from '../../../../../actions/sessionActions';
import { checkPermissionGrantedFor } from '../../../../../services/componentReusableService';
import { setPermissionInformationDialog } from '../../../../../actions/publishActions';
import { DashboardUtils } from '../../../../DashboardScreen/DashboardUtils';
import { joinClassNames } from '../../../../../services/elementHelperService';
import useAEEditor from '../../../../../hooks/useAEEditor';

interface CreateThumbnailWithAEProps {
	mediaType: string;
	mediaDetails: any;
	contextMenuData?: any;
	onContextMenuClose?: () => void;
	uploadThumbnail: (_fileData: any) => void;
}

const CreateThumbnailWithAE = ({
	mediaType,
	contextMenuData,
	onContextMenuClose,
	uploadThumbnail,
	mediaDetails,
}: CreateThumbnailWithAEProps) => {
	const { t: translator } = useTranslation();
	const dispatch = useDispatch();

	const { disableCaptureFromVideo } = useSelector((state) => (state as any).inspectScreenReducer);
	const { ccEverywhere } = useSelector((state) => (state as any).publish);
	const { userPermissions } = useSelector((state) => (state as any).session);

	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: () => {
			dispatch(updateAdobeExpressEditorModal(false));
		},
		onPublish: (_intent: any, publishParams: any, _mediaName?: string, _replaceMediaContent?: object) => {
			onPublish(publishParams);
		},
	});

	const uploadImageByUrl = async ({ mediaUrl, projectId, mediaType }: any) => {
		const blodFile = await fetch(mediaUrl)
			.then((r) => r.blob())
			.then(
				(blobFile) =>
					new File(
						[blobFile],
						`${AdobeExpressUtils.getMediaIdFromProjectId(projectId)}.${mediaType.split('/')[1]}`,
						{
							type: mediaType,
						}
					)
			);
		uploadThumbnail(blodFile);
	};

	const onPublish = (publishParams: any) => {
		const asset = publishParams?.asset[0];
		if (asset) {
			const mediaUrl = asset.data;
			const projectId = publishParams.projectId;
			uploadImageByUrl({
				mediaUrl,
				projectId,
				mediaType: asset.fileType,
			}).then(() => {
				dispatch(updateAdobeExpressEditorModal(false));
			});
		}
	};

	const hasAsPermission = useMemo(
		() => userPermissions && checkPermissionGrantedFor(userPermissions, 'adobeExpress'),
		[userPermissions]
	);

	const createAdobeExpressDesign = useCallback(
		(imageData?: any, mediaName?: string) => {
			if (!hasAsPermission) {
				dispatch(setPermissionInformationDialog(DashboardUtils.RecentMediaTypes.edits));
				return;
			}

			const canvasSize: AdobeExpressUtils.Size | undefined = imageData
				? {
						width: imageData?.width,
						height: imageData?.height,
						unit: 'px',
				  }
				: undefined;
			const dataUrl = imageData?.links[0].href ?? '';
			const srcParams = dataUrl ? dataUrl?.split('/') : [];
			const projectId =
				srcParams.length > 0
					? AdobeExpressUtils.getAdobeExpressProjectIdFromMediaId(srcParams[srcParams.length - 1])
					: null;

			if (projectId) {
				AdobeExpressUtils.editDesign({
					ccEverywhere,
					projectId,
					actionCallback: uploadToPlatformCallback,
					mediaName: mediaName ?? projectId,
					mediaType: 'image',
				});
			} else {
				AdobeExpressUtils.createNewProject({
					ccEverywhere,
					actionCallback: uploadToPlatformCallback,
					dataUrl,
					mediaName: mediaName ?? mediaDetails?.metadata?.title,
					mediaType: 'image',
					canvasSize,
				});
			}
			dispatch(updateAdobeExpressEditorModal(true));
		},
		[ccEverywhere, dispatch, hasAsPermission, mediaDetails, uploadToPlatformCallback]
	);

	return (
		<>
			<Button
				disabled={mediaType === mediaTypes.trashedMedias}
				className={joinClassNames(
					disableCaptureFromVideo ? 'hideCaptureButton' : 'defaultActionBtn',
					'thumbnailImageItem'
				)}
				style={{ marginLeft: '7px' }}
				onClick={() => createAdobeExpressDesign()}
			>
				{!hasAsPermission && (
					<div className={`media-library__item--locked`}>
						<Lock />
					</div>
				)}
				{translator('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_IMAGE_MANAGER_CREATE_IN_AE')}
			</Button>

			<Menu
				open={Boolean(contextMenuData?.menuAnchor)}
				getContentAnchorEl={null}
				onClose={onContextMenuClose}
				anchorReference={'anchorPosition'}
				anchorPosition={contextMenuData?.menuAnchor}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transitionDuration={{ appear: 200, enter: 200, exit: 0 }}
				className="thumbnailImageItem"
			>
				<MenuItem
					onClick={() => {
						createAdobeExpressDesign(contextMenuData?.imageData);
						onContextMenuClose?.();
					}}
				>
					{!hasAsPermission && (
						<div className={`media-library__item--locked`}>
							<Lock />
						</div>
					)}
					<ListItemIcon style={{ minWidth: '30px' }}>
						<EditOutlined />
					</ListItemIcon>
					<ListItemText primary={translator('COMMON_EDIT_WITH_AE_EDITOR')} />
				</MenuItem>
			</Menu>
		</>
	);
};

export default CreateThumbnailWithAE;
