import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { Button, Divider, FormControl, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';

import arrow_down from '../../assets/images/arrow-90deg-down.svg';
import support from '../../assets/images/support.svg';

import { loadAllMediaItemsCount, loadMediasByQuery, loadMediasForPagination } from '../../actions/publishActions';
import {
	updateScenesAction,
	updateScenarioAction,
	updateEditingSceneAndSendToPlayer,
	updateScenarioActiveLayoutAction,
	createScene,
	getScenario,
	updateScenario,
	addNewLayoutToScene,
	createWidget,
	setSceneLoading,
	deleteScene,
	updateScene,
	deleteWidget,
	addScenesMetadata,
	setOriginScenarioAction,
	setSaveScenario,
} from '../../actions/scenarioActions';
import { loadPlayerConfiguration } from '../../actions/playerConfigurationActions';
import {
	DEFAULT_LAYOUT_TEMPLATE,
	DEFAULT_SCENE_DATA,
	DEFAULT_WIDGETS_DATA,
	TIMELINE_VISIBILITY_PRESET_INHERIT,
	TRANSITION_FADE_IN,
	TRANSITION_FADE_OUT,
	WIDGET_TYPES,
	sceneActions,
	sceneTimelineVisibility,
	sceneTransitionInEffects,
	sceneTransitionOutEffects,
} from '../../constants/scenarioConstant';
import { generateUIId } from '../../services/elementHelperService';
import { showMessage } from '../../actions/globalActions';
import { mediaNames, messageTypes } from '../../constants/mediaConstants';
import { screenRoutes } from '../../constants/routesPath';
import { generateUUID } from '../../utils/commonUtil';
import { convertTimeToWidgetTime, getTimelineTimeFromSeconds } from '../../services/timeStampService';
import { getDetails } from '../../services/mediaDisplayService';

import ScenarioScreenContainer from './utils/ScenarioScreenContainer';
import ScenarioGraph from './utils/ScenarioGraph';
import SideBar from './utils/SideBar';
import TabBar from './utils/TabBar';
import EditorModal from './utils/EditorModal';
import { getNewPositionForScene, getVideoMediaDurationBySecond, moveViewportTo } from './utils/helper';

import './Scenario.scss';

const baseClassName = 'scenario-chart';
export const savingDebounceTime = 2000;

class ScenarioMainChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedTab: 'general',
			showWarninRecordingDialog: false,
			activeLayout: this.props.scenario?.defaults?.type,
			selectedPlayerId: '',
		};
		this.scenarioId =
			this.props.scenario?.id ??
			(this.props.match && this.props.match.params && this.props.match.params.scenarioId
				? this.props.match.params.scenarioId
				: this.props.scenarioId);
		this.scenarioContainerRef = createRef(null);
		this.flowInstanceRef = createRef(null);
		this.debounceTimer = undefined;
	}

	componentDidMount() {
		this.loadScenario();
		this.loadPlayerConfiguration();
		const { scenario, scenes, sceneWithMetadata } = this.props;
		if (!sceneWithMetadata || sceneWithMetadata.length === 0) {
			this.getSceneWithMetadata(scenario, scenes, this.state.activeLayout);
		}

		//Config Zen desk widget
		let temp = setInterval(() => {
			if (window.zE.show) {
				window.zE.show();
				clearInterval(temp);
			}
		}, 1000);
	}

	componentDidUpdate(prevProps, _prevState) {
		if (this.props.refreshMediasIds !== prevProps.refreshMediasIds) {
			const { scenario } = this.props;
			this.getSceneWithMetadata(scenario, scenario.scenes, scenario?.defaults?.type ?? 'desktop');
		}
	}

	componentWillUnmount() {
		window.zE.hide && window.zE.hide();
	}

	debouncedSaving = (func, timeout = 300) => {
		clearTimeout(this.debounceTimer);
		this.debounceTimer = setTimeout(func, timeout);
	};

	loadPlayerConfiguration = () => {
		const { loadPlayerConfiguration, defaultAccountId, showMessage, t } = this.props;

		loadPlayerConfiguration(defaultAccountId).then((data) => {
			if (!data) {
				showMessage(t('SETTINGS_PRESET_CONFIG_LOAD_DATA_FAIL'), messageTypes.error);
				return;
			}

			let selectedPlayerConfig = data.find((config) => config.recommended);
			selectedPlayerConfig = selectedPlayerConfig ?? data[0];
			this.setState({ selectedPlayerId: selectedPlayerConfig?.id });
		});
	};

	loadScenario = () => {
		if (this.props.history.location?.state?.from === 'editor') {
			this.getSceneWithMetadata(
				this.props.scenario,
				this.props.scenario?.scenes,
				this.props.scenario?.defaults?.type ?? 'desktop'
			);
			return;
		}

		setTimeout(() => {
			const {
				getScenario,
				defaultAccountId,
				updateScenarioActiveLayoutAction,
				setSceneLoading,
				setOriginScenarioAction,
				updateScenarioAction,
			} = this.props;
			if (!this.scenarioId) {
				setSceneLoading(false);
				return;
			}

			getScenario(defaultAccountId, this.scenarioId).then((updatedScenario) => {
				if (!updatedScenario) {
					setSceneLoading(false);
					showMessage("Can't get scenario", messageTypes.error);
					return;
				}

				this.setState({
					activeLayout: updatedScenario?.defaults?.type ?? 'desktop',
				});

				const activeLayout = updatedScenario?.defaults?.type ?? 'desktop';
				updateScenarioAction({
					...updatedScenario,
					defaults: {
						...(updatedScenario.defaults ?? {}),
						type: activeLayout,
					},
				});

				updateScenarioActiveLayoutAction(updatedScenario?.defaults?.type ?? 'desktop');
				setSceneLoading(false);
				setOriginScenarioAction(updatedScenario);

				this.getSceneWithMetadata(
					updatedScenario,
					updatedScenario.scenes,
					updatedScenario?.defaults?.type ?? 'desktop'
				);
			});
		}, 300);
	};

	getSceneWithMetadata = (updatedScenario, scenes, activeLayout) => {
		if (!scenes || scenes.length === 0) {
			setTimeout(() => this.props.setSceneLoading(false), 3000);
			return;
		}

		const sceneWithBG = new Set();
		const sceneIds = new Set();
		scenes.forEach((scene) => {
			const widgetTemplates = scene?.widgetTemplates ?? [];
			if (widgetTemplates.length === 0) {
				setTimeout(() => this.props.setSceneLoading(false), 3000);
				return;
			}

			let currentLayout =
				(scene?.layouts ?? []).find((l) => l.type === activeLayout ?? this.state.activeLayout) ?? {};
			currentLayout = currentLayout ?? scene?.layouts?.[0] ?? {};
			const backGroundBox =
				(((updatedScenario?.layoutTemplates ?? [])[0] ?? DEFAULT_LAYOUT_TEMPLATE).boxes ?? []).find(
					(b) => b.isBackground
				) ?? {};
			const backgroundWidgets =
				(currentLayout?.boxes ?? []).find((box) => box.boxTemplateId === backGroundBox?.id)?.widgets ?? [];

			widgetTemplates.forEach((template) => {
				if (backgroundWidgets.find((bg) => bg.widgetTemplateId === template.id)) {
					if (template.type === 'video') {
						sceneIds.add(template?.settings?.mediaId);
						sceneWithBG.add({
							sceneId: scene.id,
							mediaId: template?.settings?.mediaId,
						});
					}
				}
			});
		});
		let idString = [];
		for (const id of sceneIds) {
			idString.push('"' + id + '"');
		}

		const sceneWithBGFinal = new Set();
		this.props
			.loadMediasByQuery(
				this.props.defaultAccountId,
				mediaNames.medias,
				'?q=id:(' + encodeURIComponent(idString.join(',')) + ')'
			)
			.then((data) => {
				if (!data || data.length === 0) {
					this.props.setSceneLoading(false);
					return;
				}

				for (const item of sceneWithBG) {
					if (item.mediaId) {
						const media = data.find((d) => d.id === item.mediaId);
						const mediaDetails = getDetails(media);
						sceneWithBGFinal.add({
							sceneId: item.sceneId,
							mediaId: item.mediaId,
							metadata: mediaDetails,
						});
					} else {
						sceneWithBGFinal.add(item);
					}
				}
				this.props.addScenesMetadata(Array.from(sceneWithBGFinal));
				this.props.setSceneLoading(false);
			});
	};

	onTabItemClick = (event, item) => {
		this.setState((prevState) => {
			if (prevState.selectedTab === item.id) {
				return null;
			}
			return { selectedTab: item.id };
		});
	};

	handleSelectedSceneNameChange = (event) => {
		const { scenes, updateScenesAction, updateScenarioAction, scenario, updateScene, defaultAccountId } =
			this.props;

		const selectedScene = scenes.find((scene) => scene.selected);
		const newScene = { ...selectedScene, name: event.target.value };
		const newScenes = scenes.map((scene) => (scene.selected ? newScene : scene));

		//ui update
		updateScenesAction(newScenes);
		updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

		//send to api
		this.debouncedSaving(() => {
			const { id: scenarioId } = scenario;
			const { id: sceneId } = newScene;

			this.scenarioContainerRef.current?.isAutoSaving(true);
			updateScene(defaultAccountId, scenarioId, sceneId, { name: newScene.name }).then((resolve) => {
				if (resolve) {
					this.scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		}, savingDebounceTime);
	};

	handleEndOfSceneActionChange = (event) => {
		const { value } = event.target;
		const { scenes, updateScenesAction, updateScenarioAction, scenario, updateScene, defaultAccountId } =
			this.props;

		const selectedScene = scenes.filter((scene) => scene.selected)[0];
		const destinationSceneId =
			value === 'goToHome'
				? this.props.scenario.defaults?.sceneId
				: value === 'loopCurrentScene'
				? selectedScene.id
				: undefined;

		if (
			!selectedScene.events ||
			selectedScene.events.length === 0 ||
			selectedScene.events.filter((e) => e.type === 'endOfScene').length === 0
		) {
			//Add event handler
			selectedScene.events = [
				...(selectedScene.events ?? []),
				{
					id: generateUIId(),
					type: 'endOfScene',
					actions: [
						{
							type: value,
							metadata: {
								sceneId: destinationSceneId,
							},
						},
					],
				},
			];
		} else {
			selectedScene.events.forEach((e) => {
				if (e.type === 'endOfScene') {
					e.actions = [
						{
							type: value,
							metadata: {
								sceneId: destinationSceneId,
							},
						},
					];
				}
				return e;
			});
		}

		const newScene = { ...selectedScene };
		const newScenes = scenes.map((scene) => (scene.selected ? newScene : scene));

		//ui update
		updateScenesAction(newScenes);
		updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

		//saving to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = newScene;

		this.scenarioContainerRef.current?.isAutoSaving(true);
		updateScene(defaultAccountId, scenarioId, sceneId, { events: newScene.events }).then((resolve) => {
			if (resolve) {
				this.scenarioContainerRef.current?.isAutoSaving(false);
			}
		});
	};

	handleEndOfSceneDestinationChange = (event) => {
		const { scenes, updateScenesAction, scenario, updateScenarioAction, updateScene, defaultAccountId } =
			this.props;
		const selectedScene = scenes.filter((scene) => scene.selected)[0];
		const endOfScene = selectedScene.events.find((e) => e.type === 'endOfScene');
		if (endOfScene) {
			endOfScene.actions = endOfScene.actions.map((action) =>
				action.type === 'goToScene'
					? {
							...action,
							metadata: {
								...(action.metadata ?? {}),
								sceneId: event.target.value,
							},
					  }
					: action
			);
			selectedScene.events = selectedScene.events.map((e) => (e.type === 'endOfScene' ? { ...endOfScene } : e));
		}

		const newScene = { ...selectedScene };
		const newScenes = scenes.map((scene) => (scene.selected ? { ...selectedScene } : scene));

		//ui update
		updateScenesAction(newScenes);
		updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

		//saving to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = newScene;

		this.scenarioContainerRef.current?.isAutoSaving(true);
		updateScene(defaultAccountId, scenarioId, sceneId, { events: newScene.events }).then((resolve) => {
			if (resolve) {
				this.scenarioContainerRef.current?.isAutoSaving(false);
			}
		});
	};

	handleSwipeActionChange = (event, type) => {
		const { value } = event.target;
		const { scenes, updateScenesAction, updateScenarioAction, scenario, updateScene, defaultAccountId } =
			this.props;

		const selectedScene = scenes.filter((scene) => scene.selected)[0];
		const destinationSceneId =
			value === 'goToHome'
				? this.props.scenario.defaults?.sceneId
				: value === 'loopCurrentScene'
				? selectedScene.id
				: undefined;

		if (
			!selectedScene.events ||
			selectedScene.events.length === 0 ||
			selectedScene.events.filter((e) => e.type === type).length === 0
		) {
			//Add event handler
			selectedScene.events = [
				...(selectedScene.events ?? []),
				{
					id: generateUIId(),
					type: type,
					actions: [
						{
							type: value,
							metadata: {
								sceneId: destinationSceneId,
							},
						},
					],
				},
			];
		} else {
			selectedScene.events.forEach((e) => {
				if (e.type === type) {
					e.actions = [
						{
							type: value,
							metadata: {
								sceneId: destinationSceneId,
							},
						},
					];
				}
				return e;
			});
		}

		const newScene = { ...selectedScene };
		const newScenes = scenes.map((scene) => (scene.selected ? newScene : scene));

		//ui update
		updateScenesAction(newScenes);
		updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

		//saving to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = newScene;

		this.scenarioContainerRef.current?.isAutoSaving(true);
		updateScene(defaultAccountId, scenarioId, sceneId, { events: newScene.events }).then((resolve) => {
			if (resolve) {
				this.scenarioContainerRef.current?.isAutoSaving(false);
			}
		});
	};

	handleSwipeDestinationChange = (event, type) => {
		const { scenes, updateScenesAction, scenario, updateScenarioAction, updateScene, defaultAccountId } =
			this.props;
		const selectedScene = scenes.filter((scene) => scene.selected)[0];
		const endOfScene = selectedScene.events.find((e) => e.type === type);
		if (endOfScene) {
			endOfScene.actions = endOfScene.actions.map((action) =>
				action.type === 'goToScene'
					? {
							...action,
							metadata: {
								...(action.metadata ?? {}),
								sceneId: event.target.value,
							},
					  }
					: action
			);
			selectedScene.events = selectedScene.events.map((e) => (e.type === type ? { ...endOfScene } : e));
		}

		const newScene = { ...selectedScene };
		const newScenes = scenes.map((scene) => (scene.selected ? { ...selectedScene } : scene));

		//ui update
		updateScenesAction(newScenes);
		updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

		//saving to api
		const { id: scenarioId } = scenario;
		const { id: sceneId } = newScene;

		this.scenarioContainerRef.current?.isAutoSaving(true);
		updateScene(defaultAccountId, scenarioId, sceneId, { events: newScene.events }).then((resolve) => {
			if (resolve) {
				this.scenarioContainerRef.current?.isAutoSaving(false);
			}
		});
	};

	handleTransitionChange = (type, value) => {
		const { scenes, scenario, updateScenesAction, updateScenarioAction, updateScene, defaultAccountId } =
			this.props;
		const selectedScene = scenes.filter((scene) => scene.selected)[0];

		if (!selectedScene) {
			return;
		}

		const {
			metadata: { transition },
		} = selectedScene;

		const newTransitionValue = transition ? { ...transition, [type]: value } : { [type]: value };

		if (!transition || transition[type] !== newTransitionValue[type]) {
			const newScene = {
				...selectedScene,
				metadata: { ...selectedScene.metadata, transition: newTransitionValue },
			};
			const newScenes = scenes.map((scene) => (scene.selected ? { ...newScene } : scene));

			//ui update
			updateScenesAction(newScenes);
			updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

			const { id: scenarioId } = scenario;
			const { id: sceneId } = newScene;

			//saving to api
			this.scenarioContainerRef.current?.isAutoSaving(true);
			updateScene(defaultAccountId, scenarioId, sceneId, { metadata: newScene.metadata }).then((resolve) => {
				if (resolve) {
					this.scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		}
	};

	handleTimelineConfigChange = (type, value) => {
		const { scenes, scenario, updateScenesAction, updateScenarioAction, updateScene, defaultAccountId } =
			this.props;
		const selectedScene = scenes.filter((scene) => scene.selected)[0];

		if (!selectedScene) {
			return;
		}

		const {
			metadata: { timeline },
		} = selectedScene;

		const newTimelineConfig = timeline ? { ...timeline, [type]: value } : { [type]: value };

		if (!timeline || timeline[type] !== newTimelineConfig[type]) {
			const newScene = {
				...selectedScene,
				metadata: { ...selectedScene.metadata, timeline: newTimelineConfig },
			};
			const newScenes = scenes.map((scene) => (scene.selected ? { ...newScene } : scene));

			//ui update
			updateScenesAction(newScenes);
			updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes });

			const { id: scenarioId } = scenario;
			const { id: sceneId } = newScene;

			//saving to api
			this.scenarioContainerRef.current?.isAutoSaving(true);
			updateScene(defaultAccountId, scenarioId, sceneId, { metadata: newScene.metadata }).then((resolve) => {
				if (resolve) {
					this.scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		}
	};

	onNodeClick = () => {
		const { scenes, updateEditingSceneAndSendToPlayer } = this.props;
		const selectedScene = scenes.filter((scene) => scene.selected)[0];
		updateEditingSceneAndSendToPlayer(selectedScene);
		this.setState((prevState) => {
			if (prevState.selectedTab === 'edit') {
				return null;
			}
			return { selectedTab: 'edit' };
		});
	};

	onNodeDoubleClick = (scene) => {
		const { scenes, scenario, setSceneLoading, updateEditingSceneAndSendToPlayer } = this.props;
		const selectedScene = scenes.find((s) => s.id === scene.id);
		updateEditingSceneAndSendToPlayer(selectedScene);

		setSceneLoading(true);
		this.props.history.push(
			`${screenRoutes.PUBLISH_SCENARIOS}/${scenario.id}/detail/scenes/${selectedScene.id}/edit`,
			'_self'
		);
	};

	setActiveLayout = (type) => {
		this.setState({ activeLayout: type });
	};

	handleScenarioNameChange = (event) => {
		const { scenario, updateScenarioAction, updateScenario, defaultAccountId } = this.props;

		const { id: scenarioId, metadata } = scenario;
		const newMetadata = { ...metadata, title: event.target.value };

		// ui update
		updateScenarioAction({
			...scenario,
			metadata: newMetadata,
		});

		//saving to api
		this.debouncedSaving(() => {
			this.scenarioContainerRef.current?.isAutoSaving(true);
			updateScenario(defaultAccountId, scenarioId, {
				metadata: newMetadata,
			}).then((resolve) => {
				if (resolve) {
					this.scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		}, savingDebounceTime);
	};

	onExitMainChartClick = () => {
		if (this.props.recorderModalRef?.current?.isRecordingFromInteract()) {
			this.setState({ showWarninRecordingDialog: true });
			return;
		}
		this.goToInspectPage();
	};

	goToInspectPage = () => {
		this.props.history.push(`${screenRoutes.PUBLISH_SCENARIOS}/${this.scenarioId}/detail`);
	};

	handleAddSceneIntoScenario = (medias, position, nodes) => {
		if (!medias || medias.length <= 0) {
			return;
		}

		//Only select 1 media for 1 scene;
		const media = medias[0];

		setSceneLoading(true);
		if (media.type === 'uploadNew' && !media.duration) {
			getVideoMediaDurationBySecond(media, media.file).then((media) => {
				this.processSceneIntoScenario(media, position, nodes);
			});
		} else {
			this.processSceneIntoScenario(media, position, nodes);
		}
	};

	processSceneIntoScenario = (media, position, nodes) => {
		const {
			updateScenarioAction,
			updateScenesAction,
			scenario,
			defaultAccountId,
			createScene,
			createWidget,
			updateScenario,
		} = this.props;

		const sceneId = generateUUID();
		const layoutId = generateUUID();

		const { scenes, id: scenarioId } = scenario;

		const duration =
			media.type === 'uploadNew'
				? media.duration
				: media.asset?.resources?.find((r) => r.type === 'video')?.renditions?.[0]?.videos?.[0]?.duration ?? 0;

		const widgetId = generateUUID();
		const widgetTemplateId = generateUUID();

		const sceneName = (media.type === 'uploadNew' ? media.title : media?.metadata?.title) ?? 'New scene';
		const sceneMetaData = {
			position: getNewPositionForScene(sceneId, position, nodes),
			transition: {
				in: TRANSITION_FADE_IN.value,
				out: TRANSITION_FADE_OUT.value,
			},
		};

		const videoWidgetName = media?.metadata?.title
			? `${media?.metadata?.title} widget`
			: `${WIDGET_TYPES.WIDGET_TYPE_VIDEO} widget`;
		const videoWidgetType = WIDGET_TYPES.WIDGET_TYPE_VIDEO;
		const videoWidgetSetting = {
			mediaId: media.id,
		};
		const videoWidgetStartTime = '00:00:00';
		const videoWidgetEndTime =
			duration === 0 ? '00:00:00' : convertTimeToWidgetTime(getTimelineTimeFromSeconds(duration));

		const newScene = {
			id: sceneId,
			name: sceneName,
			metadata: sceneMetaData,
			events: [],
			widgetTemplates: [
				{
					id: widgetTemplateId,
					name: videoWidgetName,
					type: videoWidgetType,
					settings: videoWidgetSetting,
					...DEFAULT_WIDGETS_DATA,
				},
			],
			layoutTemplates: [DEFAULT_LAYOUT_TEMPLATE],
			layouts: [
				{
					id: layoutId,
					type: 'desktop',
					layoutTemplateId: DEFAULT_LAYOUT_TEMPLATE.id,
					boxes: DEFAULT_LAYOUT_TEMPLATE.boxes.map(({ id, isBackground, name }) => ({
						boxTemplateId: id,
						isBackground,
						name,
						settings: { transition: 'fade-in' },
						widgets: isBackground
							? [
									{
										id: widgetId,
										sceneId: sceneId,
										layoutId: layoutId,
										widgetTemplateId,
										boxTemplateId: id,
										start: videoWidgetStartTime,
										end: videoWidgetEndTime,
									},
							  ]
							: [],
					})),
				},
			],
			...DEFAULT_SCENE_DATA,
		};

		let defaultScene = scenario?.defaults?.sceneId;

		if (scenes.length === 0) {
			defaultScene = sceneId;
		}

		const newScenes = [...scenes, newScene];
		const updatedScenario = {
			...scenario,
			scenes: newScenes,
			defaults: { ...scenario.defaults, sceneId: defaultScene },
		};

		//load scene metadata to display
		this.getSceneWithMetadata(updatedScenario, newScenes, this.state.activeLayout);
		// Update ui
		updateScenarioAction(updatedScenario);
		updateScenesAction(newScenes);
		moveViewportTo(newScene.metadata.position, this.flowInstanceRef);
		//save scene to api
		const sceneBody = {
			name: newScene.name,
			events: newScene.events,
			metadata: newScene.metadata,
			layout: {
				id: layoutId,
				type: 'desktop',
				layoutTemplate: DEFAULT_LAYOUT_TEMPLATE,
			},
		};

		this.scenarioContainerRef.current?.isAutoSaving(true);
		createScene(defaultAccountId, scenarioId, sceneId, sceneBody).then((resolve) => {
			if (resolve) {
				const widgetBody = {
					name: videoWidgetName,
					widgetTemplateId,
					start: videoWidgetStartTime,
					end: videoWidgetEndTime,
					type: videoWidgetType,
					settings: videoWidgetSetting,
					style: {
						...DEFAULT_WIDGETS_DATA.style,
					},
					events: [],
				};
				createWidget(defaultAccountId, scenarioId, sceneId, layoutId, '1', widgetId, widgetBody).then(
					(resolve) => {
						if (resolve) {
							//only update scenario with home scene incase create 1st scene
							if (scenes.length === 0) {
								updateScenario(defaultAccountId, scenarioId, {
									defaults: updatedScenario.defaults,
								}).then((resolve) => {
									if (resolve) {
										this.scenarioContainerRef.current?.isAutoSaving(false);
									}
								});
								return;
							}
							this.scenarioContainerRef.current?.isAutoSaving(false);
						}
					}
				);
			}
		});
	};

	renderTabBar = () => {
		const { t, scenes } = this.props;
		const selectedSceneName = scenes.find((scene) => scene.selected)?.name ?? '';
		const displayName =
			selectedSceneName.length > 10 ? `${selectedSceneName.substring(0, 10)}...` : selectedSceneName;

		return (
			<TabBar
				onItemClick={this.onTabItemClick}
				style={{ borderBottom: '1px solid #E8E8E8' }}
				items={[
					{
						label: t('COMMON_GENERAL'),
						id: 'general',
						selected: this.state.selectedTab === 'general',
					},
					{
						label: `${t('COMMON_EDIT')} ${displayName ? `(${displayName})` : ''}`,
						id: 'edit',
						selected: this.state.selectedTab === 'edit',
					},
				]}
			/>
		);
	};

	renderGeneralTabContent = () => {
		const { t, scenario } = this.props;
		return (
			<>
				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('COMMON_DETAILS')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					control={<TextField variant="standard" value={scenario?.metadata?.title ?? 'No title'} />}
					label={<span className={`${baseClassName}__label`}>{t('COMMON_NAME')}</span>}
					onChange={this.handleScenarioNameChange}
					labelPlacement="start"
				/>
				<div className={`${baseClassName}__control`}>
					<span className={`${baseClassName}__label`}>{t('COMMON_CREATED')}</span>
					<span className={`${baseClassName}__label`}>
						{new Date(scenario.created).toLocaleString().replace(/\//g, '.')}
					</span>
				</div>
				<div className={`${baseClassName}__control`}>
					<span className={`${baseClassName}__label`}>{t('SCENARIO_STORYBOARD_LAST_SAVED')}</span>
					<span className={`${baseClassName}__label`}>
						{new Date(scenario.updated).toLocaleString().replace(/\//g, '.')}
					</span>
				</div>
			</>
		);
	};

	renderEditTabContent = () => {
		const { scenes, t } = this.props;
		const selectedScenes = scenes.filter((scene) => scene.selected);
		const unselectedScenes = scenes.filter((scene) => !scene.selected);

		if (selectedScenes.length !== 1) {
			return (
				<span className={`${baseClassName}__message`}>{t('SCENARIO_STORYBOARD_SELECT_SINGLE_SCENE_MESS')}</span>
			);
		}

		const selectedScene = selectedScenes[0];
		const endOfScene = (selectedScene.events?.find((e) => e.type === 'endOfScene')?.actions ?? []).find(
			(a) => a.type === 'goToScene' || a.type === 'goToHome' || a.type === 'loopCurrentScene'
		);
		const selectedEndOfSceneAction = !endOfScene ? 0 : endOfScene.type;

		const swipeLeft = (selectedScene.events?.find((e) => e.type === 'swipeLeft')?.actions ?? []).find(
			(a) => a.type === 'goToScene' || a.type === 'goToHome' || a.type === 'loopCurrentScene'
		);
		const selectedSwipeLeftAction = !swipeLeft ? 0 : swipeLeft.type;

		const swipeRight = (selectedScene.events?.find((e) => e.type === 'swipeRight')?.actions ?? []).find(
			(a) => a.type === 'goToScene' || a.type === 'goToHome' || a.type === 'loopCurrentScene'
		);
		const selectedSwipeRightAction = !swipeRight ? 0 : swipeRight.type;

		const transitionEffect = selectedScene.metadata.transition ?? {};
		const { in: transitionIn, out: transitionOut } = transitionEffect;

		const hiddenPlayerTimeline = selectedScene.metadata.timeline ?? {};
		const { visibility: timelineVisibility } = hiddenPlayerTimeline;

		return (
			<>
				<div className={`${baseClassName}__control`}>
					<Button
						variant="contained"
						className={`defaultActionBtn squireBtn`}
						onClick={() => this.onNodeDoubleClick(selectedScene)}
					>
						<img src={support} />
						{t('SCENARIO_STORYBOARD_EDIT_SCENE')}
					</Button>
				</div>

				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('COMMON_DETAILS')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					control={<TextField variant="standard" value={selectedScenes[0].name} />}
					label={<span className={`${baseClassName}__label`}>{t('COMMON_NAME')}</span>}
					onChange={this.handleSelectedSceneNameChange}
					labelPlacement="start"
				/>

				<Divider className="divider-extra-space" />

				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('SCENARIO_STORYBOARD_END_OF_SCENE')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={<span className={`${baseClassName}__label`}>{t('SCENARIO_STORYBOARD_SCENE_ACTION')}</span>}
					onChange={this.handleEndOfSceneActionChange}
					control={
						<Select variant="standard" value={selectedEndOfSceneAction}>
							<MenuItem key={0} value={0}>
								-{t('COMMON_CHOOSE')}-
							</MenuItem>
							{sceneActions.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				{selectedEndOfSceneAction === 'goToScene' && (
					<FormControlLabel
						className={`${baseClassName}__control`}
						labelPlacement="start"
						label={
							<span className={`${baseClassName}__label`}>
								<img src={arrow_down} />
								{t('SCENARIO_STORYBOARD_DESTINATION_LABEL')}
							</span>
						}
						control={
							<FormControl>
								<Select
									variant="standard"
									value={endOfScene?.metadata?.sceneId ?? 0}
									onChange={this.handleEndOfSceneDestinationChange}
								>
									<MenuItem key={0} value={0}>
										-{t('COMMON_CHOOSE')}-
									</MenuItem>
									{unselectedScenes.map((scene) => (
										<MenuItem key={scene.id} value={scene.id}>
											{scene.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
					/>
				)}
				<Divider className="divider-extra-space" />

				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('SCENARIO_STORYBOARD_SWIPE')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={<span className={`${baseClassName}__label`}>{t('SCENARIO_STORYBOARD_SWIPE_LEFT')}</span>}
					onChange={(e) => this.handleSwipeActionChange(e, 'swipeLeft')}
					control={
						<Select variant="standard" value={selectedSwipeLeftAction}>
							<MenuItem key={0} value={0}>
								-{t('COMMON_CHOOSE')}-
							</MenuItem>
							{sceneActions.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				{selectedSwipeLeftAction === 'goToScene' && (
					<FormControlLabel
						className={`${baseClassName}__control`}
						labelPlacement="start"
						label={
							<span className={`${baseClassName}__label`}>
								<img src={arrow_down} />
								{t('SCENARIO_STORYBOARD_DESTINATION_LABEL')}
							</span>
						}
						control={
							<FormControl>
								<Select
									variant="standard"
									value={swipeLeft?.metadata?.sceneId ?? 0}
									onChange={(e) => this.handleSwipeDestinationChange(e, 'swipeLeft')}
								>
									<MenuItem key={0} value={0}>
										-{t('COMMON_CHOOSE')}-
									</MenuItem>
									{unselectedScenes.map((scene) => (
										<MenuItem key={scene.id} value={scene.id}>
											{scene.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
					/>
				)}
				<Divider className="divider-extra-space divider--short" />

				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={<span className={`${baseClassName}__label`}>{t('SCENARIO_STORYBOARD_SWIPE_RIGHT')}</span>}
					onChange={(e) => this.handleSwipeActionChange(e, 'swipeRight')}
					control={
						<Select variant="standard" value={selectedSwipeRightAction}>
							<MenuItem key={0} value={0}>
								-{t('COMMON_CHOOSE')}-
							</MenuItem>
							{sceneActions.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				{selectedSwipeRightAction === 'goToScene' && (
					<FormControlLabel
						className={`${baseClassName}__control`}
						labelPlacement="start"
						label={
							<span className={`${baseClassName}__label`}>
								<img src={arrow_down} />
								{t('SCENARIO_STORYBOARD_DESTINATION_LABEL')}
							</span>
						}
						control={
							<FormControl>
								<Select
									variant="standard"
									value={swipeRight?.metadata?.sceneId ?? 0}
									onChange={(e) => this.handleSwipeDestinationChange(e, 'swipeRight')}
								>
									<MenuItem key={0} value={0}>
										-{t('COMMON_CHOOSE')}-
									</MenuItem>
									{unselectedScenes.map((scene) => (
										<MenuItem key={scene.id} value={scene.id}>
											{scene.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						}
					/>
				)}
				<Divider className="divider-extra-space" />

				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('SCENARIO_STORYBOARD_SCENE_SETTING_TRANSITION_EFFECT')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={
						<span className={`${baseClassName}__label`}>
							{t('SCENARIO_STORYBOARD_SETTING_TRANSITION_EFFECT_TRANSITION_IN')}
						</span>
					}
					onChange={(event) => {
						this.handleTransitionChange('in', event.target.value);
					}}
					control={
						<Select variant="standard" value={transitionIn ?? TRANSITION_FADE_IN.value}>
							{sceneTransitionInEffects.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={
						<span className={`${baseClassName}__label`}>
							{t('SCENARIO_STORYBOARD_SCENE_SETTING_TRANSITION_EFFECT_TRANSITION_OUT')}
						</span>
					}
					onChange={(event) => {
						this.handleTransitionChange('out', event.target.value);
					}}
					control={
						<Select variant="standard" value={transitionOut ?? TRANSITION_FADE_OUT.value}>
							{sceneTransitionOutEffects.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
				<Divider className="divider-extra-space" />

				<div className={`${baseClassName}__control ${baseClassName}__label ${baseClassName}__label--bold`}>
					{t('SCENARIO_STORYBOARD_SCENE_SETTING_PLAYER_TIMELINE')}
				</div>
				<FormControlLabel
					className={`${baseClassName}__control`}
					labelPlacement="start"
					label={
						<span className={`${baseClassName}__label`}>
							{t('SCENARIO_STORYBOARD_SCENE_SETTING_PLAYER_TIMELINE_VISIBILITY')}
						</span>
					}
					onChange={(event) => {
						this.handleTimelineConfigChange('visibility', event.target.value);
					}}
					control={
						<Select
							variant="standard"
							value={timelineVisibility ?? TIMELINE_VISIBILITY_PRESET_INHERIT.value}
						>
							{sceneTimelineVisibility.map((action) => (
								<MenuItem key={action.value} value={action.value}>
									{t(action.label)}
								</MenuItem>
							))}
						</Select>
					}
				/>
			</>
		);
	};

	renderSideBar = () => {
		const { selectedTab } = this.state;
		return (
			<SideBar header={this.renderTabBar()} style={{ width: '20%' }}>
				<div className={`${baseClassName}__sideBar-container`}>
					{selectedTab === 'general' && this.renderGeneralTabContent()}
					{selectedTab === 'edit' && this.renderEditTabContent()}
				</div>
			</SideBar>
		);
	};

	render() {
		const { t, scenario, recorderModalRef } = this.props;
		const { showWarninRecordingDialog } = this.state;

		if (!scenario) {
			return null;
		}

		return (
			<>
				<ScenarioScreenContainer
					hideDevicesSwitch
					onActiveLayoutChange={(layout) => this.setState({ activeLayout: layout })}
					onExitButtonClick={this.onExitMainChartClick}
					t={t}
					ref={this.scenarioContainerRef}
				>
					{this.renderSideBar()}
					<ScenarioGraph
						t={t}
						onNodeClick={this.onNodeClick}
						onNodeDoubleClick={this.onNodeDoubleClick}
						handleAddSceneIntoScenario={this.handleAddSceneIntoScenario}
						flowInstanceRef={this.flowInstanceRef}
						scenarioContainerRef={this.scenarioContainerRef}
						recorderModalRef={recorderModalRef}
					/>
				</ScenarioScreenContainer>

				<EditorModal
					open={showWarninRecordingDialog}
					showHeaderIcon
					title={t('SCENARIO_STORYBOARD_MODAL_EXIT_MESSAGE')}
					textContent={`<span>${t('SCENARIO_STORYBOARD_MODAL_EXIT_CONTENT')}. ${t(
						'SCENARIO_STORYBOARD_MODAL_EXIT_CONTENT_NEXT'
					)}.</span>`}
					cancelText={t('SCENARIO_STORYBOARD_MODAL_EXIT_CANCEL')}
					onCancel={() => this.setState({ showWarninRecordingDialog: false })}
					actionText={t('SCENARIO_STORYBOARD_MODAL_EXIT_CONFIRM')}
					onAccept={() => {
						this.setState({
							showWarninRecordingDialog: false,
						});
						this.goToInspectPage();
					}}
					onClose={() => this.setState({ showWarninRecordingDialog: false })}
					actionAlign="bothSide"
				/>
			</>
		);
	}
}

const mapStateToProps = ({ session, scenarioReducer, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	scenes: scenarioReducer.scenes,
	scenario: scenarioReducer.scenario,
	editingScene: scenarioReducer.editingScene,
	isDataChanged: scenarioReducer.isDataChanged,
	updatedScenes: scenarioReducer.updatedScenes,
	newWidgets: scenarioReducer.newWidgets,
	sceneWithMetadata: scenarioReducer.sceneWithMetadata,
	refreshMediasIds: publish.refreshMediasIds,
});

const mapDispatchToProps = (dispatch) => ({
	showMessage: (message, type) => dispatch(showMessage(message, type)),
	loadMediasForPagination: (accountId, mediaPerPage, offset, mediaName, sortType, query) =>
		dispatch(loadMediasForPagination(accountId, mediaPerPage, offset, mediaName, sortType, query)),
	updateScenesAction: (scenes) => dispatch(updateScenesAction(scenes)),
	updateScenarioAction: (scenario) => dispatch(updateScenarioAction(scenario)),
	updateEditingSceneAndSendToPlayer: (editingScene) => dispatch(updateEditingSceneAndSendToPlayer(editingScene)),
	updateScenarioActiveLayoutAction: (activeLayout) => dispatch(updateScenarioActiveLayoutAction(activeLayout)),
	setSceneLoading: (loading) => dispatch(setSceneLoading(loading)),
	getScenario: (accountId, scenarioId) => dispatch(getScenario(accountId, scenarioId)),
	updateScenario: (accountId, scenarioId, body) => dispatch(updateScenario(accountId, scenarioId, body)),
	createScene: (accountId, scenarioId, sceneId, body) => dispatch(createScene(accountId, scenarioId, sceneId, body)),
	updateScene: (accountId, scenarioId, sceneId, body) => dispatch(updateScene(accountId, scenarioId, sceneId, body)),
	deleteScene: (accountId, scenarioId, sceneId) => dispatch(deleteScene(accountId, scenarioId, sceneId)),
	addNewLayoutToScene: (accountId, scenarioId, sceneId, layoutId, body) =>
		dispatch(addNewLayoutToScene(accountId, scenarioId, sceneId, layoutId, body)),
	createWidget: (accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) =>
		dispatch(createWidget(accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body)),
	deleteWidget: (accountId, scenarioId, sceneId, layoutId, boxId, widgetId) =>
		dispatch(deleteWidget(accountId, scenarioId, sceneId, layoutId, boxId, widgetId)),
	loadAllMediaItemsCount: (accountId, mediaName, query) =>
		dispatch(loadAllMediaItemsCount(accountId, mediaName, query)),
	loadMediasByQuery: (accountId, mediaName, query) => dispatch(loadMediasByQuery(accountId, mediaName, query)),
	addScenesMetadata: (sceneMetadata) => dispatch(addScenesMetadata(sceneMetadata)),
	setOriginScenarioAction: (scenario) => dispatch(setOriginScenarioAction(scenario)),
	loadPlayerConfiguration: (accountId) => dispatch(loadPlayerConfiguration(accountId)),
	setSaveScenario: (shouldTriggerSave) => {
		dispatch(setSaveScenario(shouldTriggerSave));
	},
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(ScenarioMainChart);
