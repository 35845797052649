import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'underscore';
import { useHistory } from 'react-router-dom';
import ReactFlow, {
	addEdge,
	MiniMap,
	Controls,
	Background,
	applyNodeChanges,
	applyEdgeChanges,
	getNodesBounds,
} from 'reactflow';
import { ListItemIcon, ListItemText, Menu, MenuItem, Button } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import { IconsList } from '../../../reusable/IconsStore/IconsList';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import {
	updateScenarioAction,
	updateScenesAction,
	addScenesMetadata,
	updateScenario,
	deleteScene,
	createWidget,
	createScene,
	updateScene,
	emptyScenesToUpdateWaitingList,
	addScenesToUpdateWaitingList,
	updateUploadingScenesAction,
} from '../../../actions/scenarioActions';
import { getElementParent, generateUIId } from '../../../services/elementHelperService';
import { generateUUID, isMediaProcessing } from '../../../utils/commonUtil';
import { FLOWS, mediaNames, mediaTypes } from '../../../constants/mediaConstants';
import InteractMediaLibraryModal, { TAB_ID } from '../../../reusable/MediaLibrary/InteractMediaLibraryModal';
import { DEFAULT_LAYOUT_TEMPLATE } from '../../../constants/scenarioConstant';
import { getRestrictionRules } from '../../../actions/publishActions';
import { AdobeExpressUtils } from '../../PublishScreen/AdobeExpress/utils';
import { screenRoutes } from '../../../constants/routesPath';

import { EdgeContext } from './EdgeContext';
import SceneNode from './SceneNode';
import SceneEdge from './SceneEdge';
import './ScenarioGraph.scss';
import {
	getCurrentEdgeDisplayBehaviourMap,
	checkOverlapScenes,
	getNewPositionForScene,
	moveViewportTo,
	getVideoDurationBySecondFromUrl,
	getHelperLines,
} from './helper';
import useAEEditor from '../../../hooks/useAEEditor';
import ColorHub from '../../../reusable/ColorHub/ColorHub';

import { ReactComponent as ContentCut } from '../../../assets/svg/ContentCut.svg';

import 'reactflow/dist/style.css';
import useUploadFile from '../../../hooks/useUploadFile';
import { setInspectingMediaData } from '../../../actions/inspectScreenActions';
import { updateAdobeExpressEditorModal } from '../../../actions/sessionActions';
import HelperLinesRenderer from './HelperLine';

const baseClassName = 'scenario-chart-graph';
const connectionLineStyle = { stroke: '#126CFC' };
const innitPosition = { x: 50, y: 50 };
const nodeTypes = { selectorNode: SceneNode };
const edgeTypes = { customEdge: SceneEdge };
const defaultSceneSpace = 400;
export const defaultSceneWidth = 250;
export const defaultSceneHeight = 180;

const UPLOAD = 'UPLOAD';
const EDIT = 'EDIT';
const MEDIA_LIBRARY = 'MEDIA_LIBRARY';
const RECORDING = 'RECORDING';
const ADD_NEW_SCENE_SOURCE_OPTIONS = {
	[UPLOAD]: {
		key: UPLOAD,
		name: 'COMMON_UPLOAD',
		icon: <CloudUploadOutlined />,
	},
	[RECORDING]: {
		key: RECORDING,
		name: 'COMMON_CREATE_SCREEN_RECORDER',
		icon: <ColorHub component={<VideocamIcon />} htmlColor="rgba(0, 0, 0, 0.54)" />,
	},
	[EDIT]: {
		key: EDIT,
		name: 'COMMON_EDIT',
		icon: <ColorHub component={<ContentCut width={'18px'} height={'18px'} />} htmlColor="rgba(0, 0, 0, 0.54)" />,
	},
	[MEDIA_LIBRARY]: {
		key: MEDIA_LIBRARY,
		name: 'HEADER_MENU_LABEL_MEDIA_LIBRARY',
		icon: <VideoLibraryIcon />,
	},
};

const GraphBackground = () => <Background variant="lines" gap={24} style={{ backgroundColor: '#FAFCFF' }} />;
const GraphMiniMap = () => (
	<MiniMap
		nodeStrokeColor={(n) => {
			if (n.type === 'input') {
				return '#0041d0';
			}
			if (n.type === 'selectorNode') {
				return '#0041d0';
			}
			if (n.type === 'output') {
				return '#ff0072';
			}
		}}
		nodeColor={(n) => {
			if (n.type === 'selectorNode') {
				return '#0041d0';
			}
			return '#fff';
		}}
	/>
);

const ScenarioGraph = (props) => {
	const dispatch = useDispatch();

	const defaultAccountId = useSelector((state) => state.session.defaultAccountId);
	const scenes = useSelector((state) => state.scenarioReducer.scenes);
	const scenario = useSelector((state) => state.scenarioReducer.scenario);
	const sceneWithMetadata = useSelector((state) => state.scenarioReducer.sceneWithMetadata);
	const uploadingScenes = useSelector((state) => state.scenarioReducer.uploadingScenes);
	const scenesNeedToUpdate = useSelector((state) => state.scenarioReducer.updateSceneWaitingDictionary);
	const accountRestrictions = useSelector((state) => state.publish.accountRestrictions);
	const { ccEverywhere, qbrickStandardFlows } = useSelector((state) => state.publish);

	const [edges, setEdges] = useState([]);
	const [edgesMap, setEdgetMap] = useState({});
	const [showMiniNodes, setShowMiniNodes] = useState(false);
	const [resourceMenuEl, setResourceMenuEl] = useState(null);

	const [helperLineHorizontal, setHelperLineHorizontal] = useState();
	const [helperLineVertical, setHelperLineVertical] = useState();

	const isPegacoderEnable = useMemo(() => {
		return qbrickStandardFlows.find((f) => f.id === FLOWS.qbrickStandard);
	}, [qbrickStandardFlows]);

	const onUploadBegin = useCallback(
		(mediaId) => {
			dispatch(updateUploadingScenesAction([...uploadingScenes, mediaId]));
		},
		[dispatch, uploadingScenes]
	);

	const { inputFileRef, openFileSelector, startUploadFile } = useUploadFile({
		defaultAccountId,
		onUploadBegin,
	});

	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: ({ data, mediaId, type, mediaTitle }) => {
			dispatch(updateAdobeExpressEditorModal(false));
			if (!data || !mediaId || !mediaTitle || type !== 'video') {
				return;
			}
			const { x, y, width, height } = getNodesBounds(nodesRef.current);

			const { asset } = data;
			getVideoDurationBySecondFromUrl(asset[0].data).then((duration) => {
				const media = { id: mediaId, duration, metadata: { title: mediaTitle }, type: 'uploadNew' };
				props.handleAddSceneIntoScenario([media], { x: x + width, y: y + height }, nodesRef.current);
			});
		},
	});

	const {
		location: { state: { lastEditedSceneId } = {} },
	} = useHistory();

	const { flowInstanceRef, scenarioContainerRef } = props;

	const interactMediaLibraryModalRef = useRef();
	const debounceTimer = useRef();
	const nodesRef = useRef([]);

	const nodes = useMemo(() => {
		return !scenes || scenes.length === 0
			? []
			: scenes
					.map((scene, index) => {
						if (Object.keys(scene.metadata).length === 0 || scene.widgetTemplates.length === 0) {
							return;
						}
						const sceneData = sceneWithMetadata?.find((s) => s.sceneId === scene.id);

						return {
							...scene,
							id: scene.id,
							type: 'selectorNode',
							position:
								scene.metadata?.position && Object.keys(scene.metadata?.position).length === 2
									? scene.metadata?.position
									: index === 0
									? innitPosition
									: { x: defaultSceneSpace * index, y: 50 },
							data: {
								label: scene.name,
								home: scene.id === scenario.defaults?.sceneId,
								isMiniNode: showMiniNodes,
								mediaDetails: sceneData,
								isDragable: !props.summaryScenes,
								isProcessing:
									uploadingScenes.includes(sceneData?.mediaId) ||
									(!!sceneData?.metadata?.restrictions &&
										isMediaProcessing(accountRestrictions, sceneData?.metadata?.restrictions)),
							},
						};
					})
					.filter((item) => item !== undefined);
	}, [
		scenes,
		sceneWithMetadata,
		scenario.defaults?.sceneId,
		showMiniNodes,
		props.summaryScenes,
		uploadingScenes,
		accountRestrictions,
	]);

	nodesRef.current = nodes;

	useEffect(() => {
		const edges = [];
		scenes.forEach((scene) => {
			if (scene.events && scene.events.filter((e) => e.type === 'endOfScene')) {
				const goToScene = scene.events
					.filter((e) => e.type === 'endOfScene')[0]
					?.actions?.find((action) => action.type === 'goToScene');
				if (goToScene) {
					edges.push({
						id: generateUIId(),
						source: scene.id,
						target: goToScene?.metadata?.sceneId,
						style: connectionLineStyle,
						data: {
							eventId: goToScene.id,
							source: scene.id,
							target: goToScene?.metadata?.sceneId,
							name: `End scene action: Go to ${scene.name}`,
						},
					});
				}
				const goToHome = scene.events
					.filter((e) => e.type === 'endOfScene')[0]
					?.actions?.find((action) => action.type === 'goToHome');
				if (goToHome) {
					edges.push({
						id: generateUIId(),
						source: scene.id,
						target: scenario.defaults?.sceneId,
						style: connectionLineStyle,
						data: {
							eventId: goToHome.id,
							source: scene.id,
							target: scenario.defaults?.sceneId,
							name: `End scene action: Go to ${scene.name}`,
						},
					});
				}
			}

			const { widgetTemplates } = scene;
			if (widgetTemplates && widgetTemplates.length > 0) {
				widgetTemplates.forEach(({ events, type, name }) => {
					(type === 'image' || type === 'button' || type === 'text' || type === 'icon') &&
						events &&
						events.length > 0 &&
						events.forEach(({ type, actions }) => {
							if (type === 'onClick') {
								const goToScene = actions.find((action) => action.type === 'goToScene');
								if (goToScene) {
									edges.push({
										id: generateUIId(),
										source: scene.id,
										target: goToScene?.metadata?.sceneId,
										style: props.summaryScenes
											? { stroke: 'red', opacity: '0.7' }
											: { stroke: 'red' },
										animated: props.summaryScenes ? false : true,
										type: 'customEdge',
										data: {
											source: scene.id,
											target: goToScene?.metadata?.sceneId,
											name: `Widget: ${name}`,
										},
									});
								}
							}
						});
				});
			}
		});
		setEdges(edges);
	}, [scenes, scenario.default]);

	useEffect(() => {
		const edgesMap = getCurrentEdgeDisplayBehaviourMap(edges);
		setEdgetMap(edgesMap);
	}, [edges]);

	useEffect(() => {
		if (lastEditedSceneId && flowInstanceRef.current) {
			const nodeList = flowInstanceRef.current.getNodes();
			const lastEditedNode = nodeList.filter(({ id }) => id === lastEditedSceneId)[0];

			if (lastEditedNode) {
				const {
					position: { x, y },
					width,
					height,
				} = lastEditedNode;
				const { clientHeight, clientWidth } = document.getElementsByClassName('react-flow__pane')[0];
				flowInstanceRef.current.setViewport(
					{
						x: -x - width / 2 + clientWidth / 2,
						y: -y - height / 2 + clientHeight / 2,
						zoom: 1,
					},
					{ duration: 800 }
				);
			}
		}
	}, [lastEditedSceneId, flowInstanceRef.current]);

	useEffect(() => {
		if (Object.keys(scenesNeedToUpdate) === 0) {
			return;
		}

		debouncedSaving(() => {
			Object.values(scenesNeedToUpdate).forEach((value) => {
				const { defaultAccountId, scenarioId, sceneId, body } = value;

				scenarioContainerRef.current?.isAutoSaving(true);
				dispatch(updateScene(defaultAccountId, scenarioId, sceneId, body)).then((resolve) => {
					if (resolve) {
						scenarioContainerRef.current?.isAutoSaving(false);
					}
				});
			});
			dispatch(emptyScenesToUpdateWaitingList());
		}, 2000);
	}, [scenesNeedToUpdate]);

	useEffect(() => {
		dispatch(getRestrictionRules(defaultAccountId));
	}, [defaultAccountId, dispatch]);

	const onNodeClick = useCallback(
		(event, node) => {
			const target = getElementParent(event.target, (p) => p.className.includes('scene-btn'));

			if (target && target.className.includes('trash')) {
				removeNode(event, node);
				return;
			}
			if (target && target.className.includes('home')) {
				setNodeHome(event, node);
				return;
			}
			if (target && target.className.includes('duplicate')) {
				duplicateNode(event, node);
				return;
			}
			if (target && target.className.includes('edit')) {
				props.onNodeDoubleClick?.(node);
				return;
			}
			if (target && target.className.includes('videoSettings')) {
				dispatch(
					setInspectingMediaData({
						id: node.data?.mediaDetails?.mediaId,
						mediaDetails: node.data?.mediaDetails,
						mediaName: mediaNames.medias,
					})
				);
				return;
			}

			props.onNodeClick?.(event, node);
		},
		[props, removeNode, setNodeHome, duplicateNode, dispatch]
	);

	const onNodeDoubleClick = useCallback(
		(_, node) => {
			props.onNodeDoubleClick?.(node);
		},
		[props.onNodeDoubleClick]
	);

	const removeNode = useCallback(
		(event, node) => {
			event.preventDefault();
			event.stopPropagation();
			if (!node) {
				return;
			}

			const newNodes = applyNodeChanges([{ id: node.id, type: 'remove' }], nodes);

			//ui update
			dispatch(updateScenesAction(newNodes));
			dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newNodes }));

			//saving to api
			const { id: scenarioId } = scenario;
			scenarioContainerRef.current?.isAutoSaving(true);
			dispatch(deleteScene(defaultAccountId, scenarioId, node.id)).then((resolve) => {
				if (resolve) {
					scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		},
		[nodes, scenario]
	);

	const duplicateNode = useCallback(
		(event, node) => {
			event.preventDefault();
			event.stopPropagation();
			if (!node) {
				return;
			}

			const widgetsToUpdate = [];
			const newSceneId = generateUUID();
			const newLayoutStuff = node.layouts.map((layout) => {
				const newLayoutId = generateUUID();
				const { boxes } = layout;

				const newBoxes = boxes.map((box) => {
					const { widgets, boxTemplateId } = box;

					const newWidgets = widgets.map((widget) => ({
						...widget,
						id: generateUUID(),
						sceneId: newSceneId,
						boxTemplateId,
						layoutId: newLayoutId,
					}));
					widgetsToUpdate.push(...newWidgets);

					return { ...box, widgets: newWidgets };
				});

				return {
					...layout,
					id: newLayoutId,
					boxes: newBoxes,
				};
			});

			const newNode = {
				...node,
				id: newSceneId,
				metadata: {
					...node.metadata,
					position: getNewPositionForScene(
						newSceneId,
						{ x: node.position.x + 250, y: node.position.y + 180 },
						nodes
					),
				},
				selected: false,
				data: {
					...node.data,
					home: false,
				},
				layouts: newLayoutStuff,
				events: [],
			};

			const sceneMetadata = { ...newNode.data.mediaDetails, sceneId: newSceneId };
			const newNodes = applyNodeChanges([{ item: newNode, type: 'add' }], nodes);

			//ui update
			dispatch(updateScenesAction(newNodes));
			dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newNodes }));
			dispatch(addScenesMetadata([...sceneWithMetadata, sceneMetadata]));
			moveViewportTo(newNode.metadata.position, flowInstanceRef);

			//saving to api
			//node here equal to scene
			const { id: scenarioId } = scenario;
			const sceneBody = {
				name: newNode.name,
				events: newNode.events,
				metadata: newNode.metadata,
				layout: {
					id: newLayoutStuff[0].id,
					type: 'desktop',
					layoutTemplate: DEFAULT_LAYOUT_TEMPLATE,
				},
			};

			scenarioContainerRef.current?.isAutoSaving(true);
			dispatch(createScene(defaultAccountId, scenarioId, newSceneId, sceneBody)).then((resolve) => {
				if (resolve) {
					const addWidgetPromises = [];
					widgetsToUpdate.forEach((widget) => {
						const { widgetTemplateId, start, end, id: widgetId } = widget;
						const { name, type, settings, style, events } = newNode.widgetTemplates.find(
							({ id }) => id === widgetTemplateId
						);

						const widgetBody = {
							name,
							widgetTemplateId,
							start,
							end,
							type,
							settings,
							style,
							events,
						};

						addWidgetPromises.push(
							dispatch(
								createWidget(
									defaultAccountId,
									scenarioId,
									newSceneId,
									newLayoutStuff[0].id,
									'1',
									widgetId,
									widgetBody
								)
							)
						);
					});

					Promise.all(addWidgetPromises).then((resolve) => {
						if (resolve) {
							scenarioContainerRef.current?.isAutoSaving(false);
						}
					});
				}
			});
		},
		[nodes, scenario, scenes, defaultAccountId, dispatch, scenarioContainerRef, sceneWithMetadata]
	);

	const setNodeHome = useCallback(
		(event, node) => {
			event.preventDefault();
			event.stopPropagation();
			const newNodes = nodes.map((n) => {
				if (n.id === node.id) {
					n.data.home = true;
				}
				if (n.data.home && n.id !== node.id) {
					n.data.home = false;
				}
				return n;
			});

			//ui update
			const defaults = { ...(scenario.defaults ?? {}), sceneId: node.id };
			dispatch(updateScenesAction(newNodes));
			dispatch(updateScenarioAction({ ...scenario, defaults }));

			//saving to api
			const { id: scenarioId } = scenario;
			scenarioContainerRef.current?.isAutoSaving(true);
			dispatch(
				updateScenario(defaultAccountId, scenarioId, {
					defaults,
				})
			).then((resolve) => {
				if (resolve) {
					scenarioContainerRef.current?.isAutoSaving(false);
				}
			});
		},
		[nodes, scenario]
	);

	const onMoveEnd = useCallback(
		(event, viewport) => {
			if (!showMiniNodes && viewport && viewport.zoom <= 0.7) {
				setShowMiniNodes(true);
			}
			if (showMiniNodes && viewport && viewport.zoom > 0.7) {
				setShowMiniNodes(false);
			}
		},
		[setShowMiniNodes, showMiniNodes]
	);

	const handleMenuSourceSelect = (key) => {
		const { recorderModalRef } = props;
		setResourceMenuEl(null);
		switch (key) {
			case UPLOAD:
				openFileSelector();
				break;
			case MEDIA_LIBRARY:
				interactMediaLibraryModalRef.current?.showMediaLibrary(true, TAB_ID.MEDIA_LIBRARY);
				break;
			case EDIT:
				dispatch(updateAdobeExpressEditorModal(true));
				AdobeExpressUtils.createNewProject({ ccEverywhere, actionCallback: uploadToPlatformCallback });
				break;
			case RECORDING:
				recorderModalRef?.current?.showScreenRecordModal((media) => {
					const isInChartPage = window.location.href.includes(
						`${screenRoutes.PUBLISH_MEDIA_LIBRARY}/interact/${scenario.id}/chart`
					);
					if (!media || !isInChartPage) {
						return;
					}
					const { x, y, width, height } = getNodesBounds(nodesRef.current);
					props.handleAddSceneIntoScenario([media], { x: x + width, y: y + height }, nodesRef.current);
				});
				break;
		}
	};

	const getMediaAddSection = () => {
		const { t } = props;
		return (
			<>
				<Button
					variant="contained"
					className={`defaultActionBtn squireBtn`}
					onClick={(e) => {
						setResourceMenuEl(e.currentTarget);
					}}
				>
					<IconsStore iconName={IconsList.PLUS_NEW} />
					{t('MEDIA_LIBRARY_INTERACT_INSPECT_NEW_SCENE')}
				</Button>
				<Menu
					anchorEl={resourceMenuEl}
					open={!!resourceMenuEl}
					onClose={() => {
						setResourceMenuEl(null);
					}}
					getContentAnchorEl={null}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					{Object.values(ADD_NEW_SCENE_SOURCE_OPTIONS).map(({ key, name, icon }) => {
						if (key === RECORDING && !isPegacoderEnable) {
							return null;
						}
						return (
							<MenuItem
								key={key}
								value={key}
								onClick={() => {
									handleMenuSourceSelect(key);
								}}
							>
								<ListItemIcon style={{ minWidth: '30px' }}>{icon}</ListItemIcon>
								<ListItemText primary={t(name)} />
							</MenuItem>
						);
					})}
				</Menu>
			</>
		);
	};

	const onConnect = useCallback(
		(params) => {
			let changedScene = nodes.find((node) => node.id === params.source);
			let endOfScene = changedScene.events?.find((e) => e.type === 'endOfScene');
			const actionType = 'goToScene';

			if (!endOfScene || endOfScene.length === 0) {
				endOfScene = {
					id: generateUIId(),
					type: 'endOfScene',
					actions: [{ type: actionType, metadata: { sceneId: params.target } }],
				};
			} else if (!endOfScene.actions || !endOfScene.actions.find((action) => action.type === actionType)) {
				// a scene only has 1 goToScene action
				endOfScene.actions = [
					...(endOfScene.actions ?? []).filter((action) => action.type !== actionType),
					{ type: actionType, metadata: { sceneId: params.target } },
				];
			} else {
				endOfScene.actions = endOfScene.actions.map((action) =>
					action.type === actionType ? { type: actionType, metadata: { sceneId: params.target } } : action
				);
			}
			// 1 scene only link to 1 scene when it's end
			endOfScene.actions = endOfScene.actions.filter(
				(action) => action.type !== 'goToHome' && action.type !== 'loopCurrentScene'
			);

			if (!changedScene.events || changedScene.events.length === 0) {
				changedScene = {
					...changedScene,
					events: [endOfScene],
				};
			} else if (changedScene.events.filter((e) => e.type === 'endOfScene').length === 0) {
				changedScene.events = [...changedScene.events, endOfScene];
			} else {
				changedScene.events = changedScene.events.map((e) => (e.type === 'endOfScene' ? endOfScene : e));
			}

			setEdges((els) =>
				addEdge(
					{ ...params, style: connectionLineStyle, data: { source: params.source, target: params.target } },
					els
				)
			);
			const newScenes = nodes.map((node) => (node.id === changedScene.id ? changedScene : node));

			//ui update
			dispatch(updateScenesAction(newScenes));
			dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes }));

			//saving to api

			const { id: scenarioId } = scenario;
			const { id: sceneId } = changedScene;

			dispatch(
				addScenesToUpdateWaitingList([
					{
						defaultAccountId,
						scenarioId,
						sceneId,
						body: changedScene,
					},
				])
			);
		},
		[nodes, edges, setEdges, scenario, defaultAccountId, dispatch, scenarioContainerRef]
	);

	const onNodesChange = useCallback(
		(changes) => {
			setHelperLineHorizontal(undefined);
			setHelperLineVertical(undefined);

			if (changes.every((c) => c.type === 'dimensions')) {
				return;
			}

			if (changes.every((c) => c.type === 'select')) {
				const newScenes = applyNodeChanges(changes, nodes);
				dispatch(updateScenesAction(newScenes));
				dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes }));
				return;
			}

			if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging && changes[0].position) {
				let newNodes;
				const positionUpdatedScenes = changes.filter((nodeChange) => nodeChange.type === 'position');

				const helperLines = getHelperLines(positionUpdatedScenes[0], nodes);

				positionUpdatedScenes[0].position.x = helperLines.snapPosition.x ?? positionUpdatedScenes[0].position.x;
				positionUpdatedScenes[0].position.y = helperLines.snapPosition.y ?? positionUpdatedScenes[0].position.y;

				setHelperLineHorizontal(helperLines.horizontal);
				setHelperLineVertical(helperLines.vertical);

				const isScenesOverlap = positionUpdatedScenes.some((updateScene) => {
					if (!updateScene.position) {
						return false;
					}
					const {
						id: updateSceneId,
						position: { x: updateSceneX, y: updateSceneY },
					} = updateScene;

					const updateSceneRect = {
						x1: updateSceneX,
						y1: updateSceneY,
						x2: updateSceneX + defaultSceneWidth,
						y2: updateSceneY + defaultSceneHeight,
					};

					const isOverlap = nodes.find((oldNode) => {
						const {
							position: { x: oldNodeX, y: oldNodeY },
							id,
						} = oldNode;

						const oldNodeRect = {
							x1: oldNodeX,
							y1: oldNodeY,
							x2: oldNodeX + defaultSceneWidth,
							y2: oldNodeY + defaultSceneHeight,
						};

						return id !== updateSceneId && checkOverlapScenes(updateSceneRect, oldNodeRect);
					});

					return !!isOverlap;
				});

				if (isScenesOverlap) {
					const updatedChanges = changes.map((change) => {
						if (change.type !== 'position') {
							return change;
						}

						const oldNode = nodes.find((node) => node.id === change.id);

						if (oldNode) {
							return oldNode;
						}
						return change;
					});
					newNodes = applyNodeChanges(updatedChanges, nodes);
				} else {
					newNodes = applyNodeChanges(changes, nodes);
				}

				const newScenes = newNodes.map((node) => {
					return {
						...node,
						metadata: { ...node.metadata, position: node.position },
					};
				});

				//ui update
				dispatch(updateScenesAction(newScenes));
				dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes }));

				//saving to api
				const oldNode = nodes.find((node) => node.id === changes[0].id);
				const newNode = newScenes.find((node) => node.id === changes[0].id);

				if (
					Math.abs(oldNode.position.x - newNode.position.x) < 1 &&
					Math.abs(oldNode.position.y - newNode.position.y) < 1
				) {
					return;
				}

				changes.forEach(({ id: changeId }) => {
					const newScene = newNodes.find(({ id: nodeId }) => nodeId === changeId);
					const { id: scenarioId } = scenario;
					const { id: sceneId } = newScene;

					dispatch(
						addScenesToUpdateWaitingList([
							{
								defaultAccountId,
								scenarioId,
								sceneId,
								body: { metadata: newScene.metadata },
							},
						])
					);
				});
			}
		},
		[nodes, scenario, defaultAccountId, dispatch, scenarioContainerRef]
	);

	const onEdgesChange = useCallback(
		(changes) => {
			setEdges(applyEdgeChanges(changes, edges));
		},
		[edges, setEdges]
	);

	const onEdgesDelete = useCallback(
		(changes) => {
			//for now only 1 edges per change
			const {
				source,
				data: { id },
			} = changes[0];
			const updatingScence = scenes.filter(({ id }) => source !== id)[0];

			if (updatingScence) {
				const eventsModified = updatingScence.events.filter((e) => e.id === id);

				const newScenes = scenes.map((scene) =>
					scene.id === source ? { ...scene, events: eventsModified } : scene
				);

				//ui update
				dispatch(updateScenesAction(newScenes));
				dispatch(updateScenarioAction({ ...(scenario ?? {}), scenes: newScenes }));

				//saving to api
				const newScene = newScenes.find(({ id }) => id === source);
				const { id: scenarioId } = scenario;
				const { id: sceneId } = newScene;

				dispatch(addScenesToUpdateWaitingList([{ defaultAccountId, scenarioId, sceneId, body: newScene }]));
			}
		},
		[edges, setEdges]
	);

	const debouncedSaving = (func, timeout = 300) => {
		clearTimeout(debounceTimer.current);
		debounceTimer.current = setTimeout(func, timeout);
	};

	return (
		<EdgeContext.Provider value={edgesMap}>
			<input
				type="file"
				id="file"
				ref={inputFileRef}
				style={{ display: 'none' }}
				accept=".mp4,.m4a,.m4v,.mp3,.mov,.wmv"
				onChange={(event) => {
					const files = event.target.files;
					startUploadFile(files[0]).then((media) => {
						const { x, y, width, height } = getNodesBounds(nodes);
						props.handleAddSceneIntoScenario([media], { x: x + width, y: y + height }, nodes);
					});
				}}
			/>
			<div className={baseClassName}>
				{nodes && nodes.length > 0 && (
					<div className={`${baseClassName}__actions ${props.summaryScenes ? 'summaryScenes' : ''}`}>
						{props.actions ? props.actions : getMediaAddSection()}
					</div>
				)}

				<div
					className={[`${baseClassName}__body`, props.summaryScenes ? `${baseClassName}__body--summary` : '']
						.join(' ')
						.trim()}
				>
					{props.summaryScenes && (
						<ReactFlow
							nodes={nodes}
							edges={edges}
							nodeTypes={nodeTypes}
							edgeTypes={edgeTypes}
							connectionLineStyle={connectionLineStyle}
							onMoveEnd={onMoveEnd}
							elementsSelectable={false}
							fitView
						>
							{(!nodes || nodes.length === 0) && (
								<div
									className={`${baseClassName}__actions ${baseClassName}__empty ${
										props.summaryScenes ? 'summaryScenes' : ''
									}`}
								>
									<div className="scenario-chart__message">
										{props.t('SCENARIO_STORYBOARD_EMPTY_SCENES_MESSAGE')}
									</div>
									{props.actions}
								</div>
							)}

							<GraphBackground />
							{nodes && nodes.length > 0 && <GraphMiniMap />}
							{nodes && nodes.length > 0 && <Controls showInteractive={false} />}
						</ReactFlow>
					)}

					{!props.summaryScenes && (
						<ReactFlow
							onInit={(instance) => {
								flowInstanceRef.current = instance;
							}}
							nodes={nodes}
							edges={edges}
							nodeTypes={nodeTypes}
							edgeTypes={edgeTypes}
							connectionLineStyle={connectionLineStyle}
							onNodesChange={onNodesChange}
							onEdgesChange={onEdgesChange}
							onNodeClick={onNodeClick}
							onNodeDoubleClick={onNodeDoubleClick}
							onConnect={onConnect}
							onMoveEnd={onMoveEnd}
							deleteKeyCode={['Backspace', 'Delete']}
							selectNodesOnDrag
							onEdgesDelete={onEdgesDelete}
							onlyRenderVisibleElements={true}
						>
							{(!nodes || nodes.length === 0) && (
								<div className={`${baseClassName}__actions ${baseClassName}__empty summaryScenes`}>
									<div className="scenario-chart__message">
										{props.t('MEDIA_LIBRARY_INTERACT_INSPECT_NO_SCENE_MESSAGE')}
									</div>
									{getMediaAddSection()}
								</div>
							)}

							<GraphBackground />
							{nodes && nodes.length > 0 && <GraphMiniMap />}
							{nodes && nodes.length > 0 && <Controls />}
							<HelperLinesRenderer horizontal={helperLineHorizontal} vertical={helperLineVertical} />
						</ReactFlow>
					)}
				</div>
			</div>

			<InteractMediaLibraryModal
				mediaType={mediaTypes.video}
				ref={interactMediaLibraryModalRef}
				handleReturnedMedia={(media) => {
					const { x, y, width, height } = getNodesBounds(nodes);
					props.handleAddSceneIntoScenario(media, { x: x + width, y: y + height }, nodes);
				}}
			/>
		</EdgeContext.Provider>
	);
};

export default ScenarioGraph;
