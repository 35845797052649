import React, { useState } from 'react';
import { getIconByName } from '../../../../../reusable/IconLibrary/IconLibrary';
import { WidgetProps } from './WidgetProps';

type GenerateIconStyleProps = {
	isShowWidget?: boolean;
	isHover: boolean;
	style: {
		textLabel: {
			color: string;
			hoverColor: string;
		};
	};
};
const generateIconStyle = ({ isShowWidget, style, isHover }: GenerateIconStyleProps) => {
	const {
		textLabel: { color, hoverColor },
	} = style;

	return {
		display: isShowWidget ? 'inline-block' : 'none',
		color: !isHover ? color : hoverColor,
		width: '100%',
		height: '100%',
	};
};

const IconWidget = ({
	id,
	isShowWidget,
	itemRef,
	onClickItem,
	widgetTemplateId,
	style,
	isSafari,
	settings,
	onContextMenu,
}: WidgetProps.BaseProps) => {
	const { iconKey } = settings;

	const [isHover, setIsHover] = useState(false);

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	return (
		<g id={widgetTemplateId} className={`${!isSafari ? 'widget' : ''} widget_${widgetTemplateId}`}>
			<g
				ref={itemRef}
				data-widget-id={id}
				data-template-id={widgetTemplateId}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onContextMenu={(e) => {
					e.preventDefault();
					onContextMenu?.(itemRef, !!isShowWidget);
					return false;
				}}
			>
				<foreignObject>
					{getIconByName(iconKey, {
						style: generateIconStyle({ isShowWidget, style, isHover }),
						onClick: (e: any) => {
							e.stopPropagation();
							onClickItem?.(itemRef);
						},
					})}
				</foreignObject>
			</g>
		</g>
	);
};

export default IconWidget;
