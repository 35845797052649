import {
	validateToken,
	resetPassword,
	changePassword,
	loadUserAccounts,
	loadUserPermissions,
	loadUserInfo,
	revokeToken,
	getCustomMetadata,
} from '../services/profileServices';
import { loadApps } from './appsActions';
import { getAccountFeatures } from './accountFeaturesActions';
import { identityConfigurations } from '../utils/config';
import { LocalStorageService } from '../services/localStorageService';
import { showNotification } from './notificationActions';
import { showMessage } from './globalActions';
import { messageTypes } from '../constants/mediaConstants';

export const SESSION_END = 'SESSION_END';
export const SESSION_INITIALISE = 'SESSION_INITIALISE';
export const LOGIN_FORM_UPDATE = 'REGISTER_FORM_UPDATE';
export const SEND_LOGIN_FORM_REQUEST = 'LOGIN_FORM_UPDATE';
export const LOGIN_PROCESS_SUCCESS = 'LOGIN_PROCESS_SUCCESS';
export const SEND_LOGIN_CLEAR_FORM = 'SEND_LOGIN_CLEAR_FORM';
export const SEND_LOGIN_FORM_SUCCESS = 'SEND_LOGIN_FORM_SUCCESS';
export const SEND_LOGIN_FORM_FAILURE = 'SEND_LOGIN_FORM_FAILURE';
export const USER_PERMISSIONS_REINITIALIZE = 'USER_PERMISSIONS_REINITIALIZE';
export const DEFAULT_ACCOUNTID_REINITIALIZE = 'DEFAULT_ACCOUNTID_REINITIALIZE';
export const SEND_PASSWORD_RESET_FORM_REQUEST = 'SEND_PASSWORD_RESET_FORM_REQUEST';
export const SEND_PASSWORD_RESET_FORM_SUCCESS = 'SEND_PASSWORD_RESET_FORM_SUCCESS';
export const SEND_PASSWORD_RESET_FORM_FAILURE = 'SEND_PASSWORD_RESET_FORM_FAILURE';
export const USER_PERMISSIONS_REINITIALIZE_REQUEST = 'USER_PERMISSIONS_REINITIALIZE_REQUEST';
export const USER_PERMISSIONS_REINITIALIZE_REQUEST_SUCCESS = 'USER_PERMISSIONS_REINITIALIZE_REQUEST_SUCCESS';
export const USER_PERMISSIONS_REINITIALIZE_REQUEST_FAILURE = 'USER_PERMISSIONS_REINITIALIZE_REQUEST_FAILURE';
export const SAVE_CUSTOM_METADATA = 'SAVE_CUSTOM_METADATA';

export const TRIGGER_ADOBE_EXPRESS_EDITOR = 'TRIGGER_ADOBE_EXPRESS_EDITOR';
export const SET_SCREEN_RECORDER_MODAL_STATUS = 'SET_SCREEN_RECORDER_MODAL_STATUS';

const sendLoginFormRequest = (_) => ({
	type: SEND_LOGIN_FORM_REQUEST,
});

const sendLoginFormSuccess = (_) => ({
	type: SEND_LOGIN_FORM_SUCCESS,
});

const loginProcessSuccess = (_) => ({
	type: LOGIN_PROCESS_SUCCESS,
});

const sendLoginFormFailure = (_) => ({
	type: SEND_LOGIN_FORM_FAILURE,
});

// eslint-disable-next-line no-unused-vars
const sendLoginClearForm = (_) => ({
	type: SEND_LOGIN_CLEAR_FORM,
});

const sendPasswordResetFormRequest = (_) => ({
	type: SEND_PASSWORD_RESET_FORM_REQUEST,
});

const sendPasswordResetFormSuccess = (_) => ({
	type: SEND_PASSWORD_RESET_FORM_SUCCESS,
});

const sendPasswordResetFormFailure = (_) => ({
	type: SEND_PASSWORD_RESET_FORM_FAILURE,
});

const userPermissionsReinitializeRequest = (_) => ({
	type: USER_PERMISSIONS_REINITIALIZE_REQUEST,
});

const userPermissionsReinitializeRequestSuccess = (_) => ({
	type: USER_PERMISSIONS_REINITIALIZE_REQUEST_SUCCESS,
});

const defaultAccountIdReinitilize = (defaultAccountId, defaultAccountName) => ({
	type: DEFAULT_ACCOUNTID_REINITIALIZE,
	defaultAccountId,
	defaultAccountName,
});

const userPermissionsReinitializeRequestFailure = (_) => ({
	type: USER_PERMISSIONS_REINITIALIZE_REQUEST_FAILURE,
});

const userPermissionsReinitialize = (userPermissions) => ({
	type: USER_PERMISSIONS_REINITIALIZE,
	userPermissions,
});

const saveCustomMetadata = (customMetadata) => ({
	type: SAVE_CUSTOM_METADATA,
	customMetadata: customMetadata,
});

export const setScreenRecorderModalStatus = (isOpen) => ({
	type: SET_SCREEN_RECORDER_MODAL_STATUS,
	isOpen,
});

export const updateAdobeExpressEditorModal = (isOpen) => ({
	type: TRIGGER_ADOBE_EXPRESS_EDITOR,
	isOpen,
});

export const sessionInit = ({
	userId = '',
	userPermissions = '',
	accounts = '',
	username = '',
	defaultAccountId = '',
	accessToken = '',
	defaultAccountName = '',
}) => ({
	type: SESSION_INITIALISE,
	userId,
	accounts,
	username,
	accessToken,
	userPermissions,
	defaultAccountId,
	defaultAccountName,
});

export const sessionEnd = (_) => ({
	type: SESSION_END,
});

export const LoginFormUpdate = (field, value) => ({
	type: LOGIN_FORM_UPDATE,
	field,
	value,
});

export const login = (_) => (dispatch) => {
	const userId = LocalStorageService.getUserId();
	const access_token = LocalStorageService.getAccessToken();
	dispatch(sendLoginFormRequest());
	return validateToken(access_token)
		.then((data) => {
			if (data && data.isValid && data.userId) {
				dispatch(sendLoginFormSuccess());
				return data;
			}
		})
		.then((data) => {
			localStorage.setItem('userId', data.userId);
			return loadUserInfo(data.userId || userId).then((me) => {
				localStorage.setItem('username', me.login);
				return me;
			});
		})
		.then((profile) =>
			loadUserAccounts(profile.userId || userId).then((data) => {
				if (data && data.length > 0) {
					data.accessToken = access_token;
					data.defaultAccountId = data[0].accountId;
					data.defaultAccountName = data[0].name;
					localStorage.setItem('defaultAccountId', data.defaultAccountId);
					localStorage.setItem('defaultAccountName', data.defaultAccountName);
					localStorage.setItem('accounts', JSON.stringify(data));
					return {
						userId: profile.userId,
						accounts: data,
						username: profile.login,
					};
				} else {
					dispatch(logoutUserSession());
				}
			})
		)
		.then((data) => {
			dispatch(sendCustomMetadataRequest(data.accounts[0].accountId));
			dispatch(loadApps(data.accounts[0].accountId)); //Fetch apps

			return loadUserPermissions(data.accounts[0].accountId).then((features) => {
				data.userPermissions = JSON.stringify(features.permissions);
				localStorage.setItem('userPermissions', JSON.stringify(features.permissions));
				return data;
			});
		})
		.then((data) => dispatch(sessionInit(data)))
		.then((_) => dispatch(loginProcessSuccess()))
		.catch((error) => {
			dispatch(sendLoginFormFailure());
			dispatch(logoutUserSession());
			return error;
		});
};

export const checkLoginStatus = (_) => (dispatch) => {
	const accessToken = LocalStorageService.getAccessToken();
	return validateToken(accessToken).then((response) => {
		if (response && response.isValid && response.userId) {
			dispatch(sendLoginFormSuccess());
			return response;
		}
	});
};

export const passwordReset = (_) => (dispatch, getState) => {
	const { username } = getState().session;
	dispatch(sendPasswordResetFormRequest());
	return resetPassword(username)
		.then((res) => {
			if (res) {
				dispatch(sendPasswordResetFormSuccess());
				return res;
			} else {
				dispatch(showMessage('Failed to reset password.', messageTypes.error));
			}
		})
		.catch((error) => {
			dispatch(sendPasswordResetFormFailure());
			return error;
		});
};

export const passwordChange = (_) => (dispatch, getState) => {
	const { password, newPassword } = getState().session;
	dispatch(sendPasswordResetFormRequest());
	return changePassword(password, newPassword)
		.then((res) => {
			if (res) {
				dispatch(sendPasswordResetFormSuccess());
				return res;
			} else {
				dispatch(showMessage('Failed to change password.', messageTypes.error));
			}
		})
		.catch((error) => {
			dispatch(sendPasswordResetFormFailure());
			return error;
		});
};

export const logoutUserSession = (_) => (dispatch) => {
	const logouturl = identityConfigurations.logoutUrl + LocalStorageService.getTokenId();
	const accessToken = LocalStorageService.getAccessToken();
	return revokeToken(accessToken).then((res) => {
		if (res && !res.isRevoked) {
			dispatch(showNotification('Failed to logout'));
		}
		LocalStorageService.clearLocalStorage();
		dispatch(sessionEnd());
		window.location.href = logouturl;
	});
};

export const switchToAccount = (accountId, accountName) => (dispatch) => {
	dispatch(userPermissionsReinitializeRequest());
	return loadUserPermissions(accountId)
		.then((features) => {
			localStorage.setItem('defaultAccountId', accountId);
			localStorage.setItem('defaultAccountName', accountName);
			dispatch(userPermissionsReinitializeRequestSuccess());
			dispatch(userPermissionsReinitialize(JSON.stringify(features.permissions)));
			dispatch(defaultAccountIdReinitilize(accountId, accountName));
			dispatch(sendCustomMetadataRequest(accountId));
			dispatch(getAccountFeatures(accountId));
			return features.userPermissions;
		})
		.catch((_) => dispatch(userPermissionsReinitializeRequestFailure()));
};

export const sendCustomMetadataRequest = (accountId) => (dispatch) => {
	return getCustomMetadata(accountId)
		.then((customMetadata) => {
			localStorage.setItem('customMetadata', JSON.stringify(customMetadata));
			dispatch(saveCustomMetadata(JSON.stringify(customMetadata)));
			return customMetadata;
		})
		.catch(() => {
			dispatch(showNotification('Failed to fetch custom properties'));
		});
};
