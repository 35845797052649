import React, { useEffect, useState } from 'react';
import Layout from './components/Layout';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reportsSummary, histogramsReportsSummary, reportsMilestones } from '../../services/analyticsServices';
import SectionLayout from './components/SectionLayout';
import TopMetrics from './components/TopMetrics';
import VideoPerformance from './components/VideoPerformance';
import FilterByDates from './components/FilterByDates';
import { filterTypeValues } from '../../constants/analyticsConstant';
import { Box } from '@material-ui/core';
import { screenRoutes } from './../../constants/routesPath';
import { useTranslation } from 'react-i18next';
import { getMediaQueryDetails } from '../../actions/publishActions';
import { getTypeOfMedia } from '../../services/mediaDisplayService';

const AnalyticsVideoPerformanceByMediaIdScreen = ({ mediaView }) => {
	let { mediaId } = useParams();
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [reportsSummaryData, setReportsSummaryData] = useState([]);
	const [histoReportsSummaryData, setHistoReportsSummaryData] = useState([]);
	const [reportsMilestonesData, setReportsMilestonesData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [mediaType, setMediaType] = useState('');

	const getReportsSummaryData = async (queries, filterType) => {
		let queryBucket = queries;
		if (filterType === filterTypeValues[2]) {
			queryBucket = `${queries}&bucket=started+01:00:00`;
		}
		setLoading(true);
		let [Rsummary, HRsummaryms, Rmilestones] = await Promise.all([
			reportsSummary(accountId, queries),
			histogramsReportsSummary(accountId, queryBucket),
			reportsMilestones(accountId, queryBucket),
		]);

		setReportsSummaryData(Rsummary);
		setHistoReportsSummaryData(HRsummaryms);
		setReportsMilestonesData(Rmilestones);
		if (Rmilestones) {
			setLoading(false);
		}
	};

	const getFilterData = (
		startDate = moment().subtract(7, 'days').valueOf(),
		endDate = moment().valueOf(),
		filterType = filterTypeValues[2]
	) => {
		const queries =
			'q=media.id:' +
			mediaId +
			' AND started:[' +
			moment(startDate).startOf('day').format() +
			' TO ' +
			moment(endDate).endOf('day').format() +
			']';
		getReportsSummaryData(queries.replace(/\+/g, '%2B'), filterType);
	};

	useEffect(() => {
		getFilterData();
	}, []);

	useEffect(() => {
		dispatch(getMediaQueryDetails(accountId, mediaId, 'medias')).then((data) => {
			if (!data) {
				return;
			}

			const mediaType = getTypeOfMedia(data);
			setMediaType(mediaType);
		});
	}, [accountId, mediaId]);

	const breadC = {
		subTitle: reportsMilestonesData?.name,
		text: 'Video Performance',
		link: screenRoutes.ANALYTICS_VIDEO_PERFORMANCE,
	};

	return (
		<Layout title="ANALYTICS_TITLE_PLAY_ANALYTICS" breadCrumb={breadC} mediaView={mediaView}>
			<FilterByDates getFilterData={getFilterData} playSec={true} />
			<SectionLayout title="ANALYTICS_TITLE_TOP_METRICS" addClass="topMetrics" loading={!!loading}>
				{Object.keys(reportsSummaryData ?? {}).length > 0 ? (
					<TopMetrics reportsSummaryData={reportsSummaryData} mediaType={mediaType} />
				) : (
					<Box p={4}>{t('ANALYTICS_DATA_NOT_FOUND')}</Box>
				)}
			</SectionLayout>
			<SectionLayout title="ANALYTICS_TITLE_VIDEO_PERFORMANCE" addClass="videoPerformance" loading={!!loading}>
				{Object.keys(histoReportsSummaryData ?? {}).length > 0 ? (
					<VideoPerformance
						histoReportsSummaryData={{ ...(histoReportsSummaryData ?? {}) }}
						reportsMilestonesData={reportsMilestonesData}
					/>
				) : (
					<Box p={4}>{t('ANALYTICS_DATA_NOT_FOUND')}</Box>
				)}
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsVideoPerformanceByMediaIdScreen;
