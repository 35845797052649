import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
	addWidgetsToUpdateWaitingList,
	setSceneLoading,
	updateEditingSceneAndSendToPlayer,
	updateEditingSceneDurationAction,
	updateIsTimeLineChangedAction,
} from '../../../../../../../actions/scenarioActions';
import { generateUIId } from '../../../../../../../services/elementHelperService';
import { generateId } from '../../../../../../../services/stringHelperService';
import { showAlert, showMessage } from '../../../../../../../actions/globalActions';
import { mediaNames, messageTypes } from '../../../../../../../constants/mediaConstants';
import { getMediaQueryDetails } from '../../../../../../../actions/publishActions';
import { getDetails, getThumbnailFromRenditionsBasedOnWidth } from '../../../../../../../services/mediaDisplayService';
import {
	convertInputTimeToWidgetTime,
	getSecondsFromTimelineTime,
	getTimelineTimeFromTimeInput,
	convertWidgetTimeToInputTime,
	convertTimeToWidgetTime,
} from '../../../../../../../services/timeStampService';
import no_thumbnail from '../../../../../../../assets/svg/NoThumbnail.svg';

import GeneralSettings from './widgetsConfigSection/GeneralSettings';
import Content from './widgetsConfigSection/Content';
import {
	ACTION_GO_TO_SCENE,
	ACTION_GO_TO_URL,
	ACTION_JUMP_TO_TIME,
} from '../../../../../../../constants/scenarioConstant';
import { setInspectingMediaData } from '../../../../../../../actions/inspectScreenActions';

const WidgetSettings = forwardRef((props, ref) => {
	const dispatch = useDispatch();

	const scenario = useSelector((state) => state.scenarioReducer.scenario);
	const scenes = useSelector((state) => state.scenarioReducer.scenes);
	const activeLayout = useSelector((state) => state.scenarioReducer.activeLayout ?? 'mobile');
	const editingScene = useSelector((state) => state.scenarioReducer.editingScene);
	const duration = useSelector((state) => state.scenarioReducer.editingSceneDuration);
	const defaultAccountId = useSelector((state) => state.session.defaultAccountId);

	const [widgetEndTime, setWidgetEndTime] = useState('');
	const [widgetStartTime, setWidgetStartTime] = useState('');

	const editingWidgetRef = useRef(props.editingWidget);
	const editingSceneRef = useRef(editingScene);

	const gotoUrlRef = useRef(null);
	const openNewTabRef = useRef(null);

	const [currentMediaDetail, setCurrentMediaDetail] = useState();

	const currentLayout = useMemo(() => {
		let layout = editingScene?.layouts?.find((l) => l.type === activeLayout);
		return layout ?? editingScene?.layouts?.[0] ?? {};
	}, [activeLayout, editingScene]);

	const editingWidgetInBox = useMemo(() => {
		const editingBox = currentLayout?.boxes?.find(
			(b) => b.boxTemplateId?.toString() === (props.editingBox ?? props.editingWidget?.boxId)?.toString()
		);
		return ((editingBox ?? {}).widgets ?? []).find((widget) => widget.id === props.editingWidget?.id);
	}, [editingScene, props.editingWidget, props.editingBox, currentLayout]);

	const editingWidget = useMemo(() => {
		setWidgetEndTime((editingWidgetInBox?.end ?? '').replace('.', ':') ?? '');
		setWidgetStartTime((editingWidgetInBox?.start ?? '').replace('.', ':') ?? '');
		return ((editingScene ?? {}).widgetTemplates ?? []).find(
			(widget) => widget.id === editingWidgetInBox?.widgetTemplateId
		);
	}, [editingScene, editingWidgetInBox]);

	const [textHoverSetting, setTextHoverSetting] = useState(false);
	const [bgHoverSetting, setBGHoverSetting] = useState(false);

	const dropzoneRef = useRef(null);
	const [uploadedFile, setUploadedFile] = useState(null);
	const initUpdateWidgetToApi = (newEditingScene, updateBody) => {
		const { id: scenarioId } = scenario;

		if (!newEditingScene) {
			return;
		}

		const boxWidget = (
			((newEditingScene.layouts ?? []).find((l) => l.id === currentLayout?.id)?.boxes ?? []).find(
				(b) => b.boxTemplateId === (props.editingBox ?? props.editingWidget?.boxId)
			)?.widgets ?? []
		).find((w) => w.widgetTemplateId === editingWidget.id);

		dispatch(
			addWidgetsToUpdateWaitingList([
				{
					defaultAccountId,
					scenarioId,
					sceneId: newEditingScene.id,
					layoutId: currentLayout?.id,
					boxId: props.editingBox ?? props.editingWidget?.boxId,
					widgetId: boxWidget.id,
					body: updateBody,
					widgetType: editingWidget.type,
				},
			])
		);
	};

	const [imageHoverSetting, setImageHoverSetting] = useState(false);

	const getWidgetVideo = (defaultAccountId, mediaId) => {
		dispatch(getMediaQueryDetails(defaultAccountId, mediaId, 'medias')).then((media) => {
			if (!media) {
				dispatch(showMessage("Can't get media detail", messageTypes.error));
				dispatch(setSceneLoading(false));
				setUploadedFile(null);
				setCurrentMediaDetail(null);
				return;
			}
			const data = getDetails(media);
			let thumbnail = data.thumbnail;
			if (thumbnail instanceof Array) {
				thumbnail = getThumbnailFromRenditionsBasedOnWidth(thumbnail, 220);
			}

			const img = document.getElementById('widget-thumbnail');
			img.src = thumbnail;
			dispatch(setSceneLoading(false));
			setCurrentMediaDetail(data);
		});
	};

	useImperativeHandle(ref, () => {
		return {
			handleTextLabelChange,
		};
	});

	useEffect(() => {
		const { type } = editingWidget || {};

		let widgetData = '';
		switch (type) {
			case 'image': {
				widgetData = imageHoverSetting
					? editingWidget?.settings?.bgImgUrl ?? ''
					: editingWidget?.settings?.imgUrl ?? '';
				break;
			}
			case 'video': {
				widgetData = editingWidget?.settings?.mediaId ?? '';
				dispatch(setSceneLoading(true));
				setTimeout(() => getWidgetVideo(defaultAccountId, widgetData), 500);
				break;
			}
			case 'icon': {
				widgetData = '';
				break;
			}
			default: {
				widgetData = null;
			}
		}

		const img = document.getElementById('widget-thumbnail');
		if (img) {
			img.style.objectFit = widgetData ? 'cover' : 'none';
			img.src = widgetData || no_thumbnail;
		}

		setUploadedFile(widgetData);
	}, [editingScene, editingWidget, defaultAccountId, imageHoverSetting]);

	useEffect(() => {
		props.addHoverImageRef.current = imageHoverSetting;
	}, [imageHoverSetting]);

	useEffect(() => {
		editingWidgetRef.current = props.editingWidget;
	}, [props.editingWidget]);

	useEffect(() => {
		editingSceneRef.current = editingScene;
	}, [editingScene]);

	const handleAddNewEventToWidget = useCallback(
		(eventType) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);

			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);

			const newEvent = {
				id: generateUIId(),
				type: eventType,
				actions: [],
			};

			const newEvents = [...currentWidgetTemplate.events, newEvent];
			currentWidgetTemplate.events = newEvents;
			editingScene.widgetTemplates = [...widgetTemplates, currentWidgetTemplate];
			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }, true));
			initUpdateWidgetToApi(editingScene, { events: newEvents });
		},
		[editingScene, props.editingWidget?.widgetTemplateId]
	);

	const handleDeleteWidgetEvent = useCallback(
		(eventType) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);

			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);

			const newEvents = (currentWidgetTemplate.events ?? []).filter((e) => e.type !== eventType);
			currentWidgetTemplate.events = newEvents;
			editingScene.widgetTemplates = [...widgetTemplates, currentWidgetTemplate];
			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }, true));
			initUpdateWidgetToApi(editingScene, { events: newEvents });
		},
		[editingScene, props.editingWidget?.widgetTemplateId]
	);

	const handleAddActionToEvent = useCallback(
		(actionType, eventType) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);

			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);

			const editingEvent = (currentWidgetTemplate.events ?? []).find((event) => event.type === eventType);
			const othersEvent = (currentWidgetTemplate.events ?? []).filter((event) => event.type !== eventType);

			const newAction = {
				type: actionType,
				metadata: {},
			};

			editingEvent.actions = [newAction];
			const newEvents = [...othersEvent, editingEvent];
			currentWidgetTemplate.events = newEvents;
			editingScene.widgetTemplates = [...widgetTemplates, currentWidgetTemplate];
			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }, true));
			initUpdateWidgetToApi(editingScene, { events: newEvents });
		},
		[editingScene, props.editingWidget?.widgetTemplateId]
	);

	const handleDestinationSceneChange = useCallback(
		(event) => {
			const metadata = { sceneId: event.target.value };
			handleWidgetActionChange(metadata, ACTION_GO_TO_SCENE.value, 'onClick');
		},
		[editingScene, props.editingWidget, editingWidget]
	);

	const handleGotoUrlWidgetChange = (event) => {
		const openNewTabValue = openNewTabRef.current.checked;
		const metadata = { url: event.target.value, openNewTab: openNewTabValue };
		handleWidgetActionChange(metadata, ACTION_GO_TO_URL.value, 'onClick');
	};

	const handleGotoUrlOpenNewTabChange = (event) => {
		const data = event.target.checked;
		const goToUrlValue = gotoUrlRef.current.value;
		const metadata = { url: goToUrlValue, openNewTab: data };
		handleWidgetActionChange(metadata, ACTION_GO_TO_URL.value, 'onClick');
	};

	const handleWidgetActionChange = useCallback(
		(metadata, actionName, eventName, preventSubmitToApi) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);
			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);

			if (!currentWidgetTemplate) {
				return;
			}

			const updatingEvent = (currentWidgetTemplate?.events ?? []).find((e) => e.type === eventName);
			if (updatingEvent) {
				const updatingAction = (updatingEvent.actions ?? []).find((action) => action.type === actionName);
				if (updatingAction) {
					updatingEvent.actions = (updatingEvent.actions ?? []).map((action) =>
						action.type === actionName ? { ...action, metadata } : action
					);
				} else {
					updatingEvent.actions = [
						...(updatingEvent.actions ?? []),
						{
							actionName,
							metadata,
						},
					];
				}
				currentWidgetTemplate.events = currentWidgetTemplate.events?.map((e) =>
					e.type === eventName ? updatingEvent : e
				);
			} else {
				currentWidgetTemplate.events = [
					...(currentWidgetTemplate.events ?? []),
					{
						id: generateUIId(),
						type: eventName,
						actions: [
							{
								actionName,
								metadata,
							},
						],
					},
				];
			}

			editingScene.widgetTemplates = [...widgetTemplates, currentWidgetTemplate];
			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }, true));

			if (!preventSubmitToApi) {
				initUpdateWidgetToApi(editingScene, { events: currentWidgetTemplate.events });
			}
		},
		[editingScene, editingWidget, props.editingWidget, initUpdateWidgetToApi]
	);

	const handleWidgetNameChange = useCallback(
		(event) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);
			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);
			currentWidgetTemplate.name = event.target.value;
			editingScene.widgetTemplates = [...widgetTemplates, currentWidgetTemplate];

			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }));
			initUpdateWidgetToApi(editingScene, { name: currentWidgetTemplate.name, type: currentWidgetTemplate.type });
		},
		[editingScene, editingWidget, props.editingWidget, initUpdateWidgetToApi]
	);

	const handleBGColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('backgroundSettings', 'backgroundColor', value);
		},
		[handleStylingAttributeChange]
	);

	const handleHoverBGColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('backgroundSettings', 'hoverBackgroundColor', value);
		},
		[handleStylingAttributeChange]
	);

	const handleTextLabelChange = useCallback(
		(event) => {
			handleStylingAttributeChange('textLabel', 'text', event.target.value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelFontChange = useCallback(
		(event) => {
			handleStylingAttributeChange('textLabel', 'font', event.target.value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelFontSizeChange = useCallback(
		(event) => {
			handleStylingAttributeChange('textLabel', 'fontSize', event.target.value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelFontWeightChange = useCallback(
		(event) => {
			handleStylingAttributeChange('textLabel', 'fontWeight', event.target.value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('textLabel', 'color', value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelBGColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('textLabel', 'backgroundColor', value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelHoverFontWeightChange = useCallback(
		(event) => {
			handleStylingAttributeChange('textLabel', 'hoverFontWeight', event.target.value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelHoverColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('textLabel', 'hoverColor', value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleTextLabelHoverBGColorChange = useCallback(
		(event, color) => {
			const value = event ? event.target.value : color.hex;
			handleStylingAttributeChange('textLabel', 'hoverBackgroundColor', value);
		},
		[editingWidget, handleStylingAttributeChange]
	);

	const handleStylingAttributeChange = useCallback(
		(objKey, attrKey, value) => {
			const latestEditingWidget = editingWidgetRef.current;
			const latestEditingScene = editingSceneRef.current;

			const widgetTemplates = (latestEditingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== latestEditingWidget?.widgetTemplateId
			);
			const currentWidgetTemplate = (latestEditingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === latestEditingWidget?.widgetTemplateId
			);

			if (objKey) {
				const obj = { ...((currentWidgetTemplate.style ?? {})[objKey] ?? {}) };
				obj[attrKey] = value;
				currentWidgetTemplate.style = { ...(currentWidgetTemplate.style ?? {}) };
				currentWidgetTemplate.style[objKey] = obj;
			} else {
				currentWidgetTemplate.style = { ...(currentWidgetTemplate.style ?? {}) };
				currentWidgetTemplate.style[attrKey] = value;
			}

			dispatch(
				updateEditingSceneAndSendToPlayer(
					{ ...latestEditingScene, widgetTemplates: [...widgetTemplates, currentWidgetTemplate] },
					true
				)
			);
			initUpdateWidgetToApi(
				{
					...latestEditingScene,
					widgetTemplates: [...widgetTemplates, currentWidgetTemplate],
				},
				{ style: currentWidgetTemplate.style }
			);
		},
		[editingScene, editingWidget, props.editingWidget, initUpdateWidgetToApi]
	);

	const handleUpdateIconToButton = useCallback(
		(iconKey, iconSize, iconPosition, iconSpacing) => {
			const latestEditingWidget = editingWidgetRef.current;
			const latestEditingScene = editingSceneRef.current;

			const widgetTemplates = (latestEditingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== latestEditingWidget?.widgetTemplateId
			);
			const currentWidgetTemplate = (latestEditingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === latestEditingWidget?.widgetTemplateId
			);

			const isUpdateIconKey = currentWidgetTemplate.settings.iconKey !== iconKey;
			const {
				size: oldIconSize,
				position: oldIconPosition,
				spacing: oldIconSpacing,
			} = currentWidgetTemplate.style.iconConfig;

			const isUpdateIconConfig =
				oldIconSize !== iconSize || oldIconPosition !== iconPosition || oldIconSpacing !== iconSpacing;

			const iconConfig = { position: iconPosition, size: iconSize, spacing: iconSpacing };

			const updateWidgetTemplate = {
				...currentWidgetTemplate,
				settings: { ...currentWidgetTemplate.settings, iconKey },
				style: {
					...currentWidgetTemplate.style,
					iconConfig,
				},
			};

			dispatch(
				updateEditingSceneAndSendToPlayer(
					{ ...latestEditingScene, widgetTemplates: [...widgetTemplates, updateWidgetTemplate] },
					true
				)
			);

			const updateBodyToApi = {
				settings: isUpdateIconKey ? updateWidgetTemplate.settings : undefined,
				style: isUpdateIconConfig ? updateWidgetTemplate.style : undefined,
			};

			Object.keys(updateBodyToApi).forEach(
				(key) => updateBodyToApi[key] === undefined && delete updateBodyToApi[key]
			);

			initUpdateWidgetToApi(
				{
					...latestEditingScene,
					widgetTemplates: [...widgetTemplates, updateWidgetTemplate],
				},
				updateBodyToApi
			);
		},
		[editingScene, editingWidget, props.editingWidget, initUpdateWidgetToApi]
	);

	const handleTimeAttributeChange = useCallback(
		(value, attrKey) => {
			if (attrKey === 'end') {
				setWidgetEndTime(value);
			}
			if (attrKey === 'start') {
				setWidgetStartTime(value);
			}
		},
		[editingWidget, setWidgetEndTime, setWidgetStartTime]
	);

	const handleSubmitTimeAttribute = useCallback(
		(value, attrKey) => {
			const remainingLayouts = (editingScene?.layouts ?? []).filter((l) => l.type !== currentLayout.type) ?? {};

			const currentBox = (currentLayout.boxes ?? []).find((box) => box.boxTemplateId === props.editingBox);
			const boxes = (currentLayout.boxes ?? []).filter((box) => box.boxTemplateId !== props.editingBox);

			const widgets = (currentBox.widgets ?? []).filter((widget) => widget.id !== props.editingWidget?.id);
			const currentWidget = (currentBox.widgets ?? []).find((widget) => widget.id === props.editingWidget?.id);

			const totalDurationInSec = (duration.end - duration.start) / 1000;
			const updatedDurationInSec = getSecondsFromTimelineTime(getTimelineTimeFromTimeInput(value));

			//update only if  value is updated
			if (moment(currentWidget[attrKey], 'HH:mm:ss.SSS').valueOf() === moment(value, 'HH:mm:ss.SSS').valueOf()) {
				return;
			}

			//checking if widget overlap other items
			const widgetStartTime = attrKey === 'start' ? value : currentWidget['start'];
			const widgetEndTime = attrKey === 'start' ? currentWidget['end'] : value;
			const timeOverlaps = widgets.filter(({ start, end }) => {
				return (
					getTimelineTimeFromTimeInput(widgetStartTime.replace('.', ':')) <=
						getTimelineTimeFromTimeInput(end.replace('.', ':')) &&
					getTimelineTimeFromTimeInput(widgetEndTime.replace('.', ':')) >=
						getTimelineTimeFromTimeInput(start.replace('.', ':'))
				);
			});

			const currentWidgetTemplate = editingScene.widgetTemplates.find(
				({ id }) => id === currentWidget.widgetTemplateId
			);
			const isSameIndex = timeOverlaps.some(({ widgetTemplateId }) => {
				const widgetTemplate = editingScene.widgetTemplates.find(({ id }) => id === widgetTemplateId);

				if (widgetTemplate) {
					const {
						style: { zIndex },
						type,
					} = widgetTemplate;

					if (type === 'video') {
						return false;
					}

					return zIndex === currentWidgetTemplate.style.zIndex;
				}
			});

			if (isSameIndex) {
				dispatch(showAlert('Widget should not be overlapped', messageTypes.info));
			}

			//checking start time should be before end time
			const timeToCompareWith = getTimelineTimeFromTimeInput(
				currentWidget[attrKey === 'start' ? 'end' : 'start']
			);
			const timeToBeUpdated = getTimelineTimeFromTimeInput(value);
			const startShouldBeBeforeEnd =
				attrKey === 'start' ? timeToCompareWith - timeToBeUpdated : timeToBeUpdated - timeToCompareWith;

			const oldWidgetTimeValue = currentWidget[attrKey];
			if (updatedDurationInSec > totalDurationInSec || startShouldBeBeforeEnd < 0 || isSameIndex) {
				value = convertWidgetTimeToInputTime(
					convertTimeToWidgetTime(moment(oldWidgetTimeValue, 'HH:mm:ss.SSS'))
				);
			}

			currentWidget[attrKey] = convertInputTimeToWidgetTime(value);
			currentLayout.boxes = [...boxes, { ...currentBox, widgets: [...widgets, { ...currentWidget }] }];

			if (attrKey === 'start') {
				props.movePlayerToPosition?.(convertInputTimeToWidgetTime(value), 'no-notify');
			}

			dispatch(
				updateEditingSceneDurationAction({
					start: attrKey === 'start' ? convertInputTimeToWidgetTime(value) : duration?.start,
					end: attrKey === 'end' ? convertInputTimeToWidgetTime(value) : duration?.end,
				})
			);
			dispatch(
				updateEditingSceneAndSendToPlayer(
					{ ...editingScene, layouts: [...remainingLayouts, currentLayout] },
					true
				)
			);

			//update to api only if value is updated and no overlap
			if (
				moment(oldWidgetTimeValue, 'HH:mm:ss.SSS').valueOf() !== moment(value, 'HH:mm:ss.SSS').valueOf() &&
				!isSameIndex
			) {
				dispatch(updateIsTimeLineChangedAction(true));
				initUpdateWidgetToApi(
					{ ...editingScene, layouts: [...remainingLayouts, currentLayout] },
					{ start: currentWidget.start, end: currentWidget.end }
				);
				return;
			}
		},
		[
			editingScene,
			editingWidget,
			currentLayout,
			props.editingBox,
			props.editingWidget,
			duration,
			props.movePlayerToPosition,
			initUpdateWidgetToApi,
		]
	);

	const handleJumpToTimeMetaDataChange = useCallback(
		(updateTime, selectedEvent, preventSubmit) => {
			const maxTime = duration.end;
			const timeToUpdate = getTimelineTimeFromTimeInput(updateTime);

			if (maxTime - timeToUpdate < 0) {
				return;
			}

			const metadata = { time: updateTime };
			handleWidgetActionChange(metadata, ACTION_JUMP_TO_TIME.value, selectedEvent, preventSubmit);
		},
		[duration, handleWidgetActionChange]
	);

	const handleLocationAttributeChange = useCallback(
		(value, object, attr) => {
			const widgetTemplates = (editingScene.widgetTemplates ?? []).filter(
				(widget) => widget.id !== props.editingWidget?.widgetTemplateId
			);
			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === props.editingWidget?.widgetTemplateId
			);

			const updateWidgetTemplate = {
				...currentWidgetTemplate,
				style: {
					...currentWidgetTemplate.style,
					[object]: !attr
						? value
						: {
								...currentWidgetTemplate.style[object],
								[attr]: value,
						  },
				},
			};
			editingScene.widgetTemplates = [...widgetTemplates, updateWidgetTemplate];

			dispatch(updateEditingSceneAndSendToPlayer({ ...editingScene }));
			initUpdateWidgetToApi({ ...editingScene }, { style: updateWidgetTemplate.style });
		},
		[editingScene, editingWidget]
	);

	const uploadFile = useCallback(
		(file, randomFileName) => {
			const fileReader = new FileReader();
			setUploadedFile({ ...file, name: randomFileName });

			if (file.type.match('image')) {
				fileReader.onload = function () {
					const img = document.getElementById('widget-thumbnail');
					img.src = fileReader.result;
				};

				var blobFile = new File([file], randomFileName, { type: file.type });
				fileReader.readAsDataURL(blobFile);
			} else {
				fileReader.onload = function () {
					const blob = new Blob([fileReader.result], { type: file.type });
					blob.name = randomFileName;

					const url = URL.createObjectURL(blob);
					const video = document.createElement('video');

					const timeupdate = function () {
						if (snapImage()) {
							video.removeEventListener('timeupdate', timeupdate);
							video.pause();
						}
					};

					video.addEventListener('loadeddata', function () {
						if (snapImage()) {
							video.removeEventListener('timeupdate', timeupdate);
						}
					});

					const snapImage = function () {
						const canvas = document.createElement('canvas');
						canvas.width = video.videoWidth;
						canvas.height = video.videoHeight;
						canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
						const image = canvas.toDataURL();
						const success = image.length > 100000;
						if (success) {
							const img = document.getElementById('widget-thumbnail');
							img.src = image;
							URL.revokeObjectURL(url);
						}
						return success;
					};

					video.addEventListener('timeupdate', timeupdate);
					video.preload = 'metadata';
					video.src = url;
					// Load video in Safari / IE11
					video.muted = true;
					video.playsInline = true;
					video.play();
				};
				fileReader.readAsArrayBuffer(file);
			}
		},
		[setUploadedFile]
	);

	const uploadWidgetContent = useCallback(
		(acceptedFiles, fileRejections) => {
			if (fileRejections.length > 0) {
				dispatch(showMessage('File is rejected!', messageTypes.error));
				return;
			}

			const randomFileName = generateId() + '.' + acceptedFiles[0].name.split('.').pop();
			uploadFile(acceptedFiles[0], randomFileName);
			// uploadImage(acceptedFiles[0], randomFileName);
		},
		[uploadFile]
	);

	const onUploadFileClick = useCallback(
		(event) => {
			event.preventDefault();
			const latestEditingWidget = editingWidgetRef.current;
			const currentWidgetTemplate = (editingScene.widgetTemplates ?? []).find(
				(widget) => widget.id === latestEditingWidget?.widgetTemplateId
			);
			if (currentWidgetTemplate.type === 'video' || currentWidgetTemplate.type === 'image') {
				props.onUploadMediaClick?.(currentWidgetTemplate.type);
			}
		},
		[props.onUploadMediaClick, dropzoneRef.current]
	);

	const onVideoThumbnailClick = useCallback(
		(_event) => {
			dispatch(
				setInspectingMediaData({
					id: currentMediaDetail?.id,
					mediaDetails: currentMediaDetail,
					mediaName: mediaNames.medias,
				})
			);
		},
		[currentMediaDetail, dispatch]
	);

	return (
		<>
			{editingWidget && (
				<GeneralSettings
					widgetTemplate={editingWidget}
					scenes={scenes}
					editingScene={editingScene}
					gotoUrlRef={gotoUrlRef}
					openNewTabRef={openNewTabRef}
					widgetStartTime={widgetStartTime}
					widgetEndTime={widgetEndTime}
					widgetOverlayRef={props.widgetOverlayRef}
					handleWidgetNameChange={handleWidgetNameChange}
					handleDestinationSceneChange={handleDestinationSceneChange}
					handleGotoUrlWidgetChange={handleGotoUrlWidgetChange}
					handleGotoUrlOpenNewTabChange={handleGotoUrlOpenNewTabChange}
					handleTimeAttributeChange={handleTimeAttributeChange}
					handleSubmitTimeAttribute={handleSubmitTimeAttribute}
					handleLocationAttributeChange={handleLocationAttributeChange}
					handleDeleteWidgetEvent={handleDeleteWidgetEvent}
					handleAddNewEventToWidget={handleAddNewEventToWidget}
					handleAddActionToEvent={handleAddActionToEvent}
					handleStylingAttributeChange={handleStylingAttributeChange}
					handleJumpToTimeMetaDataChange={handleJumpToTimeMetaDataChange}
				/>
			)}

			<Content
				widgetTemplate={editingWidget}
				editingScene={editingScene}
				widgetInBox={editingWidgetInBox}
				isAtHoverSetting={imageHoverSetting}
				uploadedFile={uploadedFile}
				textHoverSetting={textHoverSetting}
				setTextHoverSetting={setTextHoverSetting}
				bgHoverSetting={bgHoverSetting}
				setBGHoverSetting={setBGHoverSetting}
				currentMediaDetail={currentMediaDetail}
				setHoverSetting={setImageHoverSetting}
				uploadWidgetContent={uploadWidgetContent}
				onUploadFileClick={onUploadFileClick}
				onVideoThumbnailClick={onVideoThumbnailClick}
				handleTextLabelChange={handleTextLabelChange}
				handleTextLabelFontChange={handleTextLabelFontChange}
				handleTextLabelFontSizeChange={handleTextLabelFontSizeChange}
				handleTextLabelFontWeightChange={handleTextLabelFontWeightChange}
				handleTextLabelColorChange={handleTextLabelColorChange}
				handleTextLabelBGColorChange={handleTextLabelBGColorChange}
				handleTextLabelHoverFontWeightChange={handleTextLabelHoverFontWeightChange}
				handleTextLabelHoverColorChange={handleTextLabelHoverColorChange}
				handleTextLabelHoverBGColorChange={handleTextLabelHoverBGColorChange}
				onShowIconLibraryPickerModal={props.onShowIconLibraryPickerModal}
				handleUpdateIconToButton={handleUpdateIconToButton}
				handleStylingAttributeChange={handleStylingAttributeChange}
				handleReturnedMedia={props.handleReturnedMedia}
				handleBGColorChange={handleBGColorChange}
				handleHoverBGColorChange={handleHoverBGColorChange}
			/>
		</>
	);
});

export default WidgetSettings;
